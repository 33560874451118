<template>
  <section class="VNotifications_widget-notifications_pijd">
    <transition-group
      tag="div"
      class="VNotifications_widget-notifications__list_dwqf"
      :css="false"
      @enter="enter"
      @leave="leave"
    >
      <div
        v-for="notification in localNotifications"
        ref="notifications"
        :key="notification.id"
        :class="[$style['widget-alert']]"
        @transitionend="transitionEnd"
      >
        <div :class="$style['widget-alert-content']">
          <div
            :class="[
              $style['widget-alert-side'],
              $style['widget-alert-side--left'],
            ]"
          >
            <IconWarning />
          </div>
          <div :class="$style['widget-alert-center']">
            <span v-if="notification.isHtml" v-html="notification.text" />
            <span v-else>{{ notification.text }}</span>
          </div>
        </div>
      </div>
    </transition-group>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "VNotifications",
  data: () => ({
    isActive: true,
    notificationsCopy: [],
    localNotifications: [],
  }),
  computed: {
    ...mapState("notifications", ["notifications"]),
  },
  watch: {
    notifications: {
      handler(notifications) {
        if (!this.isActive) {
          return;
        }
        const removed = this.notificationsCopy.filter(
          (el) => !notifications.includes(el)
        );
        const added = notifications.filter(
          (el) => !this.notificationsCopy.includes(el)
        );
        this.localNotifications = this.localNotifications.filter(
          (el) => !removed.includes(el)
        );
        this.localNotifications.push(...added);
        this.notificationsCopy = notifications.slice();
      },
      deep: true,
    },
  },
  created() {
    this.start();
    this.doneCallbacks = [];
  },
  activated() {
    this.start();
  },
  deactivated() {
    this.isActive = false;
  },
  methods: {
    enter(el, done) {
      el.style.height = "0px";
      el.style.height = `${el.scrollHeight}px`;
      this.doneCallbacks.push(done);
    },
    leave(el, done) {
      el.style.height = `${el.scrollHeight}px`;
      window.getComputedStyle(el).transform;
      el.style.height = "0px";
      this.doneCallbacks.push(done);
    },
    transitionEnd(e) {
      const doneCallback = this.doneCallbacks.shift();
      if (doneCallback) {
        doneCallback();
      }
      const el = e.target;
      el.style.height = "";
    },
    start() {
      this.isActive = true;
      this.localNotifications = [];
      this.notificationsCopy = this.notifications.slice();
    },
  },
};
</script>
<style module scoped lang="scss">
.widget-alert {
  background-color: #fee2e2 !important;
  color: white !important;
  transition: height 0.2s !important;
  overflow: hidden !important;
  padding: 0 30px !important;
  border-radius: 5px !important;
  margin-bottom: 22px !important;
  margin-top: -20px !important;

  &--success {
    background-color: green !important;
  }

  &--error {
    background-color: #fee2e2 !important;
  }
}
.widget-alert-content {
  padding: 7px 20px !important;
  display: flex !important;
  align-items: center !important;
}
.widget-alert-content svg {
  flex-shrink: 0 !important;
  margin-right: 7px !important;
  display: flex !important;
  align-items: center !important;
}
.widget-alert-content span {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}
.widget-alert-side {
  flex: 0 0 auto !important;
  width: 20px !important;
  position: relative !important;
  margin: 0 10px 0px 0px !important;

  &--right {
    text-align: right !important;
  }
  margin-right: 7px !important;
}

.widget-alert-center {
  flex-grow: 1 !important;
  padding: 0 2px !important;
  font-size: 1.4rem !important;
  line-height: 1.3 !important;
  color: #7f1d1d !important;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 475px) {
}
@media screen and (max-width: 370px) {
}
</style>
