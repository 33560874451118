const postalCodes = require("postal-codes-js");
import creditCardType from "credit-card-type";
import { wepayAllowedCardNetworks } from "@/global/util/vendors";

export const firstName = {
  rule: {
    minLength: 1,
    maxLength: 40,
  },
  message: {
    min: () => `Minimum ${firstName.rule.minLength} characters`,
    max: () => `Maximum ${firstName.rule.maxLength} characters`,
    required: () => "Required",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: firstName.message.required(),
        };
      },
      min: (value) => {
        return {
          valid: !(value.length < firstName.rule.minLength),
          message: firstName.message.min(),
        };
      },
      max: (value) => {
        return {
          valid: !(value.length > firstName.rule.maxLength),
          message: firstName.message.max(),
        };
      },
    },
  ],
};

export const lastName = {
  rule: {
    minLength: 1,
    maxLength: 40,
  },
  message: {
    min: () => `Minimum ${lastName.rule.minLength} characters`,
    max: () => `Maximum ${lastName.rule.maxLength} characters`,
    required: () => "Required",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: lastName.message.required(),
        };
      },
      min: (value) => {
        return {
          valid: !(value.length < lastName.rule.minLength),
          message: lastName.message.min(),
        };
      },
      max: (value) => {
        return {
          valid: !(value.length > lastName.rule.maxLength),
          message: lastName.message.max(),
        };
      },
    },
  ],
};

export const donationAsAnonymous = {
  message: {
    required: () => "Required",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: donationAsAnonymous.message.required(),
        };
      },
    },
  ],
};

export const city = {
  rule: {
    minLength: 1,
    maxLength: 40,
  },
  message: {
    min: () => `Minimum ${city.rule.minLength} characters`,
    max: () => `Maximum ${city.rule.maxLength} characters`,
    required: () => "Required",
  },
  rules: [
    {
      required: (value = "") => {
        return {
          valid: !!value,
          message: city.message.required(),
        };
      },
      min: (value = "") => {
        return {
          valid: !(value.length < city.rule.minLength),
          message: city.message.min(),
        };
      },
      max: (value = "") => {
        return {
          valid: !(value.length > city.rule.maxLength),
          message: city.message.max(),
        };
      },
    },
  ],
};

export const country = {
  rule: {
    minLength: 1,
    maxLength: 40,
  },
  message: {
    min: () => `Minimum ${country.rule.minLength} characters`,
    max: () => `Maximum ${country.rule.maxLength} characters`,
    required: () => "Required",
  },
  rules: [
    {
      required: (value = "") => {
        return {
          valid: !!value,
          message: country.message.required(),
        };
      },
      min: (value = "") => {
        return {
          valid: !(value.length < country.rule.minLength),
          message: country.message.min(),
        };
      },
      max: (value = "") => {
        return {
          valid: !(value.length > country.rule.maxLength),
          message: country.message.max(),
        };
      },
    },
  ],
};

export const state = {
  rule: {
    minLength: 1,
    maxLength: 40,
  },
  message: {
    min: () => `Minimum ${state.rule.minLength} characters`,
    max: () => `Maximum ${state.rule.maxLength} characters`,
    required: () => "Required",
  },
  rules: [
    {
      required: (value = "") => {
        return {
          valid: !!value,
          message: state.message.required(),
        };
      },
      min: (value = "") => {
        return {
          valid: !(value.length < state.rule.minLength),
          message: state.message.min(),
        };
      },
      max: (value = "") => {
        return {
          valid: !(value.length > state.rule.maxLength),
          message: state.message.max(),
        };
      },
    },
  ],
};

export const addressState = {
  rule: {
    minLength: 1,
    maxLength: 40,
  },
  message: {
    min: () => `Minimum ${addressState.rule.minLength} characters`,
    max: () => `Maximum ${addressState.rule.maxLength} characters`,
    required: () => "Required",
  },
  rules: [
    {
      required: (value = "") => {
        return {
          valid: !!value,
          message: addressState.message.required(),
        };
      },
      min: (value = "") => {
        return {
          valid: !(value.length < addressState.rule.minLength),
          message: addressState.message.min(),
        };
      },
      max: (value = "") => {
        return {
          valid: !(value.length > addressState.rule.maxLength),
          message: addressState.message.max(),
        };
      },
    },
  ],
};

export const address = {
  rule: {
    minLength: 1,
    maxLength: 40,
  },
  message: {
    min: () => `Minimum ${address.rule.minLength} characters`,
    max: () => `Maximum ${address.rule.maxLength} characters`,
    required: () => "Required",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: address.message.required(),
        };
      },
      min: (value) => {
        return {
          valid: !(value.length < address.rule.minLength),
          message: address.message.min(),
        };
      },
      max: (value) => {
        return {
          valid: !(value.length > address.rule.maxLength),
          message: address.message.max(),
        };
      },
    },
  ],
};

export const note = {
  rule: {
    minLength: 0,
    maxLength: 120,
  },
  message: {
    min: () => `Minimum ${note.rule.minLength} characters`,
    max: () => `Maximum ${note.rule.maxLength} characters`,
    required: () => "Required",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: note.message.required(),
        };
      },
      min: (value) => {
        return {
          valid: !(value.length < note.rule.minLength),
          message: note.message.min(),
        };
      },
      max: (value) => {
        return {
          valid: !(value.length > note.rule.maxLength),
          message: note.message.max(),
        };
      },
    },
  ],
};

export const phone = {
  message: {
    required: () => "Required",
    isValidNumber: () => "The mobile number is invalid",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: lastName.message.required(),
        };
      },
      isValidNumber: (value, vm) => {
        const mobileNumber = value;
        if (!vm.$store.state.tempHelper.validation?.phone) return;

        const { countryCode, isValidNumber, parse, phoneNumberFormat } =
          vm.$store.state.tempHelper.validation.phone;

        // const code = !isEmpty(countryCode)
        //   ? countryCode.toUpperCase()
        //   : countryCode;

        const isValid = (code) => {
          const parsedNumber = parse(mobileNumber, code, phoneNumberFormat); //E.164
          if (
            parsedNumber.phone &&
            isValidNumber(parsedNumber.phone, parsedNumber.country)
          ) {
            const validationInfo = {
              phone: Object.assign(
                { isValid },
                vm.$store.state.tempHelper.validation.phone
              ),
            };
            vm.$store.commit("tempHelper/setValidationInfo", validationInfo);
            return true;
          }
          return false;
        };

        let isValidPh = isValid("US");
        if (!isValidPh) {
          isValidPh = isValid("CA");
        }

        return {
          valid: isValidPh,
          message: phone.message.isValidNumber(),
        };
      },
    },
  ],
};

export const addressStateId = {
  message: {
    required: () => "Required",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: addressStateId.message.required(),
        };
      },
    },
  ],
};

export const cityId = {
  message: {
    required: () => "Required",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: cityId.message.required(),
        };
      },
    },
  ],
};

export const postalCode = {
  rule: {
    minLength: 1,
    maxLength: 10,
  },
  message: {
    required: () => "Required",
    isNotValid: () => "Postal code is not valid for that country",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: postalCode.message.required(),
        };
      },
      isValidPostalCode: (value, vm) => {
        if (!vm.$store.state.tempHelper.validation?.postalCode) return;

        const { countryCode, postalCode: _postalCode } =
          vm.$store.state.tempHelper.validation.postalCode;

        const valid =
          postalCodes.validate(countryCode, _postalCode) == true ? true : false;

        const validationInfo = {
          postalCode: Object.assign(
            { isValid: Boolean(valid) },
            vm.$store.state.tempHelper.validation.postalCode
          ),
        };
        vm.$store.commit("tempHelper/setValidationInfo", validationInfo);

        return {
          valid: Boolean(valid),
          message: !Boolean(valid) ? postalCode.message.isNotValid() : "",
        };
      },
    },
  ],
};

export const email = {
  message: {
    invalid: () => "Please enter a valid email address",
    required: () => "Required",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: email.message.required(),
        };
      },
      email: (value) => {
        const EMAIL_REGEX =
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return {
          valid: EMAIL_REGEX.test(value.toLowerCase()),
          message: email.message.invalid(),
        };
      },
    },
  ],
};

export const cardNumber = {
  message: {
    invalid: () => "Please enter a valid card",
    required: () => "Required",
    isWePaySupports: () => "Card type is not supported",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: cardNumber.message.required(),
        };
      },
      // isValidCardNumber: (value) => {
      //   const CARD_NUMBER_REGEX = /^[\s\d\-•]+$/;
      //   return {
      //     valid: CARD_NUMBER_REGEX.test(value),
      //     message: email.message.invalid(),
      //   };
      // },
      isWePaySupports: (value) => {
        let cardInformation = [];
        let vendor = "";

        if (value) {
          cardInformation = creditCardType(value);
        }

        if (cardInformation.length) {
          const types = creditCardType.types;

          vendor = Object.keys(types).find(
            (type) => types[type] === cardInformation[0].type
          );
        }

        const v = wepayAllowedCardNetworks.includes(vendor);
        return {
          valid: v,
          message: cardNumber.message.isWePaySupports(),
        };
      },
    },
  ],
};

export const securityCode = {
  rule: {
    min: 3,
    max: 4,
  },
  message: {
    min: () =>
      `Enter the ${securityCode.rule.min}-${securityCode.rule.max}-digit security code.`,
    max: () =>
      `Enter the ${securityCode.rule.min}-${securityCode.rule.max}-digit security code.`,
    required: () => "Required",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: securityCode.message.required(),
        };
      },
      min: (value) => {
        return {
          valid: !(value.length < securityCode.rule.min),
          message: securityCode.message.min(),
        };
      },
      max: (value) => {
        return {
          valid: !(value.length > securityCode.rule.max),
          message: securityCode.message.max(),
        };
      },
    },
  ],
};

export const expirationDate = {
  message: {
    required: () => "Required",
    isCardDateValid: () => "Date is invalid",
    isCardExpiry: () => "That credit card expiration date has already passed",
    isCardExpiryMonth: () => "Invalid credit card expiration month",
  },
  rules: [
    {
      required: (value) => {
        return {
          valid: !!value,
          message: securityCode.message.required(),
        };
      },
      isCardDateValid: (value) => {
        const CARD_DATE_REGEX = /^\d{2}\/\d{4}$/;
        value = value.replace(/\//g, "");
        let expirationMonth = Number(value.substr(0, 2));
        const expirationYear = Number(value.substr(2));

        if (expirationMonth < 10) {
          expirationMonth = `0${expirationMonth}`;
        }

        const date = `${expirationMonth}/${expirationYear}`;
        return {
          valid: CARD_DATE_REGEX.test(date),
          message: expirationDate.message.isCardDateValid(),
        };
      },
    },
  ],
};
