import * as constants from "@/config/constants";
const WIDGET_NAME = constants.WIDGET_NAME;

function validInitScript({ loaderObject, instanceName, win, tagName }) {
  if (!loaderObject || !loaderObject.q) {
    throw new Error(
      `Widget didn't find LoaderObject for instance [${WIDGET_NAME}${instanceName}]. ` +
        `The loading script was either modified, no call to 'init' method was done ` +
        `or there is conflicting object defined in \`window.${instanceName}\` .`
    );
  }

  // check that the widget is not loaded twice under the same name
  if (win[`loaded-${WIDGET_NAME}${tagName}`]) {
    throw new Error(
      `Widget with name [${WIDGET_NAME}${instanceName}] was already loaded. ` +
        `This means you have multiple instances with same identifier (e.g. '${WIDGET_NAME}${tagName}')`
    );
  }

  if (!constants.DEFAULT_NAME.includes(instanceName)) {
    throw new Error(
      `The widget name [${WIDGET_NAME}-${instanceName}] is incorrect.`
    );
  }
}

export default (
  win,
  defaultConfig,
  scriptElement,
  loadCustomComponent,
  tagName
) => {
  const instanceName =
    scriptElement?.attributes.getNamedItem("id")?.value ?? "";
  const loaderObject = win[instanceName];

  if (instanceName != tagName) return;

  validInitScript({ loaderObject, instanceName, win, tagName });

  const i = 0;
  const item = loaderObject.q[i];
  const methodName = item[0];
  if (i === 0 && methodName !== "init") {
    throw new Error(
      `Failed to start Widget [${instanceName}]. 'init' must be called before other methods.`
    );
  }

  switch (methodName) {
    case "init": {
      const customElementName = `${WIDGET_NAME}${tagName}`;
      window.scriptConfig = item[1];
      const { hostname, origin, protocol } = location;

      const initData = {
        hostname,
        origin,
        protocol,
        loaderObject: loaderObject.q,
        widgetName: customElementName,
        instanceName,
        scriptConfig: item[1],
        NODE_ENV: process.env.NODE_ENV,
      };
      console.log(`Starting widget [${customElementName}]`);
      if (process.env.NODE_ENV != "production") {
        console.info(initData);
      }
      window.widgetInitData = initData;

      let _customElementsDefine = window.customElements.define;
      window.customElements.define = (name, cl, conf) => {
        if (!customElements.get(name)) {
          _customElementsDefine.call(window.customElements, name, cl, conf);
        } else {
          console.warn(`${name} has been defined twice`);
        }
      };

      window.customElements.define(customElementName, loadCustomComponent);

      win[`loaded-${WIDGET_NAME}${tagName}`] = true;

      break;
    }
    default:
      console.warn(`Unsupported method [${methodName}]`, item[1]);
  }
};
