const defaultState = () => ({
  validation: {},
  // errorFields: {},
});
const getters = {};
const actions = {};

const mutations = {
  setValidationInfo(state, lib) {
    state.validation = Object.assign(state.validation, lib);
  },
  // setErrorFieldsiInfo(state, lib) {
  //   state.errorFields = Object.assign(state.errorFields, lib);
  // },
};

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  actions,
  mutations,
};
