import MASTERCARD from "@/global/assets/payment-methods/mastercard.svg"; //
import VISA from "@/global/assets/payment-methods/visa.svg"; //
import AMERICAN_EXPRESS from "@/global/assets/payment-methods/amex.svg"; //
import DISCOVER from "@/global/assets/payment-methods/discover.svg"; //
import DINERS_CLUB from "@/global/assets/payment-methods/diners.svg"; //
import JCB from "@/global/assets/payment-methods/jcb.svg"; //
import MAESTRO from "@/global/assets/payment-methods/maestro.svg"; //

// import ALIPAY from "@/global/assets/payment-methods/alipay.svg";

// import CARD from "@/global/assets/payment-methods/card.svg";
// import BANK from "@/global/assets/payment-methods/bank-alt.svg";

const wepayAllowedCardNetworks = [
  "MASTERCARD",
  "VISA",
  "AMERICAN_EXPRESS",
  "DISCOVER",
  "DINERS_CLUB",
  "JCB",
  "MAESTRO",
];
const images = {
  MASTERCARD,
  VISA,
  AMERICAN_EXPRESS,
  DISCOVER,
  DINERS_CLUB,
  JCB,
  MAESTRO,
};

export { wepayAllowedCardNetworks, images };
