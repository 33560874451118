<template>
  <div
    :style="{ height: styleHeight }"
    :class="[$style['widget'], baseClassWrapper, rootInnerDocumentClass]"
  >
    <router-view v-slot="{ Component, route }">
      <TimeOutModal
        v-if="!donationWidgetSetupPage"
        type="modal"
        position="up"
        session-actions="startOver"
      />
      <transition
        :name="transitionName"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
      >
        <div :key="route.name">
          <component
            :is="Component"
            :key="route.meta.usePathKey ? route.path : undefined"
          ></component>
        </div>
      </transition>
    </router-view>
  </div>
</template>

<script>
import Base from "@/global/mixins/wrapperComponents/Base.js";
import * as constants from "@/config/constants";

export default {
  inject: ["donationWidgetSetupPage"],
  mixins: [Base],
  data() {
    return {
      baseClassWrapper: "TheBase_c-the-base_AiORPB",
      rootInnerDocumentClass:
        constants.userInteraction.modal.rootInnerDocumentClass,
      querySelectorCardViewBottom: ".widget-card-view__bottom_GTYds",
    };
  },
};
</script>
<style scoped>
.TheBase_c-the-base_AiORPB > .left-enter-active,
.TheBase_c-the-base_AiORPB > .left-leave-active,
.TheBase_c-the-base_AiORPB > .right-enter-active,
.TheBase_c-the-base_AiORPB > .right-leave-active,
.TheBase_c-the-base_AiORPB > .up-enter-active,
.TheBase_c-the-base_AiORPB > .up-leave-active,
.TheBase_c-the-base_AiORPB > .down-enter-active,
.TheBase_c-the-base_AiORPB > .down-leave-active,
.TheBase_c-the-base_AiORPB > .fade-enter-active,
.TheBase_c-the-base_AiORPB > .fade-leave-active {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  transition: transform 500ms, opacity 500ms;
}
.right-leave-to {
  transform: translate3d(-100%, 0, 0);
}
.right-enter-to {
  animation: rightEnter 500ms forwards;
}
.left-leave-to {
  transform: translate3d(100%, 0, 0);
}
.left-enter-to {
  animation: leftEnter 500ms forwards;
}
.TheBase_c-the-base_AiORPB > div {
  background-color: #fff !important;
}
.right-leave-active,
.left-leave-active {
  z-index: 50 !important;
}
@keyframes rightEnter {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes leftEnter {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
</style>
<style module lang="scss">
.spinner_animation {
  transform: translateX(0) !important;
  animation: ani 2s forwards !important;
}

@keyframes ani {
  0% {
    transform: translateX(0) !important;
  }
  100% {
    transform: translateY(-150%) !important;
  }
}

.widget {
  width: 100% !important;
  min-height: 400px !important;
  position: relative !important;
  overflow: hidden !important;
  display: flex !important;
  transition: height 1000ms !important;
}

.widget > * {
  flex: 0 0 auto !important;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.widget {
  max-width: 500px !important;
  min-width: 500px !important;
  background: #ffffff !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  font-family: "Inter", sans-serif !important;
  color: #4b5563 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

@media screen and (max-width: 735px) {
  .widget {
    display: flex !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    flex-direction: column !important;
    min-height: 100vh !important;
    height: 100% !important;
    border-radius: 0 !important;
    transition: none !important;
    overflow-y: auto !important;
  }

  body > div {
    height: 100% !important;
  }
}

@media screen and (max-width: 370px) {
  .widget {
    font-size: 14px !important;
    line-height: 21px !important;
  }
}
</style>
