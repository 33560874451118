function allowOnlyDigits(event) {
  const REGEX = /\d/;

  if (!REGEX.test(event.key)) {
    event.preventDefault();
  }
}

function cardNumberKeypressHandler(event) {
  allowOnlyDigits(event);
}

function cardDateKeypressHandler(event) {
  let regex = /\d/;

  if (event.target.value.length === 2) {
    regex = /[\d/]/;
  }

  if (!regex.test(event.key)) {
    event.preventDefault();
  }
}

function cardSecurityCodeKeypressHandler(event) {
  allowOnlyDigits(event);
}

function mobileNumberKeypressHandler(event) {
  const REGEX = /[- +()0-9]/;

  if (!REGEX.test(event.key)) {
    event.preventDefault();
  }
}

function countCharacters(character, string) {
  let count = 0;
  let index = string.indexOf(character);

  while (index !== -1) {
    count += 1;
    index = string.indexOf(character, index + 1);
  }

  return count;
}

export {
  cardNumberKeypressHandler,
  cardDateKeypressHandler,
  cardSecurityCodeKeypressHandler,
  mobileNumberKeypressHandler,
  countCharacters,
};
