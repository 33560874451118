// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldCardNumber_widget-form-field_Fy14g label[data-v-17e478db]{display:block!important;line-height:12px!important;font-weight:500!important;font-size:10px!important;color:#6b7280!important;margin-bottom:9px!important}.FieldCardNumber_widget-form-field_Fy14g.FieldCardNumber_error_yaXxF label[data-v-17e478db]{color:#dc2626!important}.FieldCardNumber_card-logo_PdiJS[data-v-17e478db]{display:flex!important;position:absolute!important;right:20px!important;top:50%!important;transform:translateY(-50%)!important}.FieldCardNumber_card-logo_PdiJS img[data-v-17e478db]{width:35px!important;height:30px!important;margin-right:5px!important}.FieldCardNumber_card-logo_PdiJS img[data-v-17e478db]:last-child{margin-right:0!important}@media screen and (max-width:370px){.FieldCardNumber_card-logo_PdiJS img[data-v-17e478db]{width:26px!important;height:16px!important}.FieldCardNumber_card-logo_PdiJS[data-v-17e478db]{right:10px!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget-form-field": "FieldCardNumber_widget-form-field_Fy14g",
	"error": "FieldCardNumber_error_yaXxF",
	"card-logo": "FieldCardNumber_card-logo_PdiJS"
};
export default ___CSS_LOADER_EXPORT___;
