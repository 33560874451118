import { isEmpty, cloneDeep } from "lodash";
import { internalСonfig } from "@/config";
import { mapState } from "vuex";
import * as validationRules from "@/global/util/validation/rules/fields";

function skipValidationRule(rulesList = {}, skip = {}, value = "") {
  let preparedRules = [];
  let list = cloneDeep(rulesList);

  for (var rule in skip) {
    if (skip.hasOwnProperty(rule) && skip[rule] === false) {
      for (let i = 0; i < list.length; i++) {
        for (var key in list[i]) {
          if (rule == "required" && value == "") {
            delete list[i]?.[key];
          }

          if (rule == key) {
            if (list[i].hasOwnProperty(key)) {
              delete list[i]?.[key];
            }
          }
        }
      }
    }
  }

  for (let i = 0; i < list.length; i++) {
    for (var key in list[i]) {
      if (list[i].hasOwnProperty(key)) {
        preparedRules.push(list[i]?.[key]);
      }
    }
  }

  return preparedRules;
}

const rules = {
  firstName: {
    rules: validationRules.firstName.rules,
  },
  lastName: {
    rules: validationRules.lastName.rules,
  },
  email: {
    rules: validationRules.email.rules,
  },
  phone: {
    rules: validationRules.phone.rules,
  },
  address: {
    rules: validationRules.address.rules,
  },
  city: {
    rules: validationRules.city.rules,
  },
  cityName: {
    rules: validationRules.city.rules,
  },
  cityId: {
    rules: validationRules.cityId.rules,
  },
  postalCode: {
    rules: validationRules.postalCode.rules,
  },
  addressStateId: {
    rules: validationRules.addressStateId.rules,
  },
  addressState: {
    rules: validationRules.addressState.rules,
  },
  countryId: {
    rules: validationRules.country.rules,
  },
  country: {
    rules: validationRules.country.rules,
  },
  state: {
    rules: validationRules.state.rules,
  },
  donationAsAnonymous: {
    rules: validationRules.donationAsAnonymous.rules,
  },
  note: {
    rules: validationRules.note.rules,
  },
  cardNumber: {
    rules: validationRules.cardNumber.rules,
  },
  securityCode: {
    rules: validationRules.securityCode.rules,
  },
  expirationDate: {
    rules: validationRules.expirationDate.rules,
  },
};
export default {
  data: () => ({
    validationType: "alert",
    validationRules: rules,
  }),
  computed: {
    ...mapState("config", {
      externalConfigValidationForms: (state) =>
        state.config["external"]?.validationForms || {},
      env: (state) => state.config["config"].env,
    }),
    ...mapState("organization", {
      configPropertiesSettings: (state) => {
        return state.organization.widgetSettings.properties;
      },
    }),

    minAmount() {
      const minAmount =
        this.configPropertiesSettings?.chooseAmount?.givingAmount?.rules
          ?.minAmount;

      if (minAmount) {
        return minAmount;
      }
      return 5;
    },
  },
  watch: {
    minAmount: {
      handler(value) {
        this.$store.commit("organization/setMinAmountRule", parseFloat(value));
      },
      deep: true,
    },
  },
  mounted() {
    let amount = {
      message: {
        min: () => `Donation must be at least $${this.minAmount}.00 or more`,
        required: () => "Required",
      },
      rules: [
        {
          min: (value) => {
            return {
              valid: !(value < this.minAmount),
              message: amount.message.min(),
            };
          },
        },
      ],
    };

    this.validationRules.amount = {
      rules: amount.rules,
    };
  },
  methods: {
    setValidationType(opt = {}) {
      this.validationType = opt.type;
    },
    useValidation() {
      if (internalСonfig.validationForms[this.env].disabled) return false;

      if (!isEmpty(this.externalConfigValidationForms)) {
        const externalConfigValidation = {
          disabled: Boolean(this.externalConfigValidationForms?.disabled),
          secret: this.externalConfigValidationForms.secret,
        };
        if (!externalConfigValidation.disabled) return true;

        const secretValid =
          internalСonfig.validationForms[this.env].secret ===
          externalConfigValidation.secret;

        return !secretValid;
      }
      return true;
    },

    /**
     *
     const skip = {
        required: false,
      };
     */
    validateField(inputName, value, skip = {}) {
      if (!this.useValidation()) {
        return [];
      }

      if (!this.validationRules[inputName]?.rules) {
        if (process.env.NODE_ENV != "production") {
          console.warn("DEV::VALIDATION RULES NOT FOUND", {
            inputName,
            value,
            validationRules: this.validationRules[inputName],
          });
        }

        return [];
      }
      return skipValidationRule(
        this.validationRules[inputName].rules,
        skip,
        value
      )
        .filter((rule) => {
          let results = rule(value, this);

          if (results?.valid !== true) {
            return results;
          }
        })
        .map((rule) => {
          let results = rule(value, this);

          const rulesResults = {
            inputName,
            value,
            valid: results.valid,
            message: results.message,
          };

          if (this.validationType == "alert") {
            this.$store.dispatch(
              "notifications/notifyError",
              rulesResults.message
            );
          }

          return rulesResults;
        });
    },
    /**Example:
     * const skip = {
         amount: {
           required: false,
         },
       };
     */
    validateForm(form, skip = {}) {
      const formErrors = {};
      let formIsValid = true;

      for (let property in form) {
        let errors = this.validateField(
          property,
          form[property],
          skip[property]
        );

        if (errors.length) {
          formIsValid = false;
        }

        formErrors[property] = errors;
      }

      formErrors.formIsValid = formIsValid;

      return formErrors;
    },
  },
};
