const _ = require("lodash");
import { mapState } from "vuex";
import validationMixin from "@/global/mixins/validationRules.js";
import { GeoData } from "@/store/geo_data.js";

const currentStepName = "basicDetails";

export default {
  inject: ["$http"],
  mixins: [validationMixin],
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    titleStepName: "Basic Details",
    currentStepName: currentStepName,
    countries: [],
    states: [],
    cities: [],
    states_loading: false,
    country_loading: false,
    loadingAll: false,
    postalCodeValid: false,

    clearCollectionState: {},
    setPreEnteredDataCountry: {},
    setPreEnteredDataState: {},
    defaultCountry: { id: 233, name: "United States", abbrev: "US" },
    items: {
      country: {},
      state: {},
      addressStateId: "",
      addressStateName: "",
      addressStateCode: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      phonecode: "",
      address: "",
      cityName: "",
      postalCode: "",
      donationAsAnonymous: false,
    },
    loadPreEnteredData: {},
    isDisabledContinue: false,
    blockButton: false,
    errors: [],
  }),
  watch: {
    items: {
      handler(items) {
        this.$store.commit("intermediateDataCollect/setDonorData", {
          ...items,
        });
      },
      deep: true,
    },
    "items.postalCode": {
      handler() {
        this.setGlobalValidationInfoForPostalCode();
      },
    },
    "items.country.id": {
      handler(value, old_value) {
        if (value != old_value) {
          this.countryTouched();
          this.setGlobalValidationInfoForPostalCode();
        }
      },
      deep: true,
    },
  },
  computed: {
    geoData() {
      return new GeoData(this.$http);
    },
    ...mapState("tempHelper", ["validation"]),
    ...mapState("donation", ["donorInfo"]),
    ...mapState("organization", {
      stepInfo: (state) =>
        state.organization.widgetSettings.stepsInfo[currentStepName],
      propertyInfo: (state) => state.organization.widgetSettings,
      configProperties: (state) =>
        state.organization.widgetSettings.properties[currentStepName],
      donationNoteInfo: (state) =>
        state.organization.widgetSettings.stepsInfo.donationNote,
    }),
    buttonNext() {
      return this.stepInfo.buttonNext;
    },
    buttonBack() {
      return this.stepInfo.buttonBack;
    },
    allowAnonymousDonors() {
      return this.stepInfo.allowAnonymousDonors;
    },
    validationRule() {
      //monitors input and highlight it as an error if it doesn't match the rules
      const invalid = {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        address: false,
        cityName: false,
        country: false,
        countryId: false,
        postalCode: false,
        addressStateId: false,
      };

      const rules = {
        firstName: {
          required: Boolean(this.configProperties.firstName.rules.required),
          minLength: 1,
          maxLength: 31,
        },
        lastName: {
          required: Boolean(this.configProperties.lastName.rules.required),
        },
        email: {
          required: true, //Boolean(this.configProperties.email.rules.required),
        },
        phone: {
          required: Boolean(this.configProperties.phone.rules.required),
          maxLength: 15,
        },
        address: {
          required: Boolean(this.configProperties.address.rules.required),
          minLength: 1,
          maxLength: 31,
        },
        cityName: {
          required: Boolean(this.configProperties.city.rules.required),
        },
        country: {
          required: Boolean(this.configProperties.country.rules.required),
        },
        postalCode: {
          required: Boolean(this.configProperties.postalCode.rules.required),
          maxLength: 10,
        },
        addressStateId: {
          required: Boolean(this.configProperties.addressState.rules.required),
        },
      };
      return { invalid, rules };
    },

    skipRequiredRule() {
      return {
        address: {
          required: this.validationRule.rules.address.required,
        },
        phone: {
          required: this.validationRule.rules.phone.required,
        },
        cityName: {
          required: this.validationRule.rules.cityName.required,
        },
        addressStateId: {
          required: this.validationRule.rules.addressStateId.required,
        },
        countryId: {
          required: this.validationRule.rules.country.required,
        },
        postalCode: {
          required: this.validationRule.rules.postalCode.required,
        },
      };
    },
    data() {
      return {
        firstName: this.items.firstName,
        lastName: this.items.lastName,
        email: this.items.email,
        phone: this.items.phone,
        address: this.items.address,
        postalCode: this.items.postalCode,
        cityName: this.items.cityName,
        state: this.items.state,
        addressStateId: this.items.state?.id ? this.items.state.id : "",
        addressStateName: this.items.state?.name ? this.items.state.name : "",
        addressStateCode: this.items.state?.abbrev
          ? this.items.state.abbrev
          : "",
        country: this.items.country,
        countryId: this.items.country.id,
        countryName: this.items.country.name,
        countryCode: this.items.country.abbrev,
        donationAsAnonymous: this.items.donationAsAnonymous,
      };
    },
  },
  async mounted() {},
  async created() {},
  methods: {
    selectedOption(data) {
      if (data.checked == "country") {
        this.setClearCollectionState();
      }
    },
    setClearCollectionState() {
      if (_.isEmpty(this.items.state)) return;

      this.items.state = {};
      this.clearCollectionState = {
        field: "state",
        value: true,
      };
    },
    setPreEnteredData() {
      let firstBlock = () => {
        if (!_.isEmpty(this.donorInfo)) {
          const {
            firstName,
            lastName,
            email,
            phone,
            address,
            cityName,
            postalCode,
            addressStateId,
            donationAsAnonymous,
            addressStateCode,
          } = this.donorInfo;
          this.items.firstName = firstName;
          this.items.lastName = lastName;
          this.items.email = email;
          this.items.phone = phone;
          this.items.address = address;

          this.items.cityName = cityName;

          this.items.postalCode = postalCode;
          this.items.addressStateId = addressStateId;
          this.items.addressStateCode = addressStateCode;

          this.items.donationAsAnonymous = donationAsAnonymous;
        }
      };

      let secondBlock = () => {
        if (!_.isEmpty(this.donorInfo)) {
          const {
            addressStateId,
            countryId,
            addressStateCode,
            addressStateName,
            countryCode,
            countryName,
          } = this.donorInfo;

          this.items.state.id = addressStateId;
          this.items.state.name = addressStateName;
          this.items.state.abbrev = addressStateCode;

          this.items.country.id = countryId ? countryId : "";
          this.items.country.name = countryName ? countryName : "";
          this.items.country.abbrev = countryCode ? countryCode : "";

          this.setPreEnteredDataCountry = this.items.country;
          this.setPreEnteredDataState = this.items.state;
        }
      };

      return { firstBlock, secondBlock };
    },
    initRequiredValidationRule() {
      _.forIn(this.validationRule.rules, (value, key) => {
        Object.assign(this.skipRequiredRule, {
          [key]: {
            required: value?.required ? value.required : false,
          },
        });
      });
    },

    async loadCountryList() {
      this.country_loading = true;
      this.countries = await this.geoData.getCountries();
      this.country_loading = false;
    },

    async loadStatesList() {
      this.states_loading = true;
      this.states = await this.geoData.loadStatesIn(this.items.country);
      this.states_loading = false;
    },

    countryTouched() {
      this.loadStates();
    },

    setGlobalValidationInfoForPostalCode() {
      if (this.items.country?.abbrev) {
        this.$store.commit("tempHelper/setValidationInfo", {
          postalCode: {
            countryCode: this.items.country.abbrev,
            postalCode: this.items.postalCode,
          },
        });
      }
    },
    async loadStates() {
      await this.loadStatesList();
    },

    showError(fields) {
      for (var field in fields) {
        if (
          !_.isEmpty(fields[field]) &&
          this.validationRule.invalid.hasOwnProperty(field)
        ) {
          this.validationRule.invalid[field] = true;
        } else {
          this.validationRule.invalid[field] = false;
        }
      }
    },
    updateFormData(data) {
      const CSC = ["country", "state"];
      if (CSC.includes(data.field)) {
        if (!data.id) {
          this.items[data.field] = {};
        } else {
          this.items[data.field] = { ...data };
          delete this.items[data.field].field;
        }
      } else {
        this.items[data.field] = data.value;
      }

      if (typeof this.$refs?.donationAsAnonymous?.model !== "undefined") {
        this.items.donationAsAnonymous = this.$refs.donationAsAnonymous.model;
      }
    },

    preparedValidationData() {
      let prepared = { ...this.data };
      delete prepared.addressStateCode;
      delete prepared.countryCode;
      delete prepared.countryName;
      delete prepared.addressStateName;
      delete prepared.donationAsAnonymous;

      return prepared;
    },

    isValidForm() {
      return this.validateForm(
        this.preparedValidationData(),
        this.skipRequiredRule
      );
    },
    next() {
      this.errors = this.isValidForm();
      this.isDisabledContinue = !this.errors.formIsValid;

      if (!this.errors.formIsValid) {
        this.showError(this.errors);
        setTimeout(() => {
          this.isDisabledContinue = false;
        }, 1000);
        return;
      }

      this.$store.commit("donation/setDonorInfo", {
        ...this.data,
      });

      this.$store.dispatch("logs/info", {
        reason: `current step: ${this.currentStepName}; 1. file: /presets/${this.presetName}/page/TheBasicDetails.vue; 2. user actions: FORM SUBMIT; 3. ***PROCESS***`,
        data: this.data,
      });

      if (
        this.donationNoteInfo?.show == false ||
        this.donationNoteInfo?.show == 0
      ) {
        this.$router.push({ name: "payment-details" });
      } else {
        this.$router.push({ name: "donation-note" });
      }
    },
    back() {
      this.$router.back();
    },
  },
};
