<template>
  <div
    :class="[
      {
        [$style['error']]: isInvalidInput,
      },
    ]"
  >
    <label :for="name" :class="$style['label']">
      {{ label }} {{ optional }}</label
    >
    <input
      v-if="tag == 'input'"
      ref="input"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      v-model="model"
      :disabled="isDisabled"
      :maxlength="maxLength"
      @focus="focusField"
      @blur="blurField"
      @input="onInput"
      @keypress="handleKeyPress"
      @keydown.enter="keyHandler"
    />
    <textarea
      v-if="tag == 'textarea'"
      ref="textarea"
      :placeholder="placeholder"
      v-model="model"
      :maxlength="maxLength"
      @blur="blurField"
      @input="onInput"
      @keypress="handleKeyPress"
      @keydown.enter="keyHandler"
    ></textarea>
    <div
      v-if="isInvalidInput"
      :class="$style['c-fields-container__validation-hint']"
    >
      {{ validationHint }}
    </div>
    <slot name="extra" />
  </div>
</template>

<script>
import { resetValidation } from "@/global/util/validators";
import ValidationMixin from "@/global/mixins/validation";

const ENTER_KEY_CODE = {
  code: 13,
  key: "Enter",
};

export default {
  emits: ["update"],
  mixins: [ValidationMixin],
  name: "FieldGeneral",
  props: {
    type: {
      type: String,
      default: "text",
    },
    tag: {
      type: String,
      default: "input",
    },
    maxLength: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "Label",
    },
    focus: {
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    customKeypressHandler: {
      type: Function,
      default() {},
    },
    customValidators: {
      type: Array,
      default() {
        return [];
      },
    },
    validationLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isFocused: false,
      validationCode: "",
      model: "",
    };
  },
  watch: {
    value(value) {
      this.model = value;
    },
    isInvalid(value) {
      if (value) {
        this.$nextTick(() => {
          this.validate({
            name: this.name,
            value: this.model,
          });
        });
      }
    },
  },
  created() {
    if (["cardNumber", "firstName"].includes(this.name)) {
      this.$nextTick(() => {
        this.$refs?.input?.focus();
      });
    }
  },
  computed: {
    isFieldFilled() {
      return this.value !== "";
    },
    optional() {
      let optional = "";
      if (!this.isRequired) {
        optional = "(optional)";
      }
      return optional;
    },
  },
  deactivated() {
    setTimeout(() => {
      resetValidation(this);
    }, 500);
  },
  methods: {
    onInput($event) {
      this.$emit("update", {
        field: this.name,
        value: $event.target.value,
      });
    },
    focusField() {
      this.isFocused = true;
    },
    blurField(event) {
      this.isFocused = false;

      const field = {
        name: this.name,
        value: event.target.value,
      };

      this.$nextTick(() => {
        this.validate(field);
      });
    },
    keyHandler(event) {
      //event.key === ENTER_KEY_CODE.key
      event.preventDefault();
      this.blurField(event);

      const inputs = Array.from(event.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(event.target);

      if (index < inputs.length) {
        inputs[index + 1]?.focus();
      }
    },

    handleKeyPress(event) {
      const characterCode = event.which ? event.which : event.keyCode;

      if (characterCode === ENTER_KEY_CODE.code) {
        return;
      }

      this.customKeypressHandler(event);
    },
  },
};
</script>

<style module scoped>
input {
  height: auto !important;
}
.c-fields-container__validation-hint {
  display: block !important;
  line-height: 12px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color: #d03f2d !important;
}

.error label {
  color: #dc2626 !important;
}
.label {
  display: block !important;
  line-height: 12px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color: #6b7280 !important;
  margin-bottom: 9px !important;
  text-align: left !important;
}
</style>
