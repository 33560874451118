function generateBreakpoint(width) {
  return window.matchMedia(`(min-width: ${width}px)`);
}

const breakpoints = {
  bpLittle: generateBreakpoint(370),
  bpSmall: generateBreakpoint(380),
  bpMedium: generateBreakpoint(736),
  bpLarge: generateBreakpoint(1025),
};

const defaultState = {
  ...Object.keys(breakpoints).reduce(
    (obj, bp) => ({ ...obj, [bp]: breakpoints[bp].matches }),
    {}
  ),
  rem: 10,
};

const actions = {
  monitorBreakpoints({ commit }) {
    Object.keys(breakpoints).forEach((breakpoint) => {
      breakpoints[breakpoint].addListener(({ matches }) => {
        commit("updateBreakpoint", { breakpoint, matches });
      });
    });
  },
};

const mutations = {
  updateBreakpoint(state, { breakpoint, matches }) {
    state[breakpoint] = matches;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
};
