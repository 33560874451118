import { mapState, mapGetters } from "vuex";
import { config } from "@/config";
import formatMoney from "@/global/util/money";

const currentStepName = "summary";
export default {
  inject: ["$http"],
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    titleStepName: "Summary",
    currentStepName: currentStepName,
    hasImage: true,
    isDisabledContinue: false,
    messageError: {
      show: false,
      message: "There was an issue with your payment method.",
    },
    isLoading: true,
    name: "",
    country: "",
    countryBilling: "",
    address: "",
    email: "",
    city: "",
    stateProvince: "",
    postalCode: "",
    postalCodeBilling: "",
    donation: "",
    fees: "",
    totalAmount: "",
    securityCode: "",
    expirationDate: "",
    cardNumber: "",
    paymentMethod: "",
    phone: "",
    note: "",
    coverFee: false,
    anonymous: false,
    countryCode: "",
    countryCodeBilling: "",
    stateCode: "",
  }),
  computed: {
    ...mapState("screen", ["bpMedium", "bpLarge"]),
    ...mapState("config", { config: (state) => state.config }),
    ...mapGetters("donation", ["getSummaryPreparedForPay", "isMethodCard"]),
    ...mapState("donation", ["paymentDetails", "donorInfo"]),
    showLast4DigitsCards() {
      if (this.cardNumber.length) {
        return this.cardNumber.replace(/\s+/g, "").replace(/.(?=.{4})/g, "x");
      }
      return "";
    },
  },
  mounted() {
    const {
      name,
      country,
      countryBilling,
      address,
      city,
      email,
      stateProvince,
      postalCode,
      donation,
      fees,
      totalAmount,
      securityCode,
      expirationDate,
      cardNumber,
      coverFee,
      anonymous,
      paymentMethod,
      phone,
      note,
      countryCode,
      countryCodeBilling,
      stateCode,
      postalCodeBilling,
    } = this.getSummaryPreparedForPay;

    this.name = name;
    this.country = country;
    this.countryBilling = countryBilling;
    this.address = address;
    this.city = city;
    this.email = email;
    this.stateProvince = stateProvince;
    this.postalCode = postalCode;
    this.postalCodeBilling = postalCodeBilling;
    this.donation = formatMoney(donation, true, true);
    this.fees = formatMoney(fees, true, true);
    this.totalAmount = formatMoney(totalAmount, true, true);
    this.securityCode = securityCode;
    this.expirationDate = expirationDate;
    this.cardNumber = cardNumber;
    this.coverFee = coverFee;
    this.anonymous = anonymous;
    this.paymentMethod = paymentMethod;
    this.phone = phone;
    this.note = note;
    this.countryCode = countryCode;
    this.countryCodeBilling = countryCodeBilling;
    this.stateCode = stateCode;

    this.isLoading = false;
  },
  methods: {
    next() {
      this.isDisabledContinue = true;
      
      const session_id = this.config?.config?.sessionId;
      const expiration_month = Number(this.expirationDate.substr(0, 2));
      const expiration_year = Number(this.expirationDate.substr(2));

      const data = {
        amount: parseFloat((this.donation + "").replaceAll(',','')),
        session_id,
        user_name: this.name,
        email: this.email,
        cc_number: this.cardNumber,
        cvv: this.securityCode,
        expiration_month,
        expiration_year,
        cover_fee: this.coverFee,
        anonymous: this.anonymous,
        payment_method: this.paymentMethod,
        phone: this.phone,
        note: this.note,
        address: {
          country: this.countryCode,
          country_name: this.country,
          state: this.stateCode,
          state_name: this.stateProvince,
          city: this.city,
          postal_code: this.postalCode,
          street: this.address,
        },
        billing_address: {
          postal_code: this.postalCodeBilling,
          country_name: this.countryBilling,
          country: this.countryCodeBilling,
        },
      };

      if (config.mode.demo) {
        this.$router.push({ name: "thank-you" });
        return;
      }

      this.$http
        .post(`/payments/proceed`, data)
        .then((response) => {
          const resp = response.data;
          if (resp?.status == "error" || resp?.status == "fail") {
            this.isDisabledContinue = false;
            this.$store.dispatch("notifications/notifyError", resp.message);
          }

          this.$store.dispatch("logs/warning", {
            reason: `current step: ${this.currentStepName}; 1. file: /presets/${this.presetName}/page/TheSummary.vue; 2. user actions: FORM SUBMIT/.post(payments/proceed)/.then(response).payStatus: [${resp.status}]; 3. ***PROCESS***`,
            data: {
              payData: {},
              response: resp,
            },
          });

          if (resp?.status == "success") {
            this.$router.push({ name: "thank-you" });
            return;
          }
        })
        .catch((error) => {
          this.isDisabledContinue = false;
          this.$store.dispatch(
            "notifications/notifyError",
            this.messageError.message
          );

          this.$store.dispatch("logs/error", {
            reason: `current step: ${this.currentStepName}; 1. file: /presets/${this.presetName}/page/TheSummary.vue; 2. user actions: FORM SUBMIT/.post(payments/proceed)/.catch(error); 3. ***PROCESS***`,
            data: {
              payData: {},
              response: {
                ...error,
                componentMessageError: this.messageError.message,
              },
            },
          });
        });
    },
    back() {
      this.$router.back();
    },
  },
};
