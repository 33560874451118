<template>
  <span :class="$style['widget-helper']">
    <span :class="$style['widget-helper__icon']">
      <IconHelper />

      <div v-if="tooltip" :class="$style['tooltip']">
        <slot name="tooltip"></slot>
      </div>
    </span>
  </span>
</template>

<script>
export default {
  name: "VIconHelper",
  props: {
    tooltip: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style module scoped>
.tooltip {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s linear;
  font-size: 10px;
  background-color: #555;
  color: #fff;
  padding: 10px;
  border-radius: 7px;
  line-height: 1.5;
  min-width: 223px;
  position: absolute;
  left: -12px;
  transform: translateX(-100%);
}
.tooltip:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 7px;
  border-color: transparent transparent transparent #555;
  position: absolute;
  right: 0;
  transform: translate(100%, -50%);
  top: 50%;
}
.widget-helper__icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.widget-helper {
  display: inline-flex !important;
  cursor: pointer !important;
  margin-left: 10px !important;
}

.widget-helper__icon {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 20px !important;
  height: 20px !important;
  background: #f1f1f1 !important;
  border: 1px solid #bebebe !important;
  border-radius: 50% !important;
}

@media screen and (max-width: 370px) {
  .widget-helper__icon {
    width: 16px !important;
    height: 16px !important;
  }
  .widget-helper__icon svg {
    width: 5px !important;
    height: 10px !important;
  }
}
</style>
