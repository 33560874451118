<template>
  <EDonationView
    :title="titleStepName"
    :step="step"
    :show-card-heading-slot="false"
    :current-step-name="currentStepName"
  >
    <template #card_body_block_donation-input>
      <div :class="$style['widget-form-group']">
        <div
          :class="[$style['widget-form-row'], $style['widget-form-row-half']]"
        >
          <div :class="$style['widget-form-field']">
            <FieldGeneral
              ref="firstName"
              :value="items.firstName"
              name="firstName"
              label="First Name"
              placeholder="First Name"
              :max-length="40"
              :is-required="validationRule.rules.firstName.required"
              :is-invalid="validationRule.invalid.firstName"
              @update="updateFormData"
            />
          </div>

          <div :class="$style['widget-form-field']">
            <FieldGeneral
              ref="lastName"
              :value="items.lastName"
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              :max-length="40"
              :is-required="validationRule.rules.lastName.required"
              :is-invalid="validationRule.invalid.lastName"
              @update="updateFormData"
            />
          </div>
        </div>
        <div :class="$style['widget-form-row']">
          <div :class="[$style['widget-form-field']]">
            <FieldGeneral
              ref="email"
              :value="items.email"
              label="Email"
              name="email"
              placeholder="email@fundeasy.com"
              :is-required="validationRule.rules.email.required"
              :is-invalid="validationRule.invalid.email"
              @update="updateFormData"
            />
          </div>
        </div>
        <div :class="$style['widget-form-row']">
          <div :class="$style['widget-form-field']">
            <FieldMobileNumber
              ref="phone"
              :value="items.phone"
              label="Phone"
              name="phone"
              placeholder="8476287746"
              :is-required="validationRule.rules.phone.required"
              :is-invalid="validationRule.invalid.phone"
              :max-length="validationRule.rules.phone.maxLength"
              @update="updateFormData"
            />
          </div>
        </div>
      </div>

      <div :class="$style['widget-form-group']">
        <div :class="$style['widget-form-row']">
          <div :class="$style['widget-form-field']">
            <FieldGeneral
              ref="address"
              :value="items.address"
              name="address"
              type="text"
              label="Street Address"
              placeholder="123 North Robinson St."
              :is-required="validationRule.rules.address.required"
              :is-invalid="validationRule.invalid.address"
              :max-length="validationRule.rules.address.maxLength"
              @update="updateFormData"
            />
          </div>
        </div>

        <div :class="$style['widget-form-row']">
          <div :class="$style['widget-form-field']">
            <FieldGeneral
              label="City"
              ref="cityName"
              :value="items.cityName"
              name="cityName"
              type="text"
              placeholder="Chester"
              :is-required="validationRule.rules.cityName.required"
              :is-invalid="validationRule.invalid.cityName"
              :max-length="40"
              @update="updateFormData"
            />
          </div>
        </div>
        <div
          :class="[$style['widget-form-row'], $style['widget-form-row-half']]"
        >
          <div :class="$style['widget-form-field']">
            <AutoComplete
              label="State"
              collection-db="states"
              name="state"
              placeholder="New York"
              max-length="40"
              :loading-collection="states_loading"
              :clear-collection-state="clearCollectionState"
              :is-required="validationRule.rules.addressStateId.required"
              :is-invalid="validationRule.invalid.addressStateId"
              @change="updateFormData"
              @selectedOption="selectedOption"
              :set-pre-entered-data="setPreEnteredDataState"
            />
          </div>
          <div :class="$style['widget-form-field']">
            <FieldGeneral
              ref="postalCode"
              :value="items.postalCode"
              name="postalCode"
              label="Postal Code"
              placeholder="90210"
              :is-required="validationRule.rules.postalCode.required"
              :is-invalid="validationRule.invalid.postalCode"
              :max-length="validationRule.rules.postalCode.maxLength"
              @update="updateFormData"
            />
          </div>
        </div>
        <div :class="$style['widget-form-row']">
          <div :class="$style['widget-form-field']">
            <AutoComplete
              label="Country/Province"
              collection-db="countries"
              :default="defaultCountry"
              name="country"
              placeholder="United States"
              max-length="40"
              :is-required="validationRule.rules.country.required"
              :is-invalid="validationRule.invalid.countryId"
              @change="updateFormData"
              @selectedOption="selectedOption"
              :set-pre-entered-data="setPreEnteredDataCountry"
            />
          </div>
        </div>
      </div>

      <FieldCheckbox
        v-if="allowAnonymousDonors"
        ref="donationAsAnonymous"
        :value="items.donationAsAnonymous"
        label="I want my donation to be anonymous"
        @change="updateFormData"
        name="donationAsAnonymous"
      />
    </template>

    <template v-slot:footer-buttons>
      <VButtonBack :label="buttonBack" @click.prevent="back" />
      <VButtonNext
        :label="buttonNext"
        :is-disabled="isDisabledContinue"
        :opacity="blockButton"
        @click.stop="next"
      />
    </template>
  </EDonationView>
</template>

<script>
import basicDetails from "@/global/mixins/page/basicDetails.js";

export default {
  mixins: [basicDetails],

  data: () => ({
    presetName: "embedded",
  }),

  async mounted() {
    this.setPreEnteredData().firstBlock();
  },
  async created() {
    this.setValidationType({ type: "fieldError" });

    await this.geoData.preload();

    await this.loadCountryList();
    await this.loadStates();

    this.setPreEnteredData().secondBlock();
  },
};
</script>

<style module src="@/global/assets/css/page/module/basicDetails.css"></style>
