import EmbeddedComponent from "@embedded/App.vue";

import EmbeddedComponentRouter from "@embedded/router";
import storeGlobal from "@/store";

export default {
  component: EmbeddedComponent,
  router: EmbeddedComponentRouter,
  store: storeGlobal,
};
