const loki = require("lokijs");

class DBConnection {
  constructor(instance) {
    this._instance = instance;
  }

  createInstance() {
    return new loki("csc.db");
  }

  static getInstance(instance = {}) {
    if (!this._instance) {
      this._instance = new DBConnection(instance);
    }
    return this._instance?._instance;
  }
}

module.exports = DBConnection.getInstance(new loki("csc.db"));
