const DbLoki = require("@/global/util/db.loki");

export default {
  data() {
    return {
      items: [],
      query: "",
      current: -1,
      loading: false,
      selectFirst: false,
    };
  },

  computed: {
    hasItems() {
      return this.items.length > 0;
    },

    isEmpty() {
      return !this.query;
    },

    isDirty() {
      return !!this.query;
    },
  },

  methods: {
    setResult(i) {
      this.current = i;
    },

    clearCollectionDb(collectionDb) {
      let collectionStates = DbLoki.getCollection(collectionDb);
      if (collectionStates) {
        collectionStates.clear();
      }
    },

    async showAllCollection() {
      let request = await DbLoki.getCollection(this.collectionDb);
      this.items = request?.data ? request.data : [];
    },

    async getAllCollection() {
      let request = await DbLoki.getCollection(this.collectionDb);
      return request?.data ? request.data : [];
    },

    update() {
      this.cancel();

      if (!this.query) {
        return this.reset();
      }

      if (this.minChars && this.query.length < this.minChars) {
        return;
      }

      this.loading = true;

      this.fetch().then(async (response) => {
        if (response && this.query) {
          let data = response;
          this.items = this.limit ? data.slice(0, this.limit) : data;
          this.current = -1;
          this.loading = false;

          if (this.selectFirst) {
            this.down();
          }
          this.setActive(0);
        }
      });
    },

    async fetch() {
      let cancel = new Promise((resolve) => (this.cancel = resolve));
      let filter = this.query.toLocaleLowerCase();

      let collection = DbLoki.getCollection(this.collectionDb);

      if (collection) {
        collection = await collection
          .chain()
          .where(function (obj) {
            return obj.name.toLocaleLowerCase().indexOf(filter) === 0;
          })
          .data();

        if (collection) {
          collection = [
            ...new Map(collection.map((item) => [item["id"], item])).values(),
          ];
        }
      }

      return Promise.race([cancel, collection]);
    },

    cancel() {
      // used to 'cancel' previous searches
    },

    reset() {
      this.items = [];
      this.query = "";
      this.loading = false;
    },

    setActive(index) {
      this.current = index;
    },

    activeClass(index) {
      return {
        activeSDefsdW: this.current === index,
      };
    },

    async hit(event) {
      event.preventDefault();

      if (this.isEmpty) {
        await this.updateAndShowList();
      }

      if (this.current !== -1) {
        this.onHit(this.items[this.current]);
      } else {
        if (!this.hasItems) {
          this.setFocusToNextInput(event);
        }
      }
    },

    setFocusToNextInput(event) {
      event.preventDefault();

      const querySelectorAll = event.target?.form?.querySelectorAll("input");
      if (!querySelectorAll) return;

      const inputs = Array.from(querySelectorAll);
      const index = inputs.indexOf(event.target);

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    up(ev) {
      ev.preventDefault();
      if (this.current > 0) {
        this.current = this.current - 1;
        this.fixScrolling();
      }
    },

    down(ev) {
      ev.preventDefault();
      if (this.current < this.items.length - 1) {
        this.current = this.current + 1;
        this.fixScrolling();
      }
    },

    fixScrolling() {
      const liH = this.$refs.options[this.current].clientHeight;
      this.$refs.scrollContainer.scrollTop = liH * this.current;
    },

    onHit() {
      throw new Error("You need to implement the `onHit` method");
    },
  },
};
