<template>
  <input
    v-focus
    placeholder="0.00"
    type="tel"
    v-model="amount"
    :name="name"
    @blur="blurField"
    @keypress="handleKeyPress"
  />
</template>

<script>
import formatMoney, { cutDecimals } from "@/global/util/money";

const ENTER_KEY_CODE = 13;
const DOT_CHARACTER_CODE = 46;

export default {
  emits: ["update"],

  name: "FieldAmount",
  props: {
    name: {
      type: String,
      default: "",
    },
    value: {
      default: "",
    },
    label: {
      type: String,
      default: "Label",
    },
    customValidators: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isInvalid: false,
      validationCode: "",
    };
  },
  computed: {
    amount: {
      get() {
        if (this.value === null) {
          return null;
        }
        return formatMoney(this.value);
      },
      set(newValue) {
        if (newValue === "") {
          this.$emit("update", "0");
          return;
        }
        const lastChar = newValue.substr(newValue.length - 1);
        let cut = cutDecimals(newValue);
        if (lastChar === ".") {
          cut += ".";
        }
        this.$emit("update", cut);

        this.$forceUpdate();
      },
    },
  },
  methods: {
    handleKeyPress(event) {
      const characterCode = event.which ? event.which : event.keyCode;

      if (characterCode === ENTER_KEY_CODE) {
        return;
      }

      if (
        (characterCode === DOT_CHARACTER_CODE && event.target.value === null) ||
        !/^[0-9.]+$/.test(event.key) ||
        (characterCode === DOT_CHARACTER_CODE &&
          event.target.value.toString().includes("."))
      ) {
        event.preventDefault();
      }
    },
    blurField() {
      if (this.amount == null) return;

      const currentSplittedAmount = this.amount.split(".");

      if (
        typeof currentSplittedAmount[1] === "string" &&
        currentSplittedAmount[1].length === 1
      ) {
        this.amount = this.amount + 0;
      }

      if (
        typeof currentSplittedAmount[1] === "string" &&
        currentSplittedAmount[1].length === 0
      ) {
        this.amount = this.amount.replace(/\./, "");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  height: auto !important;
}
</style>
