import { mapState, mapGetters } from "vuex";

function widgetHeight() {
  return Math.floor(window.innerHeight * 0.88);
}
const transitionsWithInstantHeightAnimation = ["down", "up", "fade"];

export default {
  data() {
    return {
      isDonationPage: false,
      transitionName: "right",
      history: ["/", "/"],
      height: 0,
      heightAnimationInProgress: false,
      isCurrentTransitionWithInstantHeightAnimation: false,
      renderBase: true,
      currentRouteName: "",
    };
  },
  computed: {
    ...mapState("screen", { isDesktop: "bpMedium" }),
    ...mapState("config", { config: (state) => state.config }),
    ...mapGetters("config", ["getInitialWidgetData"]),
    isDesktopWidget() {
      return this.isDesktop;
    },
    styleHeight() {
      if (!(this.isDesktopWidget && this.height)) {
        return null;
      }
      return `${this.height}px`;
    },
  },
  watch: {
    $route(to, from) {
      let isBack = false;
      this.currentRouteName = to;

      if (
        this.history.length > 1 &&
        this.history[this.history.length - 2] === to.path
      ) {
        this.history.pop();
        isBack = true;
      } else if (
        this.history.length > 2 &&
        this.history[this.history.length - 3] === to.path
      ) {
        this.history.pop();
        this.history.pop();
        isBack = true;
      } else {
        this.history.push(to.path);
      }

      this.transitionName = this.getTransitionName(from, to, isBack);
      this.isCurrentTransitionWithInstantHeightAnimation =
        transitionsWithInstantHeightAnimation.includes(this.transitionName);
    },
    currentRouteName(value) {
      const { name, meta: { stepName }, params, query} = value;
      this.$store.commit("intermediateDataCollect/setRouteData", {
        currentStep: name,
        history: {
          [name]: {
            stepName, params, query
          }
        },
      });
    },
  },
  created() {},
  mounted() {
    this.currentRouteName = this.$route;
    this.initWidgetPayloadInfo(this.prepareWidgetPayloadInfo());

    this.$el.addEventListener("transitionend", this.transitionEnd);
  },
  beforeUnmount() {
    this.$el.removeEventListener("transitionend", this.transitionEnd);
  },
  methods: {
    prepareWidgetPayloadInfo() {
      const { hostname, origin, href, pathname, search } = location;
      const widgetInitData = this.getInitialWidgetData;
      const instanceName = widgetInitData && widgetInitData.instanceName ? widgetInitData.instanceName : "";
      const sessionId = this.config?.config?.sessionId ?? "";
      const token = this.config?.external?.token ?? ""

      const urlParams = new URLSearchParams(search);
      const memberId = urlParams.has('mid') ? urlParams.get('mid') : "";

      return {
        token,
        sessionId,
        widgetDisplayMethod: instanceName,
        location: { hostname, origin, href, pathname },
        addressWidgetDisplayed: href,
        memberId,
      };
    },
    initWidgetPayloadInfo(widgetPayloadInfo) {
      this.$store.commit("intermediateDataCollect/setWidgetPayloadInfo", widgetPayloadInfo);
    },
    leave() {
      // noop for now
    },
    beforeEnter(el) {
      this.enterEl = el;
    },
    afterLeave(el) {
      this.setBottomOverflow(el);
      el.style.transform = "";
    },
    beforeLeave(el) {
      this.leaveEl = el;
      if (this.isDesktopWidget) {
        this.height = el.scrollHeight;
      }
      // defineEmits("setAbsolute", true, el);
      // baseEvents.$emit("setAbsolute", true, el);
      if (!this.isDesktopDonationPage) {
        el.__eg_offset = this.getPosition(el);
      }
      if (this.isDesktopWidget && el.scrollHeight < widgetHeight()) {
        this.setBottomOverflow(el, "hidden");
      }
    },
    setPosition(el) {
      const position = el.__eg_offset;
      const container = el.querySelector(this.querySelectorCardViewBottom);
      if (position && container) {
        container.scrollTop = position;
      }
    },
    enter(el) {
      if (!this.isDesktopDonationPage) {
        this.setPosition(el);
      }
      if (this.isDesktopWidget) {
        this.setBottomFlex(el, "1 0 auto");
        this.$nextTick(() => {
          window.getComputedStyle(el).transform;
          if (el.scrollHeight > widgetHeight()) {
            this.setBottomFlex(el);
          }
        });
        if (this.isCurrentTransitionWithInstantHeightAnimation) {
          window.requestAnimationFrame(() =>
            window.requestAnimationFrame(() =>
              window.requestAnimationFrame(() => this.startHeightAnimation(el))
            )
          );
        }
      }
    },
    setBottomFlex(el, val = "") {
      if (this.isDesktopWidget) {
        el.querySelector(this.querySelectorCardViewBottom).style.flex = val;
      }
    },
    setBottomShrink(el, val = "") {
      if (this.isDesktopWidget) {
        el.querySelector(this.querySelectorCardViewBottom).style.flexShrink =
          val;
      }
    },
    startHeightAnimation(el) {
      if (!this.isDesktopWidget) {
        return;
      }
      this.setBottomFlex(el, "0 0 auto");
      const oldHeight = this.$el.style.height;
      this.$el.style.height = null;
      window.getComputedStyle(el).transform;
      const newHeight = Math.min(widgetHeight(), el.scrollHeight);
      this.$el.style.height = oldHeight;
      this.setBottomFlex(el);
      if (newHeight < widgetHeight()) {
        this.setBottomShrink(el, "0");
      }
      window.getComputedStyle(el).transform;
      this.height = newHeight;
      this.heightAnimationInProgress = true;
      this.useFixedTransformIfNecessary();

      setTimeout(() => this.endHeightAnimation(el), 501);
    },
    endHeightAnimation(el) {
      if (!this.heightAnimationInProgress) {
        return;
      }
      this.setBottomShrink(el);
      this.height = 0;
      this.heightAnimationInProgress = false;
    },
    useFixedTransformIfNecessary() {
      // necessary due to problems on safari
      if (this.transitionName === "down") {
        this.leaveEl.style.transform = `translate3d(0, ${this.height}px, 0)`;
      }
    },
    afterEnter(el) {
      // baseEvents.$emit("setAbsolute", false);
      if (!this.isCurrentTransitionWithInstantHeightAnimation) {
        this.startHeightAnimation(el);
      }
    },
    setBottomOverflow(el, val = "") {
      if (this.isDesktopWidget) {
        el.querySelector(this.querySelectorCardViewBottom).style.overflow = val;
      }
    },
    getTransitionName(from, to, isBack) {
      if (to.name === "start" && from.meta.transitionToStart) {
        return from.meta.transitionToStart;
      }

      const route = isBack ? from : to;
      if (route.meta.verticalAnimation) {
        return isBack ? "down" : "up";
      } else if (route.meta.fadeAnimation) {
        return "fade";
      }
      return isBack ? "left" : "right";
    },
    getPosition(el) {
      const container = el.querySelector(this.querySelectorCardViewBottom);
      if (container) {
        return container.scrollTop;
      }
      return 0;
    },
    resetBase() {
      return new Promise((resolve) => {
        this.renderBase = false;
        this.$nextTick(() => {
          this.renderBase = true;
          this.$nextTick(resolve);
        });
      });
    },
  },
};
