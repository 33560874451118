import { mapState, mapGetters } from "vuex";
import storeMixin from "@/global/mixins/store/store.js";

const currentStepName = "thankYou";
export default {
  mixins: [storeMixin],
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    titleStepName: "Thank you!",
    currentStepName: currentStepName,
    email: "",
  }),
  computed: {
    ...mapState("screen", ["bpMedium", "bpLarge"]),
    ...mapGetters("donation", ["getSummaryPreparedForPay"]),
    ...mapState("config", {
      externalConfigReloadApp: (state) =>
        state.config["external"]?.reload || false,
    }),
  },
  mounted() {
    const { email } = this.getSummaryPreparedForPay;
    this.email = email;

    this.$store.dispatch("logs/warning", {
      reason: `current step: ${this.currentStepName}; 1. file: /presets/${this.presetName}/page/TheThankYou.vue; 2. ***FINISH***`,
    });

    setTimeout(() => {
      if (this.externalConfigReloadApp) {
        window.location.reload(false);
      } else {
        this.initStoreAction("resetState")
          .state("donation")
          .state("notifications")
          .state("eventEmitter")
          .state("intermediateDataCollect")
          .state("flag");
        this.$store.dispatch("config/initUniqueSessionsData");
        this.$router.push({ name: "start" });
      }
    }, 3000);
  },
  methods: {},
};
