<template>
  <div :class="$style['widget-header']">
    <h6 :class="$style['widget-header__title']">
      {{ titleToShow }}
    </h6>
    <div :class="$style['widget-header__nav']">
      <div :class="$style['widget-progress']" v-html="showProgressSteps"></div>
      <div :class="$style['widget-next']">
        <IconForwardArrow />
      </div>
      <div v-if="closeIcon" :class="$style['widget-close']">
        <div @click="widgetClose">
          <IconClose />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore, mapState, mapGetters } from "vuex";
import { computed, useCssModule, reactive } from "vue";
import * as constants from "@/config/constants";
import { isEmpty, cloneDeep } from "lodash";
import storeMixin from "@/global/mixins/store/store.js";
export default {
  mixins: [storeMixin],
  name: "DonationNavigation",
  props: {
    title: {
      type: String,
      default: null,
    },
    closeIcon: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      required: true,
    },
    currentStepName: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const style = useCssModule();

    const state = reactive({
      // countFormSteps: computed(
      //   () => useStore().state.config.config["form-steps"].count
      // ),
      stepInfo: computed(
        () =>
          useStore().state.organization?.organization?.widgetSettings?.stepsInfo
      ),
    });

    let countFormSteps = 0;
    let stepInfo = cloneDeep(state.stepInfo);

    for (let step in stepInfo) {
      countFormSteps++;
      stepInfo[step].step = countFormSteps;
      for (let key in stepInfo[step]) {
        if (key == "show" && !stepInfo[step][key]) {
          delete stepInfo[step];
          countFormSteps -= 1;
        }
      }
    }

    const showProgressSteps = reactive(
      computed(() => {
        let span = "";
        for (var step = 0; step < countFormSteps; step++) {
          span += `<span class="${
            step < stepInfo[props.currentStepName].step ? style.active : ""
          }"></span>`;
        }
        return span;
      })
    );

    return {
      showProgressSteps,
    };
  },
  data: () => ({
    modalMode: true,
  }),
  computed: {
    ...mapState("notifications", ["eventsCancelModal"]),
    ...mapGetters("eventEmitter", ["getTimeOutModalsInfo"]),

    titleToShow() {
      if (this.title !== null) {
        return this.title;
      }
      return "";
    },
  },
  watch: {
    "eventsCancelModal.confirmedCancellationDonation": {
      handler(action) {
        if (action && this.closeIcon) {
          let element = document.getElementsByTagName(
              `${constants.WIDGET_NAME}${constants.modal}`
            ),
            index;

          for (index = element.length - 1; index >= 0; index--) {
            element[index].parentNode.removeChild(element[index]);
          }
          window[`loaded-${constants.WIDGET_NAME}${constants.modal}`] = false;

          this.initStoreAction("resetState")
            .state("donation")
            .state("notifications")
            .state("eventEmitter")
            .state("intermediateDataCollect")
            .state("flag");
          this.$store.dispatch(
            "organization/updateWidgetSettingToOriginalState"
          );
          this.$store.dispatch("config/initUniqueSessionsData");
          this.$router.push({ name: "start" });
        }
      },
      deep: true,
    },
  },
  methods: {
    widgetClose() {
      const isActiveSessionTimedOutModal =
        !isEmpty(this.getTimeOutModalsInfo) &&
        this.getTimeOutModalsInfo?.sessionTimedOutModal?.show == true;

      if (isActiveSessionTimedOutModal) {
        //close the widget without additional modals
        this.$store.dispatch(
          "notifications/confirmedCancellationDonation",
          true
        );
      } else {
        //show the additional modal window with warning
        this.$store.dispatch("notifications/clickedCancelDonationWidget", true);
      }
    },
  },
};
</script>

<style module>
.widget-close {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  width: 65px !important;
  height: 65px !important;
  margin-right: -40px !important;
  margin-left: 20px !important;
  border-left: 2px solid rgba(255, 255, 255, 0.19) !important;
}
.widget-header {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  overflow: hidden !important;
  height: 65px !important;
  padding: 0 40px !important;
  background-color: var(--theme-style-mainColor) !important;
}
.widget-header__title {
  color: #fff !important;
  line-height: 19px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: "Inter", sans-serif !important;
}
.widget-header__nav {
  display: flex !important;
  align-items: center !important;
}
.widget-progress {
  display: flex !important;
  align-items: center !important;
}
.widget-progress span {
  display: flex !important;
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
  border: 2px solid #ffffff !important;
  background-color: transparent !important;
  opacity: 0.5 !important;
  margin-right: 10px !important;
}
.widget-progress span.active {
  background-color: #fff !important;
}
.widget-next {
  display: flex;
}

.widget-next svg path {
  opacity: 0.5 !important;
  fill: #fff !important;
}
@media screen and (max-width: 768px) {
  .widget-header {
    flex-shrink: 0 !important;
    border-radius: 0 !important;
  }
}

@media screen and (max-width: 475px) {
  .widget-header {
    padding: 0 20px !important;
  }
  .widget-close {
    margin-right: -20px !important;
  }
  .widget-close {
    width: 50px !important;
  }
}

@media screen and (max-width: 370px) {
  .widget-close {
    width: 50px !important;
    height: 54px !important;
    margin-left: 10px !important;
  }
  .widget-header {
    height: 54px !important;
    padding: 0 15px !important;
  }
  .widget-header__title {
    font-size: 14px !important;
  }
  .widget-progress span {
    width: 12px !important;
    height: 12px !important;
    margin-right: 9px !important;
  }
  .widget-next svg {
    width: 13px !important;
    height: 11px !important;
  }
}
</style>
