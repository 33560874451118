import { nextTick } from "vue";

const defaultState = () => ({
  timeOutModals: {},
});

const actions = {
  timeOutModals({ commit }, action) {
    nextTick(() => {
      commit("timeOutModals", action);
    });
  },

  resetState({ commit }) {
    commit("resetState");
  },
};

const getters = {
  getTimeOutModalsInfo(state) {
    return state.timeOutModals;
  },
};

const mutations = {
  timeOutModals(state, action) {
    state.timeOutModals = Object.assign(state.timeOutModals, action.payload);
  },

  resetState(state) {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state: defaultState(),
  actions,
  getters,
  mutations,
};
