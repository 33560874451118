// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".thankYou_widget-card_wHLhi .thankYou_bold_T1I20[data-v-3ffaeb28]{font-weight:500!important}.thankYou_widget-thank-you_BTku3[data-v-3ffaeb28]{display:flex!important;flex-direction:column!important;align-items:center!important;padding:52px 0 72px!important}.thankYou_widget-thank-you__icon_onwGi svg[data-v-3ffaeb28]{width:120px!important;height:120px!important}.thankYou_widget-title_v513L[data-v-3ffaeb28]{font-weight:500!important;font-size:21px!important;line-height:25px!important;color:#111827!important;margin-bottom:10px!important;word-break:break-word!important}@media screen and (max-width:370px){.thankYou_widget-title_v513L[data-v-3ffaeb28]{font-size:19px!important;line-height:21px!important}.thankYou_widget-thank-you_BTku3[data-v-3ffaeb28]{padding:42px 0 62px!important}.thankYou_widget-thank-you__icon_onwGi svg[data-v-3ffaeb28]{width:100px!important;height:100px!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget-card": "thankYou_widget-card_wHLhi",
	"bold": "thankYou_bold_T1I20",
	"widget-thank-you": "thankYou_widget-thank-you_BTku3",
	"widget-thank-you__icon": "thankYou_widget-thank-you__icon_onwGi",
	"widget-title": "thankYou_widget-title_v513L"
};
export default ___CSS_LOADER_EXPORT___;
