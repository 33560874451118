const buttonClassName = "fe-easygiving-button_TRDSfdq";
const querySelectorClass = "show-fe-easygiving-modal_DFMWer";
const buttonClass = `.${buttonClassName}`;
const divButtonClassNameWrapper = "button-wrapper-JDFNfdf";
const divButtonClassWrapper = `.${divButtonClassNameWrapper}`;
import { cloneDeep } from "lodash";

const defaultState = {
  organization: {
    loading: false,
    loadingSettings: false,
    widgetSettings: {},
    widgetSettingsOrigin: {},
    memberSettings: {},
    buttonSettings: {
      feEasygivingDefaultButtonSettings: {
        buttonClassName,
        buttonClass,
        divButtonClassWrapper,
        divButtonClassNameWrapper,
        querySelectorClass,
      },
      loadingButtonSettings: false,
      mountedButton: false,
    },
    paymentProcessor: "",
  },
};

function setThemeColors(variable, colors) {
  let style = document.documentElement.style;
  let styleVariables = [];

  for (let key of Object.keys(colors)) {
    style.setProperty(variable + key, colors[key]);
    styleVariables.push({ [variable + key]: colors[key] });
  }
}

const moduleGetters = {
  isWepayProcessor({ paymentProcessor }) {
    return paymentProcessor === "wepay";
  },
  theme(state) {
    return (
      (state.organization &&
        state.organization.widgetSettings &&
        state.organization.widgetSettings.theme) ||
      {}
    );
  },
  feEasygivingButtonSettings(state) {
    return (
      (state.organization &&
        state.organization.buttonSettings &&
        state.organization.buttonSettings.feEasygivingButtonSettings) ||
      {}
    );
  },

  feEasygivingButtonText(state, getters) {
    return getters.feEasygivingButtonSettings?.text || "Donate Now!";
  },
  getStatusMountedButton(state, getters) {
    return state.organization.buttonSettings.mountedButton;
  },

  feEasygivingButtonStyle(state, getters) {
    const buttonSettings = getters.feEasygivingButtonSettings?.style;

    if (buttonSettings) {
      return buttonSettings;
    }
    return `
      ${divButtonClassWrapper} 
      .${buttonClass} {
        background: #047857;border: 2px solid #047857;color: #fff;
      }
      ${divButtonClassWrapper} ${buttonClass}:last-child {margin: 0;}
      ${divButtonClassWrapper} ${buttonClass}:hover {background: #047857;}
      ${buttonClass} {
        border-collapse: collapse;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        box-sizing: border-box;
        font-family: 'Proxima Nova', 'Helvetica', Arial, sans-serif;
        text-align: center;
        border-radius: 3px;
        margin: 0;
        padding: 6px 15px;
        text-decoration: none;
        border: 1px solid #cacaca;
        cursor: pointer;
        color: #fff;
        line-height: 28px;font-weight: 400;
        padding-top: 17px;
        padding-bottom: 15px;
        font-size: 31px;
        width: 100%;
        background: #047857;
        border-color: #047857;
        box-shadow: 0 2px 0 0 #047857;
        float: right;
        width: 260px;
        height: 62px;
        -webkit-user-select: none;
        -moz-user-select: none;
      }
      @media screen and (max-width: 919px){
        ${buttonClass} {
          width: 100%;
        }
      }

      `;
  },
  hasWidgetSettings({ organization }) {
    const { widgetSettings, loadingSettings } = organization;
    const hasWidgetSettings = Object.keys(widgetSettings).length > 0;
    return hasWidgetSettings && loadingSettings;
  },

  hasMemberSettings({ organization }) {
    const { memberSettings } = organization;
    return Object.keys(memberSettings).length > 0;
  },

  hasButtonSettings(state, getters) {
    return Object.keys(getters.feEasygivingButtonSettings).length > 0;
  },
};

const actions = {
  themeStyle({ commit }) {
    commit("setThemeStyle");
  },
  updateWidgetSettingToOriginalState({ commit }) {
    commit("updateWidgetSettingToOriginalState");
  },
};

const mutations = {
  setThemeStyle(state) {
    const theme =
      (state.organization &&
        state.organization.widgetSettings &&
        state.organization.widgetSettings.theme) ||
      {};

    setThemeColors("--theme-style-", theme);
  },
  setPaymentProcessor(state, paymentProcessor) {
    state.paymentProcessor = paymentProcessor;
  },
  setOrganizationWidgetSettings(state, payload) {
    state.organization.widgetSettings = Object.assign(
      state.organization.widgetSettings,
      cloneDeep(payload)
    );
    state.organization.loadingSettings = true;
  },
  setWidgetSettingToOriginalState(state, payload) {
    state.organization.widgetSettingsOrigin = cloneDeep(payload);
  },
  setMemberSettings(state, payload) {
    state.organization.memberSettings = Object.assign(
      state.organization.memberSettings,
      payload
    );
  },
  setOrganizationButtonSettings(state, payload) {
    state.organization.buttonSettings = Object.assign(
      state.organization.buttonSettings,
      payload
    );
    state.organization.buttonSettings.loadingButtonSettings = true;
  },
  setStatusMountedButton(state, payload) {
    state.organization.buttonSettings.mountedButton = payload;
  },
  setGivingAmount(state, payload) {
    let givingAmount =
      state.organization.widgetSettings?.stepsInfo?.chooseAmount?.givingAmount;
    if (givingAmount) {
      state.organization.widgetSettings.stepsInfo.chooseAmount.givingAmount =
        payload;
    }
  },
  setAllowCustomAmounts(state, payload) {
    state.organization.widgetSettings.stepsInfo.chooseAmount.allowCustomAmounts =
      Boolean(payload);
  },
  setAllowAnonymousDonors(state, payload) {
    state.organization.widgetSettings.stepsInfo.basicDetails.allowAnonymousDonors =
      Boolean(payload);
  },
  setAllowCoverFee(state, payload) {
    state.organization.widgetSettings.stepsInfo.paymentDetails.allowCoverFee =
      Boolean(payload);
  },
  setMinAmountRule(state, payload) {
    state.organization.widgetSettings.properties.chooseAmount.givingAmount.rules.minAmount =
      payload;
  },
  setCollectPhone(state, payload) {
    state.organization.widgetSettings.properties.basicDetails.phone.rules.required =
      payload;
  },
  setCollectEmail(state, payload) {
    state.organization.widgetSettings.properties.basicDetails.email.rules.required =
      payload;
  },
  setCollectAddress(state, payload) {
    state.organization.widgetSettings.properties.basicDetails.address.rules.required =
      payload;

    state.organization.widgetSettings.properties.basicDetails.city.rules.required =
      payload;

    state.organization.widgetSettings.properties.basicDetails.addressState.rules.required =
      payload;

    state.organization.widgetSettings.properties.basicDetails.country.rules.required =
      payload;

    state.organization.widgetSettings.properties.basicDetails.postalCode.rules.required =
      payload;
  },
  setTitle(state, payload) {
    let title =
      state.organization.widgetSettings?.stepsInfo?.chooseAmount?.title;
    if (title) {
      state.organization.widgetSettings.stepsInfo.chooseAmount.title = payload;
    }
  },
  setDescription(state, payload) {
    let description =
      state.organization.widgetSettings?.stepsInfo?.chooseAmount?.description;
    if (description) {
      state.organization.widgetSettings.stepsInfo.chooseAmount.description =
        payload;
    }
  },
  setAmount(state, payload) {
    let amount =
      state.organization.widgetSettings?.stepsInfo?.chooseAmount
        ?.givingAmountDefault;
    if (amount) {
      state.organization.widgetSettings.stepsInfo.chooseAmount.givingAmountDefault =
        payload;
    }
  },
  setWidgetImage(state, payload) {
    if (payload) {
      state.organization.widgetSettings.widgetImage = payload;
    }
  },
  updateWidgetSettingToOriginalState(state) {
    state.organization.widgetSettings = cloneDeep(
      state.organization.widgetSettingsOrigin
    );
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: moduleGetters,
  actions,
  mutations,
};
