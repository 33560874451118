<template>
  <FieldGeneral
    ref="field"
    type="tel"
    :value="date"
    label="MM/YYYY"
    :is-required="true"
    placeholder="MM/YYYY"
    name="expirationDate"
    :is-invalid="isInvalidCcExp"
    :custom-keypress-handler="cardDateKeypressHandler"
    :max-length="7"
    @update="setModel"
  />
</template>

<script>
import { cardDateValidator } from "@/global/util/validators";
import {
  cardDateKeypressHandler,
  countCharacters,
} from "@/global/util/keypressHandlers";

export default {
  name: "FieldCardDate",
  emits: ["update"],

  props: {
    name: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "MM/YY",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInvalidCcExp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // date: "",
      cardDateValidator,
      cardDateKeypressHandler,
    };
  },
  computed: {
    date2: {
      get() {
        this.$nextTick(() => {
          if (this.selection) {
            const input = this.getInput();
            let positionModificator = 0;
            /* NOTE: Modify the caret's position if current action changed
                     the 2nd character in the input. */
            if (input.value.substr(0, this.selection[0]).length === 2) {
              positionModificator += 1;
            }
            const modificator =
              countCharacters("/", input.value.substr(0, this.selection[0])) -
              this.beforeSelection +
              positionModificator;
            input.selectionStart = this.selection[0] + modificator;
            input.selectionEnd = this.selection[1] + modificator;
          }
        });

        let formattedValue = this.value;

        if (formattedValue.length > 1) {
          formattedValue = `${formattedValue.substr(
            0,
            2
          )}/${formattedValue.substr(2)}`;
        }

        return formattedValue;
      },
      set(newValue) {
        let processedValue = newValue.replace(/\//g, "");

        processedValue = processedValue.substr(0, 6);
        this.$emit("update", {
          field: "expirationDate",
          value: processedValue,
        });

        const input = this.getInput();

        if (input.selectionStart === input.selectionEnd) {
          this.selection = [input.selectionStart, input.selectionEnd];
          this.beforeSelection = countCharacters(
            "/",
            newValue.substr(0, input.selectionStart)
          );
        } else {
          this.selection = null;
        }
      },
    },

    date: {
      get() {
        this.$nextTick(() => {
          if (this.selection) {
            const input = this.getInput();

            let positionModificator = 0;
            if (input.value.substr(0, this.selection[0]).length === 2) {
              positionModificator += 1;
            }

            const modificator =
              countCharacters("/", input.value.substr(0, this.selection[0])) -
              this.beforeSelection +
              positionModificator;

            input.selectionStart = this.selection[0] + modificator;

            input.selectionEnd = this.selection[1] + modificator;
          }
        });

        let formattedValue = this.value;

        if (formattedValue.length > 1) {
          formattedValue = `${formattedValue.substr(
            0,
            2
          )}/${formattedValue.substr(2)}`;
        }

        return formattedValue;
      },
      set(newValue) {
        let processedValue = newValue.replace(/\//g, "");
        processedValue = processedValue.substr(0, 6);

        this.$emit("update", {
          field: "expirationDate",
          value: processedValue,
        });

        const input = this.getInput();
        if (input.selectionStart === input.selectionEnd) {
          this.selection = [input.selectionStart, input.selectionEnd];
          this.beforeSelection = countCharacters(
            "/",
            newValue.substr(0, input.selectionStart)
          );
        } else {
          this.selection = null;
        }
      },
    },
  },
  methods: {
    updateCard() {},
    setModel(date) {
      this.date = date.value;
    },
    getInput() {
      return this.$refs.field.$refs.input;
    },
  },
};
</script>
