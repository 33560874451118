<template>
  <svg
    width="120"
    height="121"
    viewBox="0 0 120 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60 120.141C44.087 120.141 28.8258 113.819 17.5736 102.567C6.32141 91.3149 0 76.0536 0 60.1406C0 44.2276 6.32141 28.9664 17.5736 17.7142C28.8258 6.46204 44.087 0.140625 60 0.140625C75.913 0.140625 91.1742 6.46204 102.426 17.7142C113.679 28.9664 120 44.2276 120 60.1406C120 76.0536 113.679 91.3149 102.426 102.567C91.1742 113.819 75.913 120.141 60 120.141V120.141ZM60 108.141C72.7304 108.141 84.9394 103.084 93.9411 94.0818C102.943 85.08 108 72.871 108 60.1406C108 47.4102 102.943 35.2013 93.9411 26.1995C84.9394 17.1978 72.7304 12.1406 60 12.1406C47.2696 12.1406 35.0606 17.1978 26.0589 26.1995C17.0571 35.2013 12 47.4102 12 60.1406C12 72.871 17.0571 85.08 26.0589 94.0818C35.0606 103.084 47.2696 108.141 60 108.141V108.141ZM46.2 55.9406L54 63.6806L73.8 43.8806C74.9547 42.9052 76.435 42.4016 77.9449 42.4704C79.4549 42.5392 80.8832 43.1754 81.9444 44.2518C83.0056 45.3281 83.6215 46.7653 83.6689 48.2761C83.7163 49.7869 83.1917 51.2599 82.2 52.4006L58.2 76.4006C57.0784 77.5 55.5705 78.1158 54 78.1158C52.4295 78.1158 50.9216 77.5 49.8 76.4006L37.8 64.4006C36.8083 63.2599 36.2838 61.7869 36.3311 60.2761C36.3785 58.7653 36.9944 57.3281 38.0556 56.2518C39.1168 55.1754 40.5451 54.5392 42.0551 54.4704C43.565 54.4016 45.0453 54.9052 46.2 55.8806V55.9406Z"
      fill="#D1D5DB"
    />
  </svg>
</template>

<script>
export default {
  name: "IconThankYou",
};
</script>
