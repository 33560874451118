<template>
  <InactivityModal
    v-if="showModalStillHere"
    @close="userStillHere"
    :modalContainerPositionCss="modalContainerPositionCss"
    :modalRootCss="modalRootCss"
  >
    <template #header>
      <h3>Still Here?</h3>
    </template>
    <template #body>
      <p>Your session will timeout in:.</p>
      <span>{{ secondsCounter }} Seconds</span>
    </template>
    <template #button>
      <div>I'm Still Here</div>
    </template>
  </InactivityModal>

  <InactivityModal
    v-if="showModalSessionTimedOut"
    @close="startOver"
    :modalContainerPositionCss="modalContainerPositionCss"
    :modalRootCss="modalRootCss"
  >
    <template #header>
      <h3>Session Timed Out</h3>
    </template>
    <template #body>
      <p>Your session was left unattended for too long.</p>
      <p>You can either start over or close out of it.</p>
    </template>
    <template #button>
      <div>Start Over</div>
    </template>
  </InactivityModal>
</template>

<script>
import { mapState } from "vuex";
import * as constants from "@/config/constants";
import inactivity from "@/global/mixins/UI/modals/inactivity.vue";
import IdleTimer from "@/global/util/userInteraction/IdleTimer";
import storeMixin from "@/global/mixins/store/store.js";

export default {
  name: "TimeOutModal",
  mixins: [storeMixin],
  components: { InactivityModal: inactivity },
  props: {
    type: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: "",
    },
    sessionActions: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    secondsCounter: 0,
    trackSecondsCounter: 0,
    modalContainerPositionCss: "",

    showModalStillHere: false,
    userStillHereClick: false,
    showModalSessionTimedOut: false,
    additionalStyles: {
      modal: {
        outerBlock: {
          default: "top: 65px !important;",
          littleScreen: "top: 54px !important;",
        },
      },
      embedded: {
        outerBlock: {
          default: "top: 65px !important;",
          littleScreen: "top: 54px !important;",
        },
      },
      kiosk: {
        outerBlock: {
          default: "",
          littleScreen: "",
        },
      },
    },

    idleTimerStore: {},
    intervalIdleTimerIds: new Set(),
  }),
  computed: {
    ...mapState("screen", { isLittleScreen: "bpLittle" }),
    isLittleWidget() {
      return this.isLittleScreen;
    },
    pageTimesOut() {
      return constants.userInteraction[this.type].pageTimesOut;
    },
    expiredAfter() {
      return this.pageTimesOut.stillHereModal.inactive;
    },
    timerDuration() {
      return this.pageTimesOut.stillHereModal.timerDuration;
    },
    modalRootCss() {
      if (!this.isLittleWidget) {
        return this.additionalStyles[this.type].outerBlock.littleScreen;
      } else {
        return this.additionalStyles[this.type].outerBlock.default;
      }
    },
  },

  watch: {
    showModalStillHere(value) {
      this.$store.dispatch("eventEmitter/timeOutModals", {
        payload: {
          stillHereModal: {
            show: value,
          },
        },
      });

      if (value) {
        this.userStillHereClick = false;

        this.timerModalStillHere = new IdleTimer({
          timeout: this.timerDuration,
          rootInnerDocumentClass:
            constants.userInteraction[this.type].rootInnerDocumentClass,
          onTimeout: () => {
            if (!this.userStillHereClick) {
              this.showModalStillHere = false;
              this.showModalSessionTimedOut = true;
            } else {
              this.showModalStillHere = true;
            }
          },
          back: (id) => {
            this.intervalIdleTimerIds.add(id);
          },
        });

        this.idleTimerStore = Object.assign(this.idleTimerStore, {
          [this.timerDuration]: this.timerModalStillHere,
        });
      } else {
        this.timerModalStillHere = new IdleTimer({
          timeout: this.expiredAfter,
          rootInnerDocumentClass:
            constants.userInteraction[this.type].rootInnerDocumentClass,
          onTimeout: () => {},
          back: (id) => {
            this.intervalIdleTimerIds.add(id);
          },
        });

        this.idleTimerStore = Object.assign(this.idleTimerStore, {
          [this.expiredAfter]: this.timerModalStillHere,
        });
      }
    },
    showModalSessionTimedOut(value) {
      if (value) {
        this.cleanUpInstanceIdleTimer();
        this.resetStates();
        this.$router.push({ name: "start" });
        clearTimeout(this.trackSecondsCounter);
      }

      this.$store.dispatch("eventEmitter/timeOutModals", {
        payload: {
          sessionTimedOutModal: {
            show: value,
          },
        },
      });
    },
  },
  mounted() {
    this.runTimeOutModal();
  },

  unmounted() {
    for (var key in this.idleTimerStore) {
      try {
        this.idleTimerStore[key].stop();
      } catch (error) {
        console.error("IdleTimer::stop: ", error);
      }
    }

    this.clearTimeoutIntervalIdleTimer();
  },
  methods: {
    runTimeOutModal() {
      this.timer = new IdleTimer({
        timeout: this.expiredAfter,
        rootInnerDocumentClass:
          constants.userInteraction[this.type].rootInnerDocumentClass,
        onTimeout: () => {
          this.showModalStillHere = true;
        },
        back: (id) => {
          this.intervalIdleTimerIds.add(id);
        },
      });

      this.idleTimerStore = Object.assign(this.idleTimerStore, {
        [this.expiredAfter]: this.timer,
      });

      this.timerStart();
      this.setPositionModal();
    },
    clearTimeoutIntervalIdleTimer() {
      this.intervalIdleTimerIds.forEach(function (item) {
        clearInterval(item);
      });
    },
    timerStart() {
      var timeLeft = 9999999;

      this.trackSecondsCounter = setInterval(() => {
        if (timeLeft == -1) {
          clearTimeout(this.trackSecondsCounter);
        } else {
          if (this.timerModalStillHere) {
            this.secondsCounter = this.timerModalStillHere.secondsDiff;
          }

          timeLeft--;
        }
      }, 100);
    },

    setPositionModal() {
      if (this.position == "center") {
        this.modalContainerPositionCss =
          "margin: 136px auto auto !important; border-radius: 10px !important;";
      }

      if (this.position == "up") {
        this.modalContainerPositionCss =
          "margin: 0 auto auto !important; border-radius: 0px 0px 10px 10px !important;";
      }
    },

    resetModalsSettings() {
      this.showModalStillHere = false;
      this.showModalSessionTimedOut = false;
    },

    userStillHere() {
      this.showModalStillHere = false;
      this.userStillHereClick = true;
    },

    resetStates() {
      this.initStoreAction("resetState")
        .state("donation")
        .state("notifications")
        .state("eventEmitter")
        .state("intermediateDataCollect")
        .state("flag");
      this.$store.dispatch("config/initUniqueSessionsData");
    },

    //IdleTimer class
    cleanUpInstanceIdleTimer() {
      this.timer instanceof IdleTimer && this.timer.cleanUp();
      this.timerModalStillHere instanceof IdleTimer &&
        this.timerModalStillHere.cleanUp();
    },

    goToStartPage() {
      setTimeout(() => {
        this.$router.push({ name: "start" });
        this.runTimeOutModal();
      }, 300);
    },

    startOver() {
      this.cleanUpInstanceIdleTimer();

      if (this.sessionActions == "reload") {
        window.location.reload();
      }

      if (this.sessionActions == "startOver") {
        this.resetModalsSettings();
        this.resetStates();
        this.goToStartPage();
      }
    },
  },
};
</script>
