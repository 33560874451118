"use strict";

export const modal = "modal_b";
export const kiosk = "kiosk";
export const embedded = "embedded";
export const button = "button";

export const WIDGET_ORIGIN_TYPE_NAME = {
  [modal]: 'modal',
  [kiosk]: 'kiosk',
  [embedded]: 'embedded',
  [button]: 'button',
}

export const DEFAULT_NAME = [modal, kiosk, embedded, button];
export const WIDGET_NAME = "fe-easygiving-";
export const userInteraction = {
  modal: {
    rootInnerDocumentClass: "interaction.modal.DWfsfQD",
    pageTimesOut: {
      stillHereModal: {
        inactive: 60, //seconds 60
        timerDuration: 30, //seconds 30
      },
    },
  },
  embedded: {
    rootInnerDocumentClass: "interaction.embedded.GDDvpas",
    pageTimesOut: {
      stillHereModal: {
        inactive: 60, //seconds 60
        timerDuration: 30, //seconds 30
      },
    },
  },
  kiosk: {
    rootInnerDocumentClass: "interaction.kiosk.gfeQDSDS",
    pageTimesOut: {
      stillHereModal: {
        inactive: 60, //seconds 60
        timerDuration: 30, //seconds 30
      },
    },
  },
};
