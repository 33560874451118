// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "input[data-v-29f63a85]{height:auto!important}.FieldGeneral_c-fields-container__validation-hint_ALxXH[data-v-29f63a85]{display:block!important;line-height:12px!important;font-weight:500!important;font-size:10px!important;color:#d03f2d!important}.FieldGeneral_error_pzrGQ label[data-v-29f63a85]{color:#dc2626!important}.FieldGeneral_label_sT47k[data-v-29f63a85]{display:block!important;line-height:12px!important;font-weight:500!important;font-size:10px!important;color:#6b7280!important;margin-bottom:9px!important;text-align:left!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"c-fields-container__validation-hint": "FieldGeneral_c-fields-container__validation-hint_ALxXH",
	"error": "FieldGeneral_error_pzrGQ",
	"label": "FieldGeneral_label_sT47k"
};
export default ___CSS_LOADER_EXPORT___;
