<template>
  <div
    :class="[
      {
        [$style['error']]: isInvalidInput,
      },
    ]"
  >
    <label :for="name" :class="$style['label']"
      >{{ label }} {{ optional }}</label
    >
    <div padding v-if="!selectedOption && !optionSkipped">
      <input
        type="text"
        v-focus="isFocused"
        :placeholder="placeholder"
        autocomplete="off"
        v-model="query"
        @keydown.down="down"
        @keydown.up="up"
        @keydown.enter="hit"
        @keydown.esc="reset"
        @input="update"
        :maxlength="maxLength"
        @blur="blurField"
        @click="updateAndShowList"
      />

      <div :class="$style['spinner']">
        <VSpinner :size="18" :ring-size="2" v-if="loadingCollection" />
      </div>

      <div
        v-if="isInvalidInput"
        :class="$style['c-fields-container__validation-hint']"
      >
        {{ validationHint }}
      </div>

      <div>
        <ul
          :class="$style['autocomplete-results']"
          ref="scrollContainer"
          :style="{ height: searchResultsHeight }"
          v-if="hasItems"
        >
          <li
            v-for="(item, $item) in items"
            :key="item.id"
            :class="[activeClass($item), $style['autocomplete-result']]"
            @mousedown="hit"
            @mousemove="setActive($item)"
            ref="options"
            @click="setResult($item)"
          >
            <div :class="$style['autocomplete-results-description']">
              <span
                v-if="item?.emoji"
                :class="$style['autocomplete-results-emoji']"
                v-text="item.emoji"
              ></span>
              <span
                v-if="item?.name"
                :class="$style['autocomplete-results-name']"
                v-text="item.name"
              ></span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div v-else @click="hideOption">
      <div @click="clickSelectedSpan">
        <span
          :class="$style['autocomplete-results-name-selected']"
          v-text="completedData.name"
        ></span>
        <i
          :class="$style['autocomplete-close-icon']"
          @click="removeOption"
          v-if="selectedOption"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5307 1.53022L10.4707 0.470215L6.0007 4.94022L1.5307 0.470215L0.470703 1.53022L4.9407 6.00021L0.470703 10.4702L1.5307 11.5302L6.0007 7.06021L10.4707 11.5302L11.5307 10.4702L7.0607 6.00021L11.5307 1.53022Z"
              fill="#838383"
            />
          </svg>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Typeahead from "@/global/util/typeahead";
import ValidationMixin from "@/global/mixins/validation";
const _ = require("lodash");
const searchResultsHeight = {
  desktop: {
    country: "145px",
    state: "210px",
    city: "270px",
  },
  standard: {
    country: "160px",
    state: "220px",
    city: "280px",
  },
};

export default {
  emits: ["change", "selectedOption"],
  mixins: [ValidationMixin],
  extends: Typeahead,
  name: "AutoComplete",
  props: {
    name: {
      type: String,
      default: "",
    },
    maxLength: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    collectionDb: {
      type: String,
      default: "",
    },
    loadingCollection: {
      type: Boolean,
      default: false,
    },
    optionsKey: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    default: {
      type: Object,
      default() {
        return {};
      },
    },
    clearCollectionState: {
      type: Object,
      default() {
        return {};
      },
    },
    setPreEnteredData: {
      type: Object,
      default() {
        return {};
      },
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
      selectedOption: false,
      optionSkipped: false,
      canSkipOption: false,
      focus: false,
      completedData: {},

      data: {},
      limit: 0,
      minChars: 1,
      selectFirst: false,
    };
  },
  watch: {
    clearCollectionState: {
      handler(data) {
        if (
          !_.isEmpty(data) &&
          !_.isEmpty(this.completedData) &&
          data.value == true &&
          data.field == "state"
        ) {
          this.removeOption();
          this.clearCollectionDb("states");
        }
      },
      deep: true,
    },
    setPreEnteredData: {
      handler(data) {
        if (!data.id && !data.name && !data.abbrev) {
          this.removeOption();
        } else {
          this.completedData = data;
          this.onHit(this.completedData, false);
        }
      },
      deep: true,
    },
    query(value = "") {
      if (!value) {
        this.completedData = {};
      }
    },
    isInvalid(value) {
      if (value) {
        this.$nextTick(() => {
          this.validate({
            name: this.name,
            value: this.query,
          });
        });
      }
    },
    completedData: {
      handler(data) {
        if (_.isEmpty(data)) {
          this.$emit("change", {
            field: this.name,
            id: "",
            name: "",
            abbrev: "",
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.query == "" && !_.isEmpty(this.default)) {
      this.completedData = this.default;
      this.onHit(this.completedData);
    }
  },
  computed: {
    ...mapState("screen", { isDesktop: "bpMedium" }),
    isDesktopWidget() {
      return this.isDesktop;
    },
    searchResultsHeight() {
      if (this.isDesktopWidget) {
        return searchResultsHeight.desktop[this.name];
      }
      return searchResultsHeight.standard[this.name];
    },
    optional() {
      let optional = "";
      if (!this.isRequired) {
        optional = "(optional)";
      }
      return optional;
    },
  },
  methods: {
    async updateAndShowList() {
      if (!this.query) {
        await this.showAllCollection();
        if (this.current == -1) {
          this.setActive(0);
        }
      }
      this.isFocused = true;
    },
    clickSelectedSpan() {
      this.isFocused = true;
    },
    skipItems() {
      if (_.isEmpty(this.completedData)) {
        this.selectedOption = false;
        this.optionSkipped = false;
        this.items = [];
      }
    },
    setSelectedQuery() {
      if (this.query) {
        this.query = this.completedData.name;
      }
    },
    blurField() {
      if (!_.isEmpty(this.completedData)) {
        this.selectedOption = true;
      }
      this.skipItems();
      this.setSelectedQuery();
      if (this.isFocused) {
        this.$nextTick(() => {
          this.validate({
            name: this.name,
            value: this.query,
          });
        });
      }
      this.isFocused = false;
    },

    onHit(item, emit = true) {
      this.selectedOption = true;
      this.completedData = item;

      this.query = item.name;

      if (emit) {
        this.$emit("change", {
          field: this.name,
          id: item.id,
          name: item.name,
          abbrev: item.abbrev,
        });
        this.$emit("selectedOption", {
          checked: this.name,
        });
      }
    },
    removeOption() {
      this.hideOption();
      this.query = "";
      this.completedData = {};
      this.items = [];
      this.$emit("selectedOption", {
        checked: this.name,
      });
    },
    hideOption() {
      this.selectedOption = false;
      this.focus = true;
    },
  },
};
</script>

<style scoped>
.activeSDefsdW {
  background-color: #d1d2d3;
}
.activeSDefsdW span {
  color: white;
}
</style>

<style module scoped>
.autocomplete-results {
  padding: 0 !important;
  margin: 0 !important;
  border: 1px solid #eeeeee !important;
  overflow: auto !important;
  border: 1px solid #cacaca !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 21%) !important;
  border-radius: 1px !important;
  position: absolute !important;
  width: 100% !important;
  top: 100% !important;
  left: 0 !important;
  z-index: 9999999 !important;
  background: #fff !important;
  overflow: auto !important;
}

.autocomplete-result {
  list-style: none !important;
  text-align: left !important;
  padding: 4px 2px !important;
  cursor: pointer !important;
}

.autocomplete-close-icon {
  cursor: pointer !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%);
  right: 12px !important;
}

.autocomplete-results-name {
  display: unset !important;
  color: #333333 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}
.autocomplete-results-name-selected {
  display: unset !important;
  color: #333333 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.autocomplete-results-description {
  font-size: 14px !important;
  display: block !important;
  line-height: 17px !important;
}
.autocomplete-results-emoji {
  padding-left: 0 !important;
  line-height: 17px !important;
  margin-right: 10px !important;
}
.spinner {
  cursor: pointer !important;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%);
  right: 12px !important;
}
input {
  height: auto !important;
}
.c-fields-container__validation-hint {
  display: block !important;
  line-height: 12px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color: #d03f2d !important;
}

.error label {
  color: #dc2626 !important;
}
.label {
  display: block !important;
  line-height: 12px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color: #6b7280 !important;
  margin-bottom: 9px !important;
  text-align: left !important;
}
</style>
