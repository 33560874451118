<template>
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9341 8.48878H0.900186C0.661442 8.48878 0.432476 8.39001 0.263658 8.21419C0.0948408 8.03838 0 7.79992 0 7.55128C0 7.30264 0.0948408 7.06418 0.263658 6.88837C0.432476 6.71255 0.661442 6.61378 0.900186 6.61378H14.9341L10.1631 1.64503C10.0695 1.56028 9.99371 1.45629 9.94051 1.33959C9.88731 1.22289 9.85784 1.09601 9.85395 0.966887C9.85006 0.837769 9.87184 0.709206 9.91791 0.589266C9.96399 0.469327 10.0334 0.360598 10.1217 0.269903C10.21 0.179207 10.3154 0.108502 10.4312 0.0622234C10.547 0.0159448 10.6708 -0.00490853 10.7947 0.000971836C10.9186 0.0068522 11.04 0.0393392 11.1513 0.0963948C11.2626 0.15345 11.3613 0.233843 11.4414 0.332529L17.7427 6.89503C17.9076 7.07027 18 7.30589 18 7.55128C18 7.79667 17.9076 8.03228 17.7427 8.20753L11.4414 14.77C11.2702 14.925 11.0492 15.0069 10.8226 14.9995C10.5959 14.9921 10.3803 14.8959 10.2188 14.7301C10.0573 14.5643 9.96185 14.3411 9.95153 14.1052C9.94121 13.8692 10.0168 13.6379 10.1631 13.4575L14.9341 8.48878Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconForwardArrow",
};
</script>
