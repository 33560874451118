// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VNotifications_widget-alert_Fr5B1[data-v-5b1686fa]{background-color:#fee2e2!important;color:#fff!important;transition:height .2s!important;overflow:hidden!important;padding:0 30px!important;border-radius:5px!important;margin-bottom:22px!important;margin-top:-20px!important}.VNotifications_widget-alert--success_M8VBI[data-v-5b1686fa]{background-color:green!important}.VNotifications_widget-alert--error_jmqtH[data-v-5b1686fa]{background-color:#fee2e2!important}.VNotifications_widget-alert-content_rbpAo[data-v-5b1686fa]{padding:7px 20px!important}.VNotifications_widget-alert-content_rbpAo[data-v-5b1686fa],.VNotifications_widget-alert-content_rbpAo svg[data-v-5b1686fa]{display:flex!important;align-items:center!important}.VNotifications_widget-alert-content_rbpAo svg[data-v-5b1686fa]{flex-shrink:0!important;margin-right:7px!important}.VNotifications_widget-alert-content_rbpAo span[data-v-5b1686fa]{font-weight:600!important;font-size:14px!important;line-height:17px!important}.VNotifications_widget-alert-side_j7PZD[data-v-5b1686fa]{flex:0 0 auto!important;width:20px!important;position:relative!important;margin:0 10px 0 0!important;margin-right:7px!important}.VNotifications_widget-alert-side--right_bLvUF[data-v-5b1686fa]{text-align:right!important}.VNotifications_widget-alert-center_sgSrZ[data-v-5b1686fa]{flex-grow:1!important;padding:0 2px!important;font-size:1.4rem!important;line-height:1.3!important;color:#7f1d1d!important;display:flex;align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget-alert": "VNotifications_widget-alert_Fr5B1",
	"widget-alert--success": "VNotifications_widget-alert--success_M8VBI",
	"widget-alert--error": "VNotifications_widget-alert--error_jmqtH",
	"widget-alert-content": "VNotifications_widget-alert-content_rbpAo",
	"widget-alert-side": "VNotifications_widget-alert-side_j7PZD",
	"widget-alert-side--right": "VNotifications_widget-alert-side--right_bLvUF",
	"widget-alert-center": "VNotifications_widget-alert-center_sgSrZ"
};
export default ___CSS_LOADER_EXPORT___;
