class IdleTimer {
  constructor({
    timeout,
    rootInnerDocumentClass,
    onTimeout,
    noActivityConfirmation,
    back,
  }) {
    this.cleanUp();

    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.noActivityConfirmation = noActivityConfirmation;
    this.secondsDiff = this.timeout;
    this.rootInnerDocumentClass = rootInnerDocumentClass;
    this.back = back;

    // const expiredTime = parseInt(
    //   localStorage.getItem(`_expiredTime.${this.rootInnerDocumentClass}`) || 0,
    //   10
    // );
    // if (expiredTime > 0 && expiredTime < Date.now()) {
    //   onExpired();
    //   return;
    // }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.startInterval();
    this.tracker();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem(`_expiredTime.${this.rootInnerDocumentClass}`) ||
          0,
        10
      );

      if (expiredTime != 0 && expiredTime < Date.now() && this.onTimeout) {
        this.stop();
      }

      this.secondsDiff = this.getSecondsDiff(expiredTime, Date.now());
      if (this.secondsDiff > this.timeout) {
        this.updateExpiredTime();
      }

      this.back && this.back(this.interval);
    }, 1000);
  }

  stop() {
    this.onTimeout && this.onTimeout();
    this.cleanUp();

    return true;
  }

  getSecondsDiff(startDate, endDate) {
    const msInSecond = 1000;
    return Math.round(Math.abs(endDate - startDate) / msInSecond);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }

    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem(
        `_expiredTime.${this.rootInnerDocumentClass}`,
        Date.now() + this.timeout * 1000
      );
    }, 300);
  }

  tracker() {
    if (window.addEventListener) {
      this.init();
    }
  }

  init() {
    let rootInner = getElementsByClassName(this.rootInnerDocumentClass);

    if (rootInner && !!rootInner.length) {
      for (var i = 0; i < rootInner.length; i++) {
        rootInner[i].addEventListener("click", this.eventHandler);
        rootInner[i].addEventListener("mouseover", this.eventHandler);
        rootInner[i].addEventListener("scroll", this.eventHandler);
        rootInner[i].addEventListener("keydown", this.eventHandler);
      }
    }
  }

  cleanUp() {
    let rootInner = getElementsByClassName(this.rootInnerDocumentClass);
    localStorage.removeItem(`_expiredTime.${this.rootInnerDocumentClass}`);
    clearInterval(this.interval);

    if (rootInner && !!rootInner.length) {
      for (var i = 0; i < rootInner.length; i++) {
        if (window.addEventListener) {
          rootInner[i].removeEventListener("click", this.eventHandler);
          rootInner[i].removeEventListener("mousemove", this.eventHandler);
          rootInner[i].removeEventListener("scroll", this.eventHandler);
          rootInner[i].removeEventListener("keydown", this.eventHandler);
        }
      }
    }
  }
}

export default IdleTimer;

function cache(key, value) {
  if (typeof value == "undefined") {
    return cache[key];
  }
  cache[key] = value;
}

function getElementsByClassName(className = "") {
  if (!cache(className)) {
    cache(className, document.getElementsByClassName(className));
  }
  return cache(className);
}
