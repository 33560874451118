import FieldCheckbox from "@/global/components/commons/form/FieldCheckbox";
import FieldAmount from "@/global/components/commons/form/FieldAmount";
import FieldGeneral from "@/global/components/commons/form/FieldGeneral";
import AutoComplete from "@/global/components/commons/form/AutoComplete";
import CardFields from "@/global/components/commons/form/CardFields";
import FieldCardDate from "@/global/components/commons/form/FieldCardDate";

import FieldMobileNumber from "@/global/components/commons/form/FieldMobileNumber";
import FieldCardNumber from "@/global/components/commons/form/FieldCardNumber";
export default [
  FieldCheckbox,
  FieldAmount,
  FieldGeneral,
  FieldMobileNumber,
  AutoComplete,
  CardFields,
  FieldCardNumber,
  FieldCardDate,
];
