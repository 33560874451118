<template>
  <KDonationView
    :title="titleStepName"
    :step="step"
    :show-card-heading-slot="false"
    :current-step-name="currentStepName"
  >
    <VSpinnerOverlay
      :is-visible="isLoading"
      class="c-the-start__spinner-overlay"
      style="background-color: #f5f5f5; z-index: 5"
    />
    <template v-slot:card_body_block_heading_2>
      <h6 :class="$style['widget-title']">Donation Summary</h6>
      <p>Please confirm that your information and total amount are correct.</p>
      <div :class="$style['widget-summary']">
        <div :class="$style['widget-summary__header']">
          <div :class="$style['widget-summary__row']">
            <span>Name</span>
            <span>{{ name }}</span>
          </div>
        </div>
        <div :class="$style['widget-summary__body']">
          <div :class="$style['widget-summary__row']">
            <span>Country</span>
            <span>{{ countryBilling }}</span>
          </div>
          <!-- <div :class="$style['widget-summary__row']">
            <span>Address</span>
            <span>{{ address }}</span>
          </div>
          <div :class="$style['widget-summary__row']">
            <span>State/Province</span>
            <span>{{ stateProvince }}</span>
          </div>
          <div :class="$style['widget-summary__row']">
            <span>City</span>
            <span>{{ city }}</span>
          </div> -->
          <div :class="$style['widget-summary__row']">
            <span>Postal Code</span>
            <span>{{ postalCodeBilling }}</span>
          </div>
          <div :class="$style['widget-summary__row']" v-if="isMethodCard">
            <span>Credit Card</span>
            <span>{{ showLast4DigitsCards }}</span>
          </div>
        </div>
        <div :class="$style['widget-summary__subtotal']">
          <div :class="$style['widget-summary__row']">
            <span>Donation</span>
            <span>${{ donation }}</span>
          </div>
          <div :class="$style['widget-summary__row']" v-if="coverFee">
            <span>
              Fees
              <span :class="$style['widget-helper']">
                <span :class="$style['widget-helper__icon']">
                  <IconHelper />
                </span>
              </span>
            </span>
            <span>${{ fees }}</span>
          </div>
        </div>
        <div :class="$style['widget-summary__total']">
          <div :class="$style['widget-summary__row']">
            <span>Total Amount</span>
            <span>${{ totalAmount }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer-buttons>
      <VButtonBack
        label="Go Back"
        :is-disabled="isDisabledContinue"
        @click.prevent="back"
      />
      <VButtonNext
        label="Continue"
        :is-disabled="isDisabledContinue"
        @click="next"
      />
    </template>
  </KDonationView>
</template>

<script>
import summary from "@/global/mixins/page/summary.js";

export default {
  mixins: [summary],

  data: () => ({
    presetName: "kiosk",
  }),
};
</script>

<style lang="scss" src="@/global/assets/css/page/summary.scss"></style>
<style module scoped src="@/global/assets/css/page/module/summary.css"></style>
