import validationMixin from "@/global/mixins/validationRules.js";
import { isEmpty } from "lodash";

export default {
  mixins: [validationMixin],
  data() {
    return {
      isInvalidInput: false,
      validationHint: null,
    };
  },
  watch: {
    isInvalid(val) {
      this.isInvalidInput = val;
    },
  },

  computed: {},

  methods: {
    validate(field) {
      this.isInvalidInput = false;

      this.setValidationType({ type: "fieldError" });

      const validateField = this.validateField(field.name, field.value, {
        required: this.isRequired,
      });

      if (!isEmpty(validateField)) {
        this.isInvalidInput = true;
        this.validationHint = validateField[0].message;
      }
    },
  },
};
