<template>
  <router-view v-if="hasWidgetSettings" />
</template>

<script>
import { mapGetters } from "vuex";
import organizationMixin from "@/global/mixins/organization.js";

export default {
  name: "App",
  mixins: [organizationMixin],
  computed: {
    ...mapGetters("organization", ["hasWidgetSettings"]),
  },
};
</script>

<style scoped lang="scss"></style>
