<template>
  <button
    class="card-button_URWNDw"
    :disabled="isDisabled || opacity"
    :class="[
      {
        opacity: opacity,
      },
    ]"
  >
    <VSpinner
      :size="spinnerSize"
      :ring-size="spinnerRingSize"
      v-if="isDisabled"
    />
    <span v-else>{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "VButtonNext",
  components: {},
  props: {
    label: {
      type: String,
      default: "",
    },
    spinnerSize: {
      type: Number,
      default: null,
    },
    spinnerRingSize: {
      type: Number,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scope>
.ButtonView_widget-card__footer-buttons_FrgASq .card-button_URWNDw {
  flex: 1 !important;
}
.ButtonView_widget-card__footer-buttons_vFDfeq .card-button_URWNDw {
  flex: 1 !important;
}
.card-button_URWNDw {
  cursor: pointer !important;
  font-size: 16px !important;
  text-decoration: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  color: #ffffff !important;
  border: 1px solid transparent;
  background: var(--theme-style-mainColor) !important;
  border-radius: 5px !important;
  height: 60px !important;
}
.card-button_URWNDw svg {
  margin-right: 7px !important;
}
.card-button_URWNDw:disabled,
.card-button_URWNDw[disabled] {
  opacity: 0.6 !important;
}
.opacity {
  opacity: 0.6 !important;
}
@media screen and (max-width: 370px) {
  .card-button_URWNDw {
    height: 43px !important;
  }

  .card-button_URWNDw svg {
    width: 13px !important;
    height: 11px !important;
  }
}
</style>
