<template>
  <div
    v-if="show"
    :class="[$style['widget-alert'], $style['widget-alert-error']]"
  >
    <IconWarning />
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    message: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted: function () {},
  computed: {},
};
</script>

<style scoped lang="scss"></style>
<style module scoped>
.widget-alert {
  padding: 0 30px !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 60px !important;
  margin-bottom: 22px !important;
  margin-top: -20px !important;
}
.widget-alert svg {
  flex-shrink: 0 !important;
  margin-right: 7px !important;
}
.widget-alert span {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.widget-alert-error {
  background-color: #fee2e2 !important;
}
.widget-alert-error span {
  color: #7f1d1d !important;
}
@media screen and (max-width: 475px) {
  .widget-alert {
    padding: 0 15px !important;
    margin-top: -10px !important;
  }
}
@media screen and (max-width: 370px) {
  .widget-alert span {
    font-size: 12px !important;
    line-height: 14px !important;
  }

  .widget-alert {
    padding: 0 15px !important;
    height: 43px !important;
    margin-bottom: 15px !important;
    margin-top: -15px !important;
  }

  .widget-alert svg {
    margin-right: 5px !important;
    width: 20px !important;
    height: 20px !important;
  }
}
</style>
