<template>
  <DonationNavigation
    :title="title"
    :has-left="hasLeft"
    :left-content="leftContent"
    :left-action="leftAction"
    :has-right="hasRight"
    :right-content="rightContent"
    :right-action="rightAction"
    :right-bold="rightBold"
    :step="step"
    :close-icon="true"
    :current-step-name="currentStepName"
  />
  <!-- Spinner -->
  <slot />
  <div
    ref="bottom"
    :class="$style['widget-card']"
    class="widget-card-view__bottom_GTYds"
  >
    <div :class="$style['widget-card__content']">
      <CancelDonationModal />
      <form @submit.prevent>
        <div class="MDonationView_widget-card__body_XcV5qX">
          <DonationHeader :has-hero-image="showHeroImage" />

          <div id="widget-notifications">
            <VNotifications />
          </div>
          <div
            :class="$style['widget-card__heading']"
            v-if="showCardHeadingSlot"
          >
            <slot name="card_body_block_heading_1"></slot>
          </div>
          <slot name="card_body_block_heading_2"></slot>

          <slot name="card_body_block_donation-input"></slot>
        </div>
        <div :class="$style['widget-card__footer']">
          <div class="ButtonView_widget-card__footer-buttons_FrgASq">
            <slot name="footer-buttons" />
          </div>

          <div :class="$style['widget-card__footer-copy']">
            <IconSecure />
            <span
              >Secure Donation. Powered by
              <a :href="site" target="_blank">{{ companyName }}</a></span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import DonationView from "@/global/mixins/wrapperComponents/DonationView.js";

export default {
  inject: ["donationWidgetSetupPage"],
  name: "MDonationView",
  mixins: [DonationView],
};
</script>

<style lang="scss">
.ButtonView_widget-card__footer-buttons_FrgASq {
  display: flex !important;
  gap: 20px !important;
  align-items: center !important;
  margin-bottom: 20px !important;
}

@media screen and (max-width: 370px) {
  .ButtonView_widget-card__footer-buttons_FrgASq {
    gap: 15px !important;
    margin-bottom: 15px !important;
  }
}
</style>

<style module>
.widget-card__footer-copy {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #9ca3af !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
}
.widget-card__footer-copy svg {
  margin-right: 7px !important;
}
.widget-card__footer-copy a {
  color: #9ca3af !important;
  text-decoration: underline !important;
  font-weight: 600 !important;
}
.widget-card__heading {
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
  border-bottom: 1px solid #e5e7eb !important;
}

.widget-card__heading p {
  word-break: break-word !important;
}

.widget-card .bold {
  font-weight: 500 !important;
}
.widget-card input,
.widget-card textarea {
  display: block !important;
  width: 100% !important;
  font-family: "Inter", sans-serif !important;
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #271211 !important;
  border: none !important;
  outline: none !important;
}
.widget-card select {
  font-size: 16px !important;
  font-family: "Inter", sans-serif !important;
  border: none !important;
  outline: none !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  display: block !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  text-indent: 0 !important;
}

.widget-card select::-ms-expand {
  display: none !important;
}

.widget-card input::-webkit-input-placeholder {
  color: #9ca3af !important;
}

.widget-card input:-moz-placeholder {
  color: #9ca3af !important;
}

.widget-card input::-moz-placeholder {
  color: #9ca3af !important;
}

.widget-card input:-ms-input-placeholder {
  color: #9ca3af !important;
}

.widget-card input::-ms-input-placeholder {
  color: #9ca3af !important;
}

.widget-card input::placeholder {
  color: rgba(29, 31, 35, 0.6) !important;
  color: #9ca3af !important;
}
.widget-card__content {
  position: relative !important;
  padding: 40px !important;
}
.widget-card__footer {
  margin-top: 20px !important;
  padding-top: 20px !important;
  border-top: 1px solid #e5e7eb !important;
}

@media screen and (max-width: 735px) {
  .widget-card {
    flex: 1 auto !important;
  }
  .widget-card__content {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    height: 100% !important;
  }
}
@media screen and (max-width: 475px) {
  .widget-card__content {
    padding: 30px 20px !important;
  }
}
@media screen and (max-width: 370px) {
  .widget-card__footer-copy {
    font-size: 11px !important;
    line-height: 14px !important;
  }

  .widget-card__footer-copy svg {
    width: 10px !important;
    height: 12px !important;
  }
  .widget-card input,
  .widget-card textarea {
    font-size: 14px !important;
    line-height: 17px !important;
  }
  .widget-card select {
    font-size: 14px !important;
  }
  .widget-card__content {
    padding: 30px 15px !important;
  }
  .widget-card__footer {
    margin-top: 15px !important;
    padding-top: 15px !important;
  }

  .widget-card__heading {
    margin-bottom: 15px !important;
    padding-bottom: 15px !important;
  }
}
</style>
