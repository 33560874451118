import { mapState } from "vuex";

export default {
  props: {
    hasNavigation: {
      type: Boolean,
      default: true,
    },
    showCardHeadingSlot: {
      type: Boolean,
      default: true,
    },
    hasHeroImage: {
      type: Boolean,
      default: null,
    },
    hasFooter: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: undefined,
    },
    hasLeft: {
      type: Boolean,
      default: undefined,
    },
    leftContent: {
      type: String,
      default: undefined,
    },
    leftAction: {
      type: Function,
      default: undefined,
    },
    hasRight: {
      type: Boolean,
      default: undefined,
    },
    rightContent: {
      type: String,
      default: undefined,
    },
    rightAction: {
      type: Function,
      default: undefined,
    },
    rightBold: {
      type: Boolean,
      default: undefined,
    },
    hasOrganizationInfo: {
      type: Boolean,
      default: false,
    },
    isBare: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      required: true,
    },
    currentStepName: {
      type: String,
      required: true,
    },
  },

  data: () => ({}),

  computed: {
    ...mapState("config", {
      site: (state) => state.config["fe-informations"].site,
      companyName: (state) => state.config["fe-informations"].companyName,
    }),
    showHeroImage() {
      if (this.hasHeroImage !== null) {
        return this.hasHeroImage;
      }
      return false;
    },
  },
  mounted: function () {
    this.$nextTick(function () {});
  },
  beforeUnmount() {},
  methods: {},
};
