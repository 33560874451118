var nAgt = navigator.userAgent;
var _browserName = navigator.appName;
var _fullVersion = "" + parseFloat(navigator.appVersion);
var _majorVersion = parseInt(navigator.appVersion, 10);
var nameOffset, verOffset, ix;

// In Opera, the true version is after "Opera" or after "Version"
if ((verOffset = nAgt.indexOf("Opera")) != -1) {
  _browserName = "Opera";
  _fullVersion = nAgt.substring(verOffset + 6);
  if ((verOffset = nAgt.indexOf("Version")) != -1)
    _fullVersion = nAgt.substring(verOffset + 8);
}
// In MSIE, the true version is after "MSIE" in userAgent
else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
  _browserName = "Microsoft Internet Explorer";
  _fullVersion = nAgt.substring(verOffset + 5);
}
// In Chrome, the true version is after "Chrome"
else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
  _browserName = "Chrome";
  _fullVersion = nAgt.substring(verOffset + 7);
}
// In Safari, the true version is after "Safari" or after "Version"
else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
  _browserName = "Safari";
  _fullVersion = nAgt.substring(verOffset + 7);
  if ((verOffset = nAgt.indexOf("Version")) != -1)
    _fullVersion = nAgt.substring(verOffset + 8);
}
// In Firefox, the true version is after "Firefox"
else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
  _browserName = "Firefox";
  _fullVersion = nAgt.substring(verOffset + 8);
}
// In most other browsers, "name/version" is at the end of userAgent
else if (
  (nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))
) {
  _browserName = nAgt.substring(nameOffset, verOffset);
  _fullVersion = nAgt.substring(verOffset + 1);
  if (_browserName.toLowerCase() == _browserName.toUpperCase()) {
    _browserName = navigator.appName;
  }
}
// trim the _fullVersion string at semicolon/space if present
if ((ix = _fullVersion.indexOf(";")) != -1)
  _fullVersion = _fullVersion.substring(0, ix);
if ((ix = _fullVersion.indexOf(" ")) != -1)
  _fullVersion = _fullVersion.substring(0, ix);

_majorVersion = parseInt("" + _fullVersion, 10);
if (isNaN(_majorVersion)) {
  _fullVersion = "" + parseFloat(navigator.appVersion);
  _majorVersion = parseInt(navigator.appVersion, 10);
}

export const browserName = _browserName;
export const fullVersion = _fullVersion;
export const majorVersion = _majorVersion;
export const navigatorAppName = navigator.appName;
export const navigatorUserAgent = navigator.userAgent;
export const platform = navigator.platform;

export const userAgentData = {
  mobile: navigator?.userAgentData?.mobile
    ? navigator.userAgentData.mobile
    : undefined,
  platform: navigator?.userAgentData?.platform
    ? navigator.userAgentData.platform
    : undefined,
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getUTCMonth() + 1),
      padTo2Digits(date.getUTCDate()),
    ].join("-") +
    " " +
    [
      padTo2Digits(date.getUTCHours()),
      padTo2Digits(date.getUTCMinutes()),
      padTo2Digits(date.getUTCSeconds()),
    ].join(":")
  );
}
