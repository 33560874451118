<template>
  <div :class="divButtonClassNameWrapper">
    <button
      :class="[buttonClassName, querySelectorClass, hideButtonTemp]"
    ></button>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  inject: ["$http"],
  name: "App",
  data: () => ({
    hideButtonTemp: "fe-easygiving_button_hidden_TRDSfdq",
    themeTagId: "",
  }),
  computed: {
    ...mapGetters("organization", [
      "feEasygivingButtonStyle",
      "feEasygivingButtonText",
      "hasButtonSettings",
    ]),
    ...mapState("organization", {
      buttonClassName: (state) =>
        state.organization.buttonSettings.feEasygivingDefaultButtonSettings
          .buttonClassName,
      divButtonClassNameWrapper: (state) =>
        state.organization.buttonSettings.feEasygivingDefaultButtonSettings
          .divButtonClassNameWrapper,
      querySelectorClass: (state) =>
        state.organization.buttonSettings.feEasygivingDefaultButtonSettings
          .querySelectorClass,
    }),
  },
  watch: {
    feEasygivingButtonStyle() {
      this.setButtonConfig();
    },
    hasButtonSettings(value) {
      if (value) {
        this.setTheme();

        this.setButtonConfig();
      }
    },
  },
  mounted() {
    this.$http.get(`/settings/button`).then(({ data }) => {
      const response = data.data;
      if (data.status == "success") {
        this.$store.commit("organization/setOrganizationButtonSettings", {
          feEasygivingButtonSettings: response,
        });
      }
    });
  },
  methods: {
    setTheme() {
      if (!this.themeTagId) {
        const id = "fe-easygiving-button-theme";
        const isset = document.getElementById(id);
        if (!isset) {
          const el = document.createElement("style");
          el.id = id;
          document.head.appendChild(el);
        }

        this.themeTagId = id;
      }
      const el = document.querySelector(`style#${this.themeTagId}`);
      if (el) {
        el.textContent = this.feEasygivingButtonStyle;
      }
    },
    setButtonConfig() {
      let feEasygivingButton = document.querySelector(
        `.${this.querySelectorClass}`
      );
      if (feEasygivingButton) {
        feEasygivingButton.innerText = this.feEasygivingButtonText;
        feEasygivingButton.classList.remove(this.hideButtonTemp);

        this.$store.commit("organization/setStatusMountedButton", true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fe-easygiving_button_hidden_TRDSfdq {
  display: none !important;
}
</style>
