export default function formatMoney(
  value,
  forceTwoDecimalPlacesOnFloat = false,
  forceTwoDecimalPlacesOnInteger = false
) {
  const splittedValue = String(value).split(".");

  let formattedValue = splittedValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (typeof splittedValue[1] === "string") {
    formattedValue += `.${splittedValue[1].substring(0, 2)}`;

    if (forceTwoDecimalPlacesOnFloat && splittedValue[1].length === 1) {
      formattedValue += 0;
    }
  }

  if (forceTwoDecimalPlacesOnInteger && Number.isInteger(value)) {
    formattedValue += ".00";
  }

  return formattedValue;
}

function cutDecimals(num, decimals = 2) {
  const escaped = num.toString().replace(/,/g, "");
  const splitted = escaped.split(".");
  const integer = splitted[0];
  const fraction = splitted[1] ? splitted[1] : "";
  const dotOrNot = splitted[1] ? "." : "";
  const finalString = `${integer}${dotOrNot}${fraction.substr(0, decimals)}`;

  return typeof num === "string" ? finalString : parseFloat(finalString);
}

export { cutDecimals };
