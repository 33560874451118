<template>
  <div class="c-v-spinner-overlay" :class="{ 'is-visible': isVisible }">
    <VSpinner :size="size" :ring-size="5" />
  </div>
</template>

<script>
import VSpinner from "@/global/components/commons/VSpinner";
const DEFAULT_SIZE = 50;
export default {
  name: "VSpinnerOverlay",
  components: {
    VSpinner,
  },
  props: {
    size: {
      type: Number,
      default: DEFAULT_SIZE,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.c-v-spinner-overlay {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--theme-style-spinner-backgroundColor) !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  transition: visibility 0s 0.1s, opacity 0.1s !important;
  visibility: hidden !important;
  opacity: 0 !important;

  &.is-visible {
    visibility: visible !important;
    opacity: 1 !important;
    transition-delay: 0s !important;
  }
}
</style>
