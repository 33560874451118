<template>
  <div class="TheBasicDetails_widget-checkbox_heydDS">
    <div :class="$style['widget-checkbox__flex']">
      <input
        ref="checkbox"
        type="checkbox"
        id="check"
        v-model="model"
        :name="name"
        :disabled="isDisabled"
        @keydown.enter="enter"
        @change="$emit('update', $event.target.checked)"
      />

      <label for="check">{{ label }}</label>
    </div>
    <slot name="icon"></slot>
  </div>
</template>
<script>
export default {
  name: "FieldCheckbox",
  props: {
    name: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Label",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInvalidInput: false,
      validationHint: null,
      model: false,
    };
  },
  watch: {
    isInvalid(val) {
      this.isInvalidInput = val;
    },
    value(value) {
      this.model = value;
    },
    model(value) {
      this.$emit("update", value);
    },
  },
  computed: {},
  methods: {
    enter(event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped>
.TheBasicDetails_widget-checkbox_heydDS > div > label {
  width: unset !important;
}
.TheBasicDetails_widget-checkbox_heydDS {
  display: flex !important;
  align-items: center !important;
}
.TheBasicDetails_widget-checkbox_heydDS input {
  flex-shrink: 0 !important;
  width: 18px !important;
  height: 18px !important;
  border: 1px solid #bdbdbd !important;
  border-radius: 3px !important;
  outline: none !important;
  padding: 0 !important;
  margin-right: 7px !important;
}
.TheBasicDetails_widget-checkbox_heydDS label {
  font-size: 14px !important;
  color: #777777 !important;
  line-height: 16px !important;
  margin-top: 2px !important;
}
@media screen and (max-width: 370px) {
  .TheBasicDetails_widget-checkbox_heydDS label {
    font-size: 12px !important;
    line-height: 13px !important;
    margin-top: 0 !important;
  }

  .TheBasicDetails_widget-checkbox_heydDS input {
    flex-shrink: 0 !important;
    width: 13px !important;
    height: 13px !important;
  }
}
</style>
<style module scoped>
.widget-checkbox {
  display: flex !important;
  align-items: center !important;
}
.widget-checkbox__flex {
  display: flex !important;
}
.widget-checkbox input {
  flex-shrink: 0 !important;
  width: 18px !important;
  height: 18px !important;
  border: 1px solid #bdbdbd !important;
  border-radius: 3px !important;
  outline: none !important;
  padding: 0 !important;
  margin-right: 7px !important;
}
.widget-checkbox label {
  font-size: 14px !important;
  color: #777777 !important;
  line-height: 16px !important;
  margin-top: 2px !important;
}
@media screen and (max-width: 475px) {
}
@media screen and (max-width: 370px) {
  .widget-checkbox label {
    font-size: 12px !important;
    line-height: 13px !important;
    margin-top: 0 !important;
  }
  .widget-checkbox input {
    flex-shrink: 0 !important;
    width: 13px !important;
    height: 13px !important;
  }
}
</style>
