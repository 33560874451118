<template>
  <YesNoModal
    v-if="isActive"
    @left="backToDonation"
    @right="cancelDonation"
    :modalContainerPositionCss="modalContainerPositionCss"
  >
    <template #header>
      <h3>Cancel Donation?</h3>
    </template>
    <template #body>
      <p>All information that was provided will be lost</p>
      <p>and the process will start from the beginning.</p>
    </template>
    <template #left>
      <div>Back to Donation</div>
    </template>
    <template #right>
      <div>Cancel Donation</div>
    </template>
  </YesNoModal>
</template>

<script>
import yesNo from "@/global/mixins/UI/modals/yesNo.vue";
import { mapState } from "vuex";

export default {
  name: "CancelDonationModal",
  components: { YesNoModal: yesNo },

  data: () => ({
    isActive: false,
    modalContainerPositionCss:
      "margin: 0 auto auto !important; border-radius: 0px 0px 10px 10px !important;",
  }),
  computed: {
    ...mapState("notifications", ["eventsCancelModal"]),
  },

  watch: {
    "eventsCancelModal.clickedCancelDonationWidget": {
      handler(action) {
        this.isActive = action;
      },
      deep: true,
    },
  },

  methods: {
    cancelDonation() {
      this.isActive = false;
      this.$store.dispatch("notifications/confirmedCancellationDonation", true);
    },
    backToDonation() {
      this.isActive = false;
      this.$store.dispatch("notifications/restoreEventsCancelModal");
    },
  },
};
</script>
