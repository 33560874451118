import axios from "axios";
import { config } from "@/config";

export default function httpSetup(store) {
  const { token } = store.state.config.config.external;

  const instance = axios.create({
    baseURL: config.apiBase,
    headers: {
      token,
      auth: config.auth,
    },
  });

  if (!store) {
    // TODO: error, authentication handling -> to AWS clowdWatch report
    return instance;
  }

  instance.interceptors.request.use(
    (httpConfig) => {
      //!!!!
      const { token } = store.state.authentication;
      if (token) {
        httpConfig.headers.Authorization = `token ${token}`;
      }
      return httpConfig;
    },
    (error) => {
      if (process.env.NODE_ENV !== "production") {
        console.log("REQUEST_ERROR", error);
      } else {
        store.dispatch("logs/error", {
          token: token,
          reason: `1. file: /src/http/base.js; 2. action: The request interceptor;`,
          data: error,
        });
        // TODO: ERROR, authentication handling -> to AWS clowdWatch report
      }
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;

      let message = "";
      let messageHtml = "";
      if (!response || (response.status >= 500 && response.status < 600)) {
        message = "Unexpected error. Please try again.";
      } else if (response.status >= 400 && response.status < 500) {
        if (response.data && response.data.message) {
          ({ message, messageHtml } = response.data);
        } else {
          message = "There was a problem. Please try again.";
        }
      }
      if (messageHtml) {
        store.dispatch("notifications/notifyError", {
          text: messageHtml,
          isHtml: true,
        });
      } else if (
        response &&
        response.status &&
        response.status === 401 &&
        !store.state.authentication &&
        !store.state.authentication.ignoreAuthenticationErrors
      ) {
        console.log("!!!!!!!!", response);
        store.commit("authentication/setAuthenticated", false);
      } else {
        store.dispatch("notifications/notifyError", message);
      }

      store.dispatch("logs/error", {
        token: token,
        reason: `1. file: /src/http/base.js; 2. action: The response interceptor;`,
        data: response,
      });
      return Promise.reject(error);
    }
  );

  return instance;
}
