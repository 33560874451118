const _ = require("lodash");
const DbLoki = require("@/global/util/db.loki");
class City {
  constructor(opts = {}) {
    this.id = opts.id;
    this.name = opts.name;
    this.country_code = opts.country_code;
    this.country_id = opts.country_id;
  }
}

class State {
  constructor(opts = {}) {
    this.id = opts.id;
    this.name = opts.name;
    this.abbrev = opts.state_code;
    this.country_code = opts.country_code;
    this.country_id = opts.country_id;
  }
}

class Country {
  constructor(
    { id, name, iso2, emoji, phonecode } = {
      id: 233,
      name: "United States",
      iso2: "US",
      emoji: "🇺🇸",
      phonecode: 1,
    }
  ) {
    this.id = id;
    this.name = name;
    this.abbrev = iso2;
    this.emoji = emoji;
    this.phonecode = this.preaparePhoneCode(phonecode);
  }

  preaparePhoneCode(phonecode) {
    if (!_.isEmpty(phonecode)) {
      return Array.from(phonecode)[0] === "+" ? phonecode : `+${phonecode}`;
    }
    return "";
  }
}
class GeoData {
  constructor(http) {
    this._countries = [];
    this._countryStates = {};
    this._stateCities = {};
    this._cities = [];
    this._states = {};
    this.$http = http;
  }

  async getCountries() {
    let countries = await DbLoki.getCollection("countries");
    return countries.data;
  }

  setCountries(value) {
    this._countries = value;
  }

  getStateCities() {
    return this._stateCities;
  }

  setStateCities(value) {
    return (this._stateCities = value);
  }

  async preload() {
    return await this.$http.get(`/settings/countries`).then((response) => {
      this._countries = _.sortBy(
        response.data.data.map((json) => new Country(json)),
        "name"
      );

      var [us] = _.remove(this._countries, { abbrev: "US" });
      var [ca] = _.remove(this._countries, { abbrev: "CA" });
      // this._countries.unshift({ name: "───────────" });
      this._countries.unshift(ca);
      this._countries.unshift(us);

      DbLoki.addCollection("countries").insert(this._countries);

      return response.data;
    });
  }

  cacheCountryStates(country, states_json) {
    this._countryStates[country.id] = _.sortBy(
      states_json.map((json) => {
        this._states[json.id] = new State(json);
        return this._states[json.id];
      }),
      "name"
    );
  }

  cacheStateCities(stateId, states_json = []) {
    this._stateCities[stateId] = _.sortBy(
      states_json.map((json) => {
        this._cities[json.id] = new City(json);
        return this._cities[json.id];
      }),
      "name"
    );
  }

  getState(...args) {
    if (args.length == 2) {
      var [country, state_search] = args;
      return this.countryOrStateSearch(
        this._countryStates[country.id],
        state_search
      );
    } else {
      if (_.isNumber(args[0])) return this._states[args[0]];
      else return null;
    }
  }

  getCity(...args) {
    if (args.length == 2) {
      var [stateId, city_search] = args;
      return this.stateOrCitySearch(this._stateCities[stateId], city_search);
    } else {
      if (_.isNumber(args[0])) return this._cities[args[0]];
      else return null;
    }
  }

  stateOrCitySearch(list, where) {
    if (_.isString(where)) where = { abbrev: where };
    if (_.isNumber(where)) where = { id: where };
    return _.find(list, where);
  }

  countryOrStateSearch(list, where) {
    if (_.isNumber(where)) where = { id: where };
    return _.find(list, where);
  }

  async loadStatesIn(country) {
    if (!country || !country.id) return [];
    return await this.$http
      .get(`/settings/countries/${country.id}/states`)
      .then((response) => {
        const states = response.data.data;

        let collectionStates = DbLoki.getCollection("states");
        if (collectionStates) {
          collectionStates.clear();
        }

        this.cacheCountryStates(country, states);

        DbLoki.addCollection("states").insert(this._countryStates[country.id]);

        return this._countryStates[country.id];
      });
  }

  async loadCitiesIn(stateId) {
    if (!stateId) return [];

    return await this.$http
      .get(`/settings/states/${stateId}/cities`)

      .then((response) => {
        const cities = response.data.data;

        let collectionCities = DbLoki.getCollection("cities");
        if (collectionCities) {
          collectionCities.clear();
        }

        this.cacheStateCities(stateId, cities);

        DbLoki.addCollection("cities").insert(this._stateCities[stateId]);

        return this._stateCities[stateId];
      });
  }

  async findCountryByName(countryName = "") {
    if (!countryName) return [];

    let collection = DbLoki.getCollection("countries");

    if (collection) {
      collection = await collection
        .chain()
        .where(function (obj) {
          return (
            obj.name.toLocaleLowerCase() == countryName.toLocaleLowerCase()
          );
        })
        .data();

      if (collection) {
        collection = [
          ...new Map(collection.map((item) => [item["id"], item])).values(),
        ];
      }

      return collection[0];
    }
    return [];
  }

  async findStateByName(stateName = "") {
    if (!stateName) return [];

    let collection = DbLoki.getCollection("states");

    if (collection) {
      collection = await collection
        .chain()
        .where(function (obj) {
          return obj.name.toLocaleLowerCase() == stateName.toLocaleLowerCase();
        })
        .data();

      if (collection) {
        collection = [
          ...new Map(collection.map((item) => [item["id"], item])).values(),
        ];
      }

      return collection[0];
    }
    return [];
  }
}
module.exports = { GeoData };
