"use strict";

const config = {
  apiBase: "",
  auth: "",
  v3: "",
  env: process.env.NODE_ENV,
};

const internalСonfig = {
  validationForms: {
    production: {
      disabled: false,
      secret: "__PRODUCTION__",
    },
    staging: {
      disabled: false,
      secret: "__STAGING__",
    },
    development: {
      disabled: false,
      secret: "__DEVELOPMENT__",
    },
  },
};

switch (process.env.NODE_ENV) {
  case "production":
    config.apiBase = "https://api-easygiving.fundeasy.com/v1";
    config.auth =
      "awsk_prd_cfe17f47c80dd45a9cb37137dd7d4e704cbb5b1c21b1c44550db67834ac64463";
    config.v3 = "https://app.fundeasy.com";
    break;

  case "staging":
    config.apiBase = "https://api.staging-easygiving.fundeasy.com/v1";
    config.auth =
      "awsk_stg_3d7d4ead981a662cb59e4a0b4f79edae954c6ca5643e5b325bb81d8883b6514c";
    config.v3 = "https://app.insite-technology.com";
    break;

  default: //awsk_dev_255511213a2d64c821ac7e8bc23f487c369cf1bd2597224561c509ccf2c4d142
    config.apiBase = "https://api.staging-easygiving.fundeasy.com/v1";
    // config.apiBase = "http://localhost:2525/v1";
    config.auth =
      // "awsk_dev_255511213a2d64c821ac7e8bc23f487c369cf1bd2597224561c509ccf2c4d142";
      "awsk_stg_3d7d4ead981a662cb59e4a0b4f79edae954c6ca5643e5b325bb81d8883b6514c";
    config.v3 = "https://app.bcc-dev.com:1337";
    // config.v3 = "http://localhost:8082";
    break;
}

config.mode = {
  demo: location.origin == config.v3,
};

const feInformations = {
  companyName: "FundEasy",
  site: "https://fundeasy.com",
};

const theme = {
  default: {
    spinner: {
      backgroundColor: "#3b82f633",
      colorInnerCircle: "#1d1f23",
    },
  },
};

const forms = {
  steps: {},
};

module.exports = {
  "fe-informations": { ...feInformations },
  "theme-default": { ...theme.default },
  "form-steps": { ...forms.steps },
  config,
  internalСonfig,
};
