// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VButtonBack_card-button_border_Dp__0[data-v-624bbe06]{color:var(--theme-style-mainColor)!important;background-color:transparent!important;border:1px solid var(--theme-style-mainColor)!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-button_border": "VButtonBack_card-button_border_Dp__0"
};
export default ___CSS_LOADER_EXPORT___;
