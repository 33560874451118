import formatMoney from "@/global/util/money";
import axiosInstanceCreator from "@/http/index";

const defaultState = () => ({
  start: {},
  donorInfo: {},
  donorNote: {},
  paymentDetails: {},
  summaryPrepared: {},
});

const getters = {
  amountFormatted({ start }) {
    const { amount } = start;
    if (typeof amount !== "number") {
      return amount;
    }
    return formatMoney(amount, true);
  },

  getSummaryPreparedForPay(state) {
    const { donorInfo, start, paymentDetails, donorNote } = state;
    const firstName = donorInfo?.firstName ? donorInfo.firstName : "";
    const lastName = donorInfo?.lastName ? donorInfo.lastName : "";
    let donation = start?.amount ? parseFloat((start.amount + "").replaceAll(',','')) : 0;
    let fees = parseFloat(start.fee);
    const coverFee = Boolean(paymentDetails.coveringProcessingFees);
    const anonymous = Boolean(donorInfo.donationAsAnonymous);
    const countryCode = donorInfo?.countryCode ? donorInfo.countryCode : "";
    const countryCodeBilling = paymentDetails?.countryCode
      ? paymentDetails.countryCode
      : "";
    const address = donorInfo?.address ? donorInfo.address : "";
    const country = donorInfo?.countryName ? donorInfo.countryName : "";
    const countryBilling = paymentDetails?.countryName
      ? paymentDetails.countryName
      : "";
    const city = donorInfo?.cityName ? donorInfo.cityName : "";
    const stateProvince =
      donorInfo?.addressStateName && donorInfo.addressStateName != ""
        ? donorInfo.addressStateName
        : "";
    const stateCode = donorInfo?.addressStateCode
      ? donorInfo.addressStateCode
      : "";
    const postalCode = donorInfo?.postalCode ? donorInfo.postalCode : "";
    const postalCodeBilling = paymentDetails?.postalCode
      ? paymentDetails.postalCode
      : "";
    const email = donorInfo?.email ? donorInfo.email : "";
    const securityCode = paymentDetails?.securityCode
      ? paymentDetails.securityCode
      : "";
    const expirationDate = paymentDetails?.expirationDate
      ? paymentDetails.expirationDate
      : "";
    const cardNumber = paymentDetails?.cardNumber
      ? paymentDetails.cardNumber
      : "";
    const paymentMethod = state?.paymentMethod ? state.paymentMethod : "";
    const phone = donorInfo?.phone ? donorInfo.phone : "";
    const note = donorNote?.note ? donorNote.note : "";

    let totalAmount = coverFee ? donation + fees : donation;
    totalAmount = Math.round((totalAmount + Number.EPSILON) * 100) / 100;

    return {
      name: `${firstName} ${lastName}`,
      country,
      countryBilling,
      address,
      countryCode,
      countryCodeBilling,
      city,
      coverFee,
      anonymous,
      stateProvince,
      stateCode,
      postalCode,
      postalCodeBilling,
      email,
      donation,
      fees,
      totalAmount,
      securityCode,
      expirationDate,
      cardNumber,
      paymentMethod,
      phone,
      note,
    };
  },
  isMethodCard(state) {
    return state.paymentMethod === "card";
  },
  isMethodGooglePay(state) {
    return state.paymentMethod === "googlePay";
  },
  isMethodApplePay(state) {
    return state.paymentMethod === "applePay";
  },
};

const actions = {
  fetchPaymentsFee({ commit, state }) {
    const $http = axiosInstanceCreator();
    if (state.start?.amount) {
      const asDefaultFee = parseFloat((state.start.amount + "").replaceAll(',', '')) * 0.065;
      return $http(`/payments/fee?amount=${state.start.amount}`)
        .then(({ data }) => {
          if (data.status == "success") {
            commit("setPaymentsFee", data.data.fee);
          } else {
            commit("setPaymentsFee", asDefaultFee);
          }
        })
        .catch(() => {
          commit("setPaymentsFee", asDefaultFee);
        });
    }

    return Promise.resolve();
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

const mutations = {
  //use
  setStart(state, payload) {
    state.start = payload;
  },
  //use
  setDonorInfo(state, donorInfo) {
    state.donorInfo = donorInfo;
  },
  setPaymentsFee(state, payload) {
    state.start.fee = parseFloat(payload);
  },
  setPaymentDetails(state, paymentDetails) {
    state.paymentDetails = Object.assign(state.paymentDetails, paymentDetails);
  },
  setDonorNote(state, donorNote) {
    state.donorNote = donorNote;
  },
  setPaymentMethod(state, paymentMethod) {
    state.paymentMethod = paymentMethod;
  },

  resetState(state) {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  actions,
  mutations,
};
