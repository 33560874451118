 /**
* It takes two objects and returns a new object that is the result of merging the two objects together
* @param obj1 - The first object to merge
* @param obj2 - The second object to merge
*/
function concatObjects(obj1, obj2) {
 let result = {};
 Object.keys(obj1).forEach((key) => {
   if (obj2[key]) {
     result[key] = Object.assign(obj1[key], obj2[key]);
   } else {
     result[key] = obj1[key];
   }
 });

 Object.keys(obj2).forEach((key) => {
   if (!obj1[key]) {
     result[key] = obj2[key];
   }
 })

 return result;
}

export { concatObjects };