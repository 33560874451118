import IconMonitor from "@/global/components/UI/IconMonit";
import IconSecure from "@/global/components/UI/IconSecure";
import IconForwardArrow from "@/global/components/UI/IconForwardArrow";
import IconThankYou from "@/global/components/UI/IconThankYou";
import IconHelper from "@/global/components/UI/IconHelper";
import IconWarning from "@/global/components/UI/IconWarning";
import IconClose from "@/global/components/UI/IconClose";

export default [
  IconMonitor,
  IconSecure,
  IconForwardArrow,
  IconThankYou,
  IconHelper,
  IconWarning,
  IconClose,
];
