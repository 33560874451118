<template>
  <MDonationView
    :title="titleStepName"
    :step="step"
    :current-step-name="currentStepName"
  >
    <template v-slot:card_body_block_heading_1>
      <h6 :class="$style['widget-title']">Thank you for your donation!</h6>
      <p>
        Shortly, you will receive a summary of your donation at
        <span :class="$style['bold']">{{ email }}</span
        >.
      </p>
    </template>

    <template v-slot:card_body_block_heading_2>
      <div :class="$style['widget-thank-you']">
        <div :class="$style['widget-thank-you__icon']">
          <IconThankYou />
        </div>
      </div>
    </template>
  </MDonationView>
</template>

<script>
import thankYou from "@/global/mixins/page/thankYou.js";

export default {
  mixins: [thankYou],

  data: () => ({
    presetName: "modal",
  }),
};
</script>

<style lang="scss" src="@/global/assets/css/page/thankYou.scss"></style>
<style module scoped src="@/global/assets/css/page/module/thankYou.css"></style>
