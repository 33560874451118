<template>
  <div :class="$style['widget-form-row']">
    <div :class="[$style['widget-form-field']]">
      <FieldCardNumber
        :value="card.cardNumber"
        v-model:is-amex="card.isAmex"
        label="Card Number"
        :name="name"
        @update="updateCard"
        :is-invalid-card="isInvalidCard"
      />
    </div>
  </div>

  <div :class="[$style['widget-form-row'], $style['widget-form-row-half']]">
    <div :class="$style['widget-form-field']">
      <FieldCardDate
        :value="card.expirationDate"
        :is-invalid-cc-exp="isInvalidCcExp"
        @update="updateCard"
      />
    </div>
    <div :class="$style['widget-form-field']">
      <FieldGeneral
        ref="securityCode"
        :value="card.securityCode"
        label="CVC"
        name="securityCode"
        :is-required="true"
        placeholder="***"
        :custom-keypress-handler="card.cardSecurityCodeKeypressHandler"
        :max-length="4"
        :is-invalid="isInvalidCvc"
        @update="updateCard"
      >
      </FieldGeneral>
    </div>
  </div>
</template>

<script>
import { cardDefaults } from "@/global/util/cardDefaults";

export default {
  name: "CardFields",
  emits: ["update"],
  props: {
    card: {
      type: Object,
      default: cardDefaults,
    },
    name: {
      type: String,
      default: "",
    },
    isInvalidCard: {
      type: Boolean,
      default: false,
    },
    isInvalidCvc: {
      type: Boolean,
      default: false,
    },
    isInvalidCcExp: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  watch: {},
  methods: {
    updateCard(value) {
      this.card[value.field] = value.value;
      this.$emit("update", {
        card: this.card,
      });
    },
  },
};
</script>

<style module scoped>
.widget-form-row {
  margin-bottom: 20px !important;
}

.widget-form-row-half {
  display: flex !important;
  gap: 20px !important;
  align-items: center !important;
}

.widget-form-row-half .widget-form-field {
  width: calc(50% - 10px) !important;
}
.card-logo {
  display: flex !important;
  position: absolute !important;
  right: 20px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.card-logo img {
  width: 30px !important;
  height: 19px !important;
  margin-right: 5px !important;
}
.card-logo img:last-child {
  margin-right: 0 !important;
}

@media screen and (max-width: 370px) {
  .widget-form-row {
    margin-bottom: 15px !important;
  }
  .widget-form-row-half {
    gap: 15px !important;
  }
  .card-logo img {
    width: 26px !important;
    height: 16px !important;
  }

  .card-logo {
    right: 10px !important;
  }
}

.widget-form-field {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  position: relative !important;
  height: 60px !important;
  padding: 9px 15px !important;
  border: 1px solid #e5e7eb !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px !important;
}
.widget-form-field label {
  display: block !important;
  line-height: 12px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color: #6b7280 !important;
  margin-bottom: 9px !important;
}

.widget-form-field.error label {
  color: #dc2626 !important;
}

.widget-form-group {
  border: 1px solid #e5e7eb !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px !important;
  margin-bottom: 20px !important;
}
.widget-form-group .widget-form-row {
  gap: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #e5e7eb !important;
}
.widget-form-group .widget-form-row:last-child {
  border-bottom: none !important;
}
.widget-form-group .widget-form-field {
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
}
.widget-form-group .widget-form-row-half .widget-form-field {
  width: 50% !important;
}
.widget-form-group .widget-form-row-half .widget-form-field:nth-child(1) {
  border-right: 1px solid #e5e7eb !important;
}

@media screen and (max-width: 370px) {
  .widget-form-field {
    height: 50px !important;
    padding: 10px 10px !important;
  }
  .widget-note .widget-form-field {
    height: 280px !important;
  }
  .widget-card select {
    font-size: 14px !important;
  }
  .widget-form-group {
    margin-bottom: 15px !important;
  }

  .widget-form-field label {
    font-size: 9px !important;
    margin-bottom: 6px !important;
    line-height: 10px !important;
  }
  .widget-form-group .widget-form-field {
    height: 50px !important;
  }
  .widget-form-row-half {
    gap: 15px !important;
  }
}
</style>
