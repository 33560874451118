<template>
  <div v-if="selected.card.checked">
    <CardFields
      ref="card"
      :card="card"
      name="cardNumber"
      @update="updateCardData"
      :is-invalid-card="isInvalidCard"
      :is-invalid-cvc="isInvalidCvc"
      :is-invalid-cc-exp="isInvalidCcExp"
    />

    <div :class="[$style['widget-form-row'], $style['widget-form-row-half']]">
      <div :class="$style['widget-form-field']">
        <AutoComplete
          ref="country"
          label="Country/Province"
          collection-db="countries"
          name="country"
          placeholder="United States"
          max-length="40"
          :is-required="true"
          @change="updateFormData"
          :set-pre-entered-data="setPreEnteredDataCountry"
        />
      </div>
      <div :class="$style['widget-form-field']">
        <FieldGeneral
          ref="postalCode"
          :value="items.postalCode"
          name="postalCode"
          label="Postal Code"
          placeholder="90210"
          :is-required="true"
          :is-invalid="isInvalidPostalCode"
          :max-length="10"
          @update="updateFormData"
        />
      </div>
    </div>
  </div>
  <!-- //CART -->

  <!-- GOOGLE -->
  <div v-if="selected.googlePay.checked">
    <div :class="$style['payment-details']">
      <div :class="$style['payment-details__info']">
        <div :class="$style['payment-details__info-icon']">
          <img :src="icons.google" alt="Google Pay selected" />
        </div>
        <p :class="$style['payment-details__info-tex']">
          Google Pay selected for check out.
        </p>
      </div>
      <div :class="$style['payment-details__description']">
        <div :class="$style['payment-details__description-icon']">
          <iconMonitor />
        </div>
        <p :class="$style['payment-details__description-text']">
          Another step will appear after submitting your order to complete your
          purchase details.
        </p>
      </div>
    </div>
  </div>
  <!-- //GOOGLE -->

  <!-- APPLE -->
  <div v-if="selected.applePay.checked">
    <div :class="$style['payment-details']">
      <div :class="$style['payment-details__info']">
        <div :class="$style['payment-details__info-icon']">
          <img :src="icons.apple" alt="Apple Pay selected" />
        </div>
        <p :class="$style['payment-details__info-text']">
          Apple Pay selected for check out.
        </p>
      </div>
      <div :class="$style['payment-details__description']">
        <div :class="$style['payment-details__description-icon']">
          <iconMonitor />
        </div>
        <p :class="$style['payment-details__description-text']">
          Another step will appear after submitting your order to complete your
          purchase details.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { cardDefaults } from "@/global/util/cardDefaults";
import apple from "@/global/assets/payment-methods/apple-pay.svg";
import google from "@/global/assets/payment-methods/google-pay.svg";

import validationMixin from "@/global/mixins/validationRules.js";
import { GeoData } from "@/store/geo_data.js";
import { mapState } from "vuex";
export default {
  name: "PaymentType",
  mixins: [validationMixin],
  inject: ["$http"],
  emits: ["update", "updateFormData"],
  props: {
    selected: {
      type: Object,
      required: true,
    },
    items: {
      type: Object,
      required: true,
    },
    isInvalidCard: {
      type: Boolean,
      default: false,
    },
    isInvalidCvc: {
      type: Boolean,
      default: false,
    },
    isInvalidCcExp: {
      type: Boolean,
      default: false,
    },
    isInvalidPostalCode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    card: cardDefaults(),
    countries: [],
    setPreEnteredDataCountry: {},
    country_loading: false,
    isWorking: false,
    defaultCountry: { id: 233, name: "United States", abbrev: "US" },
    bottom: {
      coverProcessingFees: false,
      rememberCard: false,
      shareContactInfo: true,
      mobileNumber: "",
      formattedNumber: "",
    },
  }),
  computed: {
    icons: () => ({
      apple,
      google,
    }),
    geoData() {
      return new GeoData(this.$http);
    },
    ...mapState("donation", ["donorInfo", "paymentDetails"]),
  },
  watch: {
    "items.country.id": {
      handler(value, old_value) {
        if (value != old_value) {
          this.setGlobalValidationInfoForPostalCode();
        }
      },
      deep: true,
    },
    "items.postalCode": {
      handler() {
        this.setGlobalValidationInfoForPostalCode();
      },
    },
  },
  async mounted() {
    this.setPreEnteredData();
  },
  async created() {
    await this.geoData.preload();
    await this.loadCountryList();
  },
  methods: {
    setGlobalValidationInfoForPostalCode() {
      if (this.items.country?.abbrev) {
        this.$store.commit("tempHelper/setValidationInfo", {
          postalCode: {
            countryCode: this.items.country.abbrev,
            postalCode: this.items.postalCode,
          },
        });
      }
    },
    setPreEnteredData() {
      if (!_.isEmpty(this.paymentDetails)) {
        const {
          postalCode,
          countryId,
          cardNumber,
          securityCode,
          expirationDate,
          countryName,
          countryCode,
        } = this.paymentDetails;

        this.items.country.id = countryId;
        this.items.country.abbrev = countryCode;
        this.items.country.name = countryName;
        this.items.postalCode = postalCode;

        this.setPreEnteredDataCountry = this.items.country;

        this.card.cardNumber = cardNumber;
        this.card.securityCode = securityCode;
        this.card.expirationDate = expirationDate;
      } else if (!_.isEmpty(this.donorInfo)) {
        const { postalCode, countryId, countryName, countryCode } =
          this.donorInfo;

        this.items.country.id = countryId;
        this.items.country.abbrev = countryCode;
        this.items.country.name = countryName;
        this.items.postalCode = postalCode;

        if (
          !this.items.country.id &&
          !this.items.country.name &&
          !this.items.country.abbrev
        ) {
          this.items.country = this.defaultCountry;
        }

        this.setPreEnteredDataCountry = this.items.country;
      }
    },
    async loadCountryList() {
      this.country_loading = true;
      this.countries = await this.geoData.getCountries();
      this.country_loading = false;
    },

    updateCardData(value) {
      this.$emit("update", {
        cardNumber: value.card.cardNumber,
        securityCode: value.card.securityCode,
        isAmex: value.card.isAmex,
        expirationDate: value.card.expirationDate,
      });
    },
    updateFormData(data) {
      let emit = {
        field: data.field,
        value: data.value,
      };
      if (data.field == "country") {
        emit = {
          field: data.field,
          id: data.id,
          name: data.name,
          abbrev: data.abbrev,
        };
      }
      this.$emit("updateFormData", emit);
    },
  },
};
</script>

<style module scoped>
.payment-details__info-icon img {
  border: 1px solid #e5e7eb !important;
  border-radius: 40px !important;
  width: 50px !important;
  height: 25px !important;
}
.payment-details__info-text {
  line-height: 19px !important;
}
.payment-details__description {
  display: flex !important;
}
.payment-details__description-icon {
  display: inline-flex !important;
  margin-right: 15px !important;
  flex-shrink: 0 !important;
}
.payment-details__description-text {
  font-size: 14px !important;
  line-height: 21px !important;
  color: #6b7280 !important;
}
.payment-details {
  margin-bottom: 74px !important;
  border: 1px solid #e5e7eb !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px !important;
  padding: 13px 15px 18px 15px !important;
}
.payment-details__info {
  padding-bottom: 20px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #e5e7eb !important;
}
.payment-details__info-icon {
  display: inline-flex !important;
  margin-bottom: 10px !important;
}
.widget-form-row {
  margin-bottom: 20px !important;
}

.widget-form-row-half {
  display: flex !important;
  gap: 20px !important;
  align-items: center !important;
}

.widget-form-row-half .widget-form-field {
  width: calc(50% - 10px) !important;
}

@media screen and (max-width: 370px) {
  .payment-details__description-text {
    font-size: 12px !important;
    line-height: 17px !important;
  }

  .payment-details__description-icon svg {
    width: 30px !important;
    height: 28px !important;
  }
  .payment-details {
    margin-bottom: 60px !important;
  }

  .payment-details__info-icon img {
    width: 43px !important;
    height: 23px !important;
  }

  .payment-details__info {
    padding-bottom: 15px !important;
    margin-bottom: 15px !important;
  }

  .payment-details__info-icon {
    margin-bottom: 8px !important;
  }
  .widget-form-row {
    margin-bottom: 15px !important;
  }
  .widget-form-row-half {
    gap: 15px !important;
  }
}

.widget-form-field {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  position: relative !important;
  height: 60px !important;
  padding: 9px 15px !important;
  border: 1px solid #e5e7eb !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px !important;
}
.widget-form-field label {
  display: block !important;
  line-height: 12px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color: #6b7280 !important;
  margin-bottom: 9px !important;
}

.widget-form-field.error label {
  color: #dc2626 !important;
}

.widget-form-group {
  border: 1px solid #e5e7eb !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px !important;
  margin-bottom: 20px !important;
}
.widget-form-group .widget-form-row {
  gap: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #e5e7eb !important;
}
.widget-form-group .widget-form-row:last-child {
  border-bottom: none !important;
}
.widget-form-group .widget-form-field {
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
}
.widget-form-group .widget-form-row-half .widget-form-field {
  width: 50% !important;
}
.widget-form-group .widget-form-row-half .widget-form-field:nth-child(1) {
  border-right: 1px solid #e5e7eb !important;
}

@media screen and (max-width: 370px) {
  .widget-form-field {
    height: 50px !important;
    padding: 10px 10px !important;
  }
  .widget-note .widget-form-field {
    height: 280px !important;
  }
  .widget-card select {
    font-size: 14px !important;
  }
  .widget-form-group {
    margin-bottom: 15px !important;
  }

  .widget-form-field label {
    font-size: 9px !important;
    margin-bottom: 6px !important;
    line-height: 10px !important;
  }
  .widget-form-group .widget-form-field {
    height: 50px !important;
  }
  .widget-form-row-half {
    gap: 15px !important;
  }
}
</style>
