<template>
  <KDonationView
    :title="titleStepName"
    :step="step"
    :current-step-name="currentStepName"
  >
    <template v-slot:card_body_block_heading_1>
      <h6 :class="$style['widget-title']">{{ title }}</h6>
      <p>
        {{ description }}
      </p>
    </template>
    <template v-slot:card_body_block_heading_2>
      <div :class="$style['widget-note']">
        <div :class="$style['widget-form-row']">
          <div :class="$style['widget-form-field']">
            <span
              :class="[
                $style['widget-note-counter'],
                {
                  [$style['middle']]: note.length >= maxMiddleLength,
                  [$style['error']]:
                    note.length == validationRule.rules.note.maxLength,
                },
              ]"
            >
              {{ note.length }}/{{ validationRule.rules.note.maxLength }}
            </span>
            <FieldGeneral
              style="resize: vertical; height: 90% !important"
              tag="textarea"
              ref="note"
              :value="note"
              name="note"
              label="Donation Note"
              placeholder="Note/Memo (Optional)"
              :is-required="validationRule.rules.note.required"
              :is-invalid="validationRule.invalid.note"
              :max-length="validationRule.rules.note.maxLength"
              @update="updateFormData"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer-buttons>
      <VButtonBack :label="buttonBack" @click.prevent="back" />
      <VButtonNext
        :label="buttonNext"
        :is-disabled="isDisabledContinue"
        @click="next"
      />
    </template>
  </KDonationView>
</template>

<script>
import donationNote from "@/global/mixins/page/donationNote.js";

export default {
  mixins: [donationNote],
  data: () => ({
    presetName: "kiosk",
  }),
};
</script>

<style module src="@/global/assets/css/page/module/donationNote.css"></style>
