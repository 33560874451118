import KioskComponent from "@kiosk/App.vue";

import KioskComponentRouter from "@kiosk/router";
import storeGlobal from "@/store";

export default {
  component: KioskComponent,
  router: KioskComponentRouter,
  store: storeGlobal,
};
