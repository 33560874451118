<template>
  <svg
    width="41"
    height="37"
    viewBox="0 0 41 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.07031 4.96875C5.53988 4.96875 5.03117 5.17946 4.6561 5.55454C4.28103 5.92961 4.07031 6.43832 4.07031 6.96875V24.9688C4.07031 25.4992 4.28103 26.0079 4.6561 26.383C5.03117 26.758 5.53988 26.9688 6.07031 26.9688H34.0703C34.6007 26.9688 35.1095 26.758 35.4845 26.383C35.8596 26.0079 36.0703 25.4992 36.0703 24.9688V6.96875C36.0703 6.43832 35.8596 5.92961 35.4845 5.55454C35.1095 5.17946 34.6007 4.96875 34.0703 4.96875H6.07031ZM6.07031 0.96875H34.0703C35.6616 0.96875 37.1877 1.60089 38.313 2.72611C39.4382 3.85133 40.0703 5.37745 40.0703 6.96875V24.9688C40.0703 26.56 39.4382 28.0862 38.313 29.2114C37.1877 30.3366 35.6616 30.9688 34.0703 30.9688H6.07031C4.47901 30.9688 2.95289 30.3366 1.82767 29.2114C0.702453 28.0862 0.0703125 26.56 0.0703125 24.9688L0.0703125 6.96875C0.0703125 5.37745 0.702453 3.85133 1.82767 2.72611C2.95289 1.60089 4.47901 0.96875 6.07031 0.96875V0.96875ZM14.0703 32.9688H26.0703C26.6007 32.9688 27.1095 33.1795 27.4845 33.5545C27.8596 33.9296 28.0703 34.4383 28.0703 34.9688C28.0703 35.4992 27.8596 36.0079 27.4845 36.383C27.1095 36.758 26.6007 36.9688 26.0703 36.9688H14.0703C13.5399 36.9688 13.0312 36.758 12.6561 36.383C12.281 36.0079 12.0703 35.4992 12.0703 34.9688C12.0703 34.4383 12.281 33.9296 12.6561 33.5545C13.0312 33.1795 13.5399 32.9688 14.0703 32.9688Z"
      fill="#6B7280"
    />
  </svg>
</template>

<script>
export default {
  name: "iconMonitor",
};
</script>
