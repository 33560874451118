import { concatObjects } from "@/global/util/object";

const defaultState = () => ({
  analytics: {},
})

const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
}

const getters = {}
const mutations = {
  set(state, payload) {
    Object.assign(state, concatObjects(state, payload));
  },
  resetState(state) {
    Object.assign(state, defaultState())
  },
}

export default {
  namespaced: true,
  state: defaultState(),
  actions,
  getters,
  mutations,
}
