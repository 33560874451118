<template>
  <transition name="modalRtdvx">
    <div :class="$style['modalRtdvx-outer']">
      <div :class="$style['modalRtdvx-mask']"></div>
      <div :class="$style['modalRtdvx-wrapper']">
        <div
          :class="$style['modalRtdvx-container']"
          :style="modalContainerPositionCss"
        >
          <div :class="$style['modalRtdvx-header']">
            <slot name="header"> default header </slot>
          </div>

          <div :class="$style['modalRtdvx-body']">
            <slot name="body"> default body </slot>
          </div>

          <div :class="$style['modalRtdvx-footer']">
            <slot name="footer">
              <button
                :class="$style['modalRtdvx-default-button-left']"
                @click="$emit('left')"
              >
                <slot name="left"> default button </slot>
              </button>
              <button
                :class="$style['modalRtdvx-default-button-right']"
                @click="$emit('right')"
              >
                <slot name="right"> default button </slot>
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  mixins: [],
  components: {},
  props: {
    modalContainerPositionCss: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  mounted() {},
};
</script>
<style module>
.modalRtdvx-outer {
  position: absolute !important;
  z-index: 9998 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: table !important;
  transition: opacity 0.3s ease !important;
}

.modalRtdvx-mask {
  position: absolute !important;
  z-index: 10 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(2.5px) !important;
  display: table !important;
  transition: opacity 0.3s ease !important;
}
.modalRtdvx-wrapper {
  position: relative;
  z-index: 50;
  padding: 0 10px !important;
}
.modalRtdvx-container {
  max-height: 312px !important;
  max-width: 410px !important;
  padding: 30px !important;
  background-color: #fff !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  transition: all 0.3s ease;
  font-family: "Inter", sans-serif !important;
}
.modalRtdvx-header {
  margin: 0 0 20px 0 !important;
}
.modalRtdvx-header h3 {
  font-weight: 500 !important;
  font-size: 21px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #111827 !important;
  margin: 0;
}
.modalRtdvx-footer {
  display: grid !important;
  gap: 20px !important;
  align-items: center !important;
  margin-bottom: 20px !important;
}

.modalRtdvx-body {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #4b5563 !important;
  margin: 0 0 20px 0 !important;
}
.modalRtdvx-body p {
  font-family: "Inter", sans-serif !important;
  margin: 0 !important;
}
.modalRtdvx-body span {
  font-weight: 600 !important;
  font-family: "Inter", sans-serif !important;
}

.modalRtdvx-default-button-right {
  cursor: pointer !important;
  font-size: 16px !important;
  text-decoration: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  color: #ffffff !important;
  font-family: "Inter", sans-serif !important;
  border: 1px solid transparent !important;
  background: #d03f2c !important;
  border-radius: 5px !important;
  height: 60px !important;
  width: 100% !important;
}

.modalRtdvx-default-button-left {
  cursor: pointer !important;
  font-size: 16px !important;
  text-decoration: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  font-family: "Inter", sans-serif !important;
  border: 1px solid transparent !important;
  color: #3b82f6 !important;
  background-color: transparent !important;
  border: 1px solid #3b82f6 !important;
  border-radius: 5px !important;
  height: 60px !important;
  width: 100% !important;
}
.modalRtdvx-default-button {
  cursor: pointer !important;
  font-size: 16px !important;
  text-decoration: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  color: #ffffff !important;
  font-family: "Inter", sans-serif !important;
  border: 1px solid transparent !important;
  background: var(--theme-style-mainColor) !important;
  border-radius: 5px !important;
  height: 60px !important;
  width: 100% !important;
}

@media screen and (max-width: 370px) {
  .modalRtdvx-footer {
    gap: 15px !important;
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 475px) {
  .modalRtdvx-container {
    padding: 30px 25px !important;
  }

  .modalRtdvx-body {
    font-size: 14px !important;
  }

  .modalRtdvx-header h3 {
    font-size: 18px !important;
  }
}
</style>
<style>
.modalRtdvx-enter {
  opacity: 0 !important;
}

.modalRtdvx-leave-active {
  opacity: 0 !important;
}
</style>
