<template>
  <div class="c-v-spinner" :style="{ width: cssSize, height: cssSize }">
    <div
      class="c-v-spinner__ring c-v-spinner__ring--full"
      :style="Object.assign({}, cssRingStyle, cssRingBackgroundColor)"
    />
    <div
      class="c-v-spinner__ring c-v-spinner__ring--part"
      :style="cssRingStyle"
    />
  </div>
</template>

<script>
const DEFAULT_SIZE = 20;
const DEFAULT_RING_SIZE = 3;

export default {
  name: "VSpinner",
  props: {
    size: {
      type: Number,
      default: DEFAULT_SIZE,
    },
    ringSize: {
      type: Number,
      default: DEFAULT_RING_SIZE,
    },
    ringBackgroundColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    cssSize() {
      return `${this.size || DEFAULT_SIZE}px`;
    },
    cssRingStyle() {
      return {
        borderWidth: `${this.ringSize || DEFAULT_RING_SIZE}px`,
      };
    },
    cssRingBackgroundColor() {
      let ringBackgroundColorStyle = {};

      if (this.ringBackgroundColor) {
        ringBackgroundColorStyle = {
          color: this.ringBackgroundColor,
        };
      }

      return ringBackgroundColorStyle;
    },
  },
};
</script>

<style lang="scss">
@keyframes c-v-spinner-animation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.c-v-spinner {
  position: relative !important;
  flex-shrink: 0 !important;

  &--home {
    width: 50px !important;
    height: 50px !important;
    color: var(--theme-style-spinner-backgroundColor) !important;
  }
}

.c-v-spinner__ring {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;

  border: 0 solid;
  border-radius: 50% !important;

  &--part {
    border-color: currentColor transparent transparent transparent !important;
    animation: c-v-spinner-animation 1s linear infinite !important;
  }

  &--full {
    border-color: currentColor !important;
    opacity: 0.5 !important;
  }

  .c-v-spinner--home & {
    border-width: 5px !important;
  }
}
</style>
