import MDonationView from "@modal/components/DonationView";
import KDonationView from "@kiosk/components/DonationView";
import EDonationView from "@embedded/components/DonationView";

import PaymentType from "@/global/components/PaymentType";
import VSpinner from "@/global/components/commons/VSpinner";
import VButtonBack from "@/global/components/commons/VButtonBack";
import VButtonNext from "@/global/components/commons/VButtonNext";
import VIconHelper from "@/global/components/commons/VIconHelper.vue";
import VSpinnerOverlay from "@/global/components/commons/VSpinnerOverlay";
import DonationNavigation from "@/global/components/DonationNavigation";
import DonationHeader from "@/global/components/DonationHeader";
import Notification from "@/global/components/Notification";
import VNotifications from "@/global/components/VNotifications";
import CancelDonationModal from "@/global/components/modals/cancelDonation.vue";

import UI from "@/global/components/UI";
import Form from "@/global/components/commons/form";

import TimeOutModal from "@/global/components/modals/timeOut.vue";

export default [
  MDonationView,
  KDonationView,
  EDonationView,
  VSpinnerOverlay,
  DonationNavigation,
  DonationHeader,
  VButtonBack,
  VButtonNext,
  VIconHelper,
  VSpinner,
  PaymentType,
  Notification,
  VNotifications,
  TimeOutModal,
  CancelDonationModal,
]
  .concat(UI)
  .concat(Form);
