<template>
  <img
    v-if="hasHeroImage && image != 'deleteBanner'"
    :class="$style['widget-card__img']"
    :src="image"
    id="bannerImage"
    alt=""
  />
</template>

<script>
//https://hotauto.parts/image/no-img-placeholder.jpg
// :style="{ 'background-image': 'url(' + image + ')' }"
import { mapState } from "vuex";
export default {
  name: "DonationHeader",
  props: {
    hasHeroImage: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted: function () {},
  computed: {
    ...mapState("organization", {
      widgetImage: (state) => state.organization?.widgetSettings?.widgetImage,
    }),

    image() {
      return this.widgetImage;
    },
  },
};
</script>

<style scoped lang="scss">
.c-v-button__spinner-wrapper {
  display: inline-block !important;

  &.has-label {
    margin-right: 10px !important;
  }
}
</style>
<style module lang="scss">
.widget-card__img {
  height: auto; //205px;
  margin-top: -40px !important;
  width: calc(100% + 80px) !important;
  margin-left: -40px !important;
  margin-bottom: 40px !important;
}

@media screen and (max-width: 475px) {
  .widget-card__img {
    margin-top: -30px !important;
    width: calc(100% + 40px) !important;
    margin-left: -20px !important;
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 370px) {
  .widget-card__img {
    margin-bottom: 30px !important;
  }
}
</style>
