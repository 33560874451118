// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheWidgetBase_widget_embedded_gyPYS{padding:50px 0!important}.TheWidgetBase_widget-container_jkfmE{display:flex;justify-content:center!important;align-items:center!important;padding:0 0!important;min-height:400px!important}@media screen and (max-width:736px){.TheWidgetBase_widget_embedded_gyPYS{padding:0!important;width:100%!important}}@media screen and (max-width:735px){.TheWidgetBase_widget-container_jkfmE{padding:0!important;height:100%!important;overflow:hidden!important}}@media screen and (max-width:1440px) and (min-width:720px){.TheWidgetBase_widget-container_jkfmE>div{transform:scale3d(.8,.8,1);transform-origin:50% 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget_embedded": "TheWidgetBase_widget_embedded_gyPYS",
	"widget-container": "TheWidgetBase_widget-container_jkfmE"
};
export default ___CSS_LOADER_EXPORT___;
