// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VIconHelper_tooltip_VdAgf[data-v-284b0811]{visibility:hidden;opacity:0;transition:.2s linear;font-size:10px;background-color:#555;color:#fff;padding:10px;border-radius:7px;line-height:1.5;min-width:223px;position:absolute;left:-12px;transform:translateX(-100%)}.VIconHelper_tooltip_VdAgf[data-v-284b0811]:after{content:\"\";display:block;width:0;height:0;border-style:solid;border-width:6px 0 6px 7px;border-color:transparent transparent transparent #555;position:absolute;right:0;transform:translate(100%,-50%);top:50%}.VIconHelper_widget-helper__icon_px49i:hover .VIconHelper_tooltip_VdAgf[data-v-284b0811]{visibility:visible;opacity:1}.VIconHelper_widget-helper_Z9VeO[data-v-284b0811]{display:inline-flex!important;cursor:pointer!important;margin-left:10px!important}.VIconHelper_widget-helper__icon_px49i[data-v-284b0811]{position:relative!important;display:flex!important;align-items:center!important;justify-content:center!important;width:20px!important;height:20px!important;background:#f1f1f1!important;border:1px solid #bebebe!important;border-radius:50%!important}@media screen and (max-width:370px){.VIconHelper_widget-helper__icon_px49i[data-v-284b0811]{width:16px!important;height:16px!important}.VIconHelper_widget-helper__icon_px49i svg[data-v-284b0811]{width:5px!important;height:10px!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "VIconHelper_tooltip_VdAgf",
	"widget-helper__icon": "VIconHelper_widget-helper__icon_px49i",
	"widget-helper": "VIconHelper_widget-helper_Z9VeO"
};
export default ___CSS_LOADER_EXPORT___;
