// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheWidgetBase_widget_modal_iNVAI{position:fixed!important;width:100%!important;height:100%!important;left:0!important;top:0!important;z-index:999999!important}.TheWidgetBase_widget_modal_overlay_CuE3G{background:#000!important;opacity:.4!important}.TheWidgetBase_widget_modal_content_CC060,.TheWidgetBase_widget_modal_overlay_CuE3G{position:absolute!important;top:0!important;left:0!important;width:100%!important;height:100%!important}.TheWidgetBase_widget_modal_content_CC060{overflow:auto!important;padding:0!important}.TheWidgetBase_widget-container_srBaE{display:flex;justify-content:center!important;align-items:center!important;padding:10vh 15px 50px!important;min-height:400px!important}@media screen and (max-width:736px){.TheWidgetBase_widget_modal_content_CC060{padding:0!important}.TheWidgetBase_widget_modal_iNVAI .TheWidgetBase_widget-container_srBaE{height:auto!important}}@media screen and (max-width:735px){.TheWidgetBase_widget-container_srBaE{padding:0!important;height:100%!important;overflow:hidden!important}}@media screen and (max-width:1440px) and (min-width:720px){.TheWidgetBase_widget-container_srBaE>div{transform:scale3d(.8,.8,1);transform-origin:50% 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget_modal": "TheWidgetBase_widget_modal_iNVAI",
	"widget_modal_overlay": "TheWidgetBase_widget_modal_overlay_CuE3G",
	"widget_modal_content": "TheWidgetBase_widget_modal_content_CC060",
	"widget-container": "TheWidgetBase_widget-container_srBaE"
};
export default ___CSS_LOADER_EXPORT___;
