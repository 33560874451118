export default {
  methods: {
    initStoreAction(action) {
      const dispatch = this.$store.dispatch;
      return {
        state: function (storeName) {
          dispatch(`${storeName}/${action}`);
          return this;
        },
      };
    },
  },
};
