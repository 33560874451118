// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DonationHeader_widget-card__img_qqfmY{height:auto;margin-top:-40px!important;width:calc(100% + 80px)!important;margin-left:-40px!important;margin-bottom:40px!important}@media screen and (max-width:475px){.DonationHeader_widget-card__img_qqfmY{margin-top:-30px!important;width:calc(100% + 40px)!important;margin-left:-20px!important;margin-bottom:40px!important}}@media screen and (max-width:370px){.DonationHeader_widget-card__img_qqfmY{margin-bottom:30px!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget-card__img": "DonationHeader_widget-card__img_qqfmY"
};
export default ___CSS_LOADER_EXPORT___;
