const VALIDATION_CODES = {
  EMPTY: "EMPTY",
  INVALID: "INVALID",
  DONATION_TOO_SMALL: "DONATION_TOO_SMALL",
};

function generalValidator(fieldComponent) {
  let validationCode = null;

  if (fieldComponent.value === "" || fieldComponent.value === null) {
    validationCode = VALIDATION_CODES.EMPTY;
  }

  return validationCode;
}

function emailValidator(fieldComponent) {
  let validationCode = null;
  const EMAIL_REGEX =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  if (!EMAIL_REGEX.test(fieldComponent.value.toLowerCase())) {
    validationCode = VALIDATION_CODES.INVALID;
  }

  return validationCode;
}

function cardNumberValidator(fieldComponent) {
  let validationCode = null;

  const CARD_NUMBER_REGEX = /^[\s\d\-•]+$/;

  if (!CARD_NUMBER_REGEX.test(fieldComponent.value)) {
    validationCode = VALIDATION_CODES.INVALID;
  }

  return validationCode;
}

function cardDateValidator(fieldComponent) {
  let validationCode = null;
  const CARD_DATE_REGEX = /^\d{2}\/\d{4}$/;

  if (!CARD_DATE_REGEX.test(fieldComponent.value)) {
    validationCode = VALIDATION_CODES.INVALID;
  }

  return validationCode;
}

function getFirstFailedValidationCode(fields) {
  let validationCode = null;

  for (let i = 0; i < fields.length; i += 1) {
    const field = fields[i];

    if (field.isInvalid) {
      validationCode = {
        code: field.validationCode,
        fieldName: field.validationLabel || field.label.toLowerCase(),
      };

      break;
    }
  }

  return validationCode;
}

function resetValidation(vm) {
  /* eslint-disable no-param-reassign */
  vm.isInvalid = false;
  vm.validationCode = "";
  vm.$parent.toggleValidationHints();
}

function getFirstInvalidField(fields) {
  for (let i = 0; i < fields.length; i += 1) {
    const field = fields[i];

    if (field.isInvalid) {
      return field;
    }
  }
  return null;
}

function isBiggerThan5(fieldComponent) {
  let validationCode = null;

  if (Number(fieldComponent.value) < 5) {
    validationCode = VALIDATION_CODES.DONATION_TOO_SMALL;
  }

  return validationCode;
}

function verificationCodeValidator(fieldComponent) {
  let validationCode = null;
  const verificationCodeRegex = /^\d{6}$/;

  if (!verificationCodeRegex.test(fieldComponent.value)) {
    validationCode = VALIDATION_CODES.INVALID;
  }

  return validationCode;
}

export {
  VALIDATION_CODES,
  generalValidator,
  emailValidator,
  cardNumberValidator,
  cardDateValidator,
  getFirstFailedValidationCode,
  resetValidation,
  getFirstInvalidField,
  isBiggerThan5,
  verificationCodeValidator,
};
