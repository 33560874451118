<template>
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.73617 3.56197C2.33067 2.85547 3.04417 2.50197 3.87617 2.50197C4.32017 2.50197 4.65567 2.59247 4.88317 2.77297C5.11067 2.95397 5.22467 3.18647 5.22467 3.46997C5.22467 3.71697 5.09817 3.96397 4.84617 4.21047C4.59367 4.45747 4.30417 4.67097 3.97667 4.85097C3.64867 5.03197 3.34867 5.29897 3.07667 5.65247C2.80417 6.00597 2.66817 6.40697 2.66817 6.85597C2.66817 7.44697 2.86517 7.94547 3.25917 8.35097L5.16517 7.81697C4.97267 7.63597 4.87617 7.40097 4.87617 7.11047C4.87617 6.85297 4.96667 6.61247 5.14817 6.38747C5.32917 6.16347 5.55917 5.95447 5.83817 5.76297C6.11717 5.57097 6.39917 5.36147 6.68367 5.13647C6.96817 4.91147 7.20767 4.61897 7.40317 4.25947C7.59867 3.89947 7.69617 3.49497 7.69617 3.04447C7.69617 2.25247 7.37717 1.61397 6.73917 1.12847C6.10117 0.642465 5.23317 0.398465 4.13567 0.398465C2.54717 0.393965 1.27717 0.931965 0.326172 2.01297L1.73617 3.56197ZM4.10667 11.9735C4.49817 11.9735 4.82617 11.838 5.09067 11.567C5.35517 11.296 5.48767 10.9755 5.48767 10.6055C5.48767 10.236 5.35517 9.91697 5.09067 9.64897C4.82617 9.38047 4.49817 9.24647 4.10667 9.24647C3.71567 9.24647 3.37817 9.38047 3.09367 9.64897C2.81017 9.91697 2.66817 10.236 2.66817 10.6055C2.66817 10.9755 2.81017 11.296 3.09367 11.567C3.37817 11.838 3.71567 11.9735 4.10667 11.9735Z"
      fill="#999999"
    />
  </svg>
</template>

<script>
export default {
  name: "IconHelper",
};
</script>
