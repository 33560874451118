<template>
  <transition name="modalTrvdfVw">
    <div :class="$style['modalTrvdfVw-outer']" :style="modalRootCss">
      <div :class="$style['modalTrvdfVw-mask']"></div>
      <div :class="$style['modalTrvdfVw-wrapper']">
        <div
          :class="$style['modalTrvdfVw-container']"
          :style="modalContainerPositionCss"
        >
          <div :class="$style['modalTrvdfVw-header']">
            <slot name="header"> default header </slot>
          </div>

          <div :class="$style['modalTrvdfVw-body']">
            <slot name="body"> default body </slot>
          </div>

          <div>
            <slot name="footer">
              <button
                :class="$style['modalTrvdfVw-default-button']"
                @click="$emit('close')"
              >
                <slot name="button"> default button </slot>
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  mixins: [],
  components: {},
  props: {
    modalContainerPositionCss: {
      type: String,
      default: "",
    },
    modalRootCss: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  mounted() {},
};
</script>
<style module>
.modalTrvdfVw-outer {
  position: absolute !important;
  z-index: 9998 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: table !important;
  transition: opacity 0.3s ease !important;
}
.modalTrvdfVw-mask {
  position: absolute !important;
  z-index: 10 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.25) !important;
  backdrop-filter: blur(2.5px) !important;
  display: table !important;
  transition: opacity 0.3s ease !important;
}
.modalTrvdfVw-wrapper {
  position: relative;
  z-index: 50;
  padding: 0 10px !important;
}
.modalTrvdfVw-container {
  max-width: 410px !important;

  /* margin: 136px auto auto !important; */
  /* margin: 0 auto auto !important; */

  padding: 30px !important;
  background-color: #fff !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06) !important;

  /* border-radius: 10px !important; */
  /* border-radius: 0px 0px 10px 10px !important; */

  transition: all 0.3s ease;
  font-family: "Inter", sans-serif !important;
}

.modalTrvdfVw-header {
  margin: 0 0 20px 0 !important;
}
.modalTrvdfVw-header h3 {
  font-weight: 500 !important;
  font-size: 21px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #111827 !important;
  margin: 0;
}

.modalTrvdfVw-body {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #4b5563 !important;
  margin: 0 0 20px 0 !important;
}
.modalTrvdfVw-body p {
  font-family: "Inter", sans-serif !important;
  margin: 0 !important;
}
.modalTrvdfVw-body span {
  font-weight: 600 !important;
  font-family: "Inter", sans-serif !important;
}

.modalTrvdfVw-default-button {
  cursor: pointer !important;
  font-size: 16px !important;
  text-decoration: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  color: #ffffff !important;
  font-family: "Inter", sans-serif !important;
  border: 1px solid transparent !important;
  background: var(--theme-style-mainColor) !important;
  border-radius: 5px !important;
  height: 60px !important;
  width: 100% !important;
}

@media screen and (max-width: 475px) {
  .modalTrvdfVw-container {
    padding: 30px 25px !important;
  }

  .modalTrvdfVw-body {
    font-size: 14px !important;
  }

  .modalTrvdfVw-header h3 {
    font-size: 18px !important;
  }
}
</style>

<style>
.modalTrvdfVw-enter {
  opacity: 0 !important;
}

.modalTrvdfVw-leave-active {
  opacity: 0 !important;
}
</style>
