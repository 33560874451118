import CustomComponent from "@/core/fe-easygiving-custom.component";
import AppKiosk from "@kiosk";
import AppModal from "@modal";
import EmbeddedModal from "@embedded";
import Button from "@button";

import globalComponents from "@/global/components";
import globalDirectives from "@/global/directives";
class KioskCustomComponent extends CustomComponent {
  constructor() {
    super({ ...AppKiosk }, globalComponents, globalDirectives);
  }
}

class ModalCustomComponent extends CustomComponent {
  constructor() {
    super({ ...AppModal }, globalComponents, globalDirectives);
  }
}

class EmbeddedCustomComponent extends CustomComponent {
  constructor() {
    super({ ...EmbeddedModal }, globalComponents, globalDirectives);
  }
}

class ButtonCustomComponent extends CustomComponent {
  constructor() {
    super({ ...Button }, [], []);
  }
}

export default {
  KioskCustomComponent,
  ModalCustomComponent,
  EmbeddedCustomComponent,
  ButtonCustomComponent,
  AppModal,
  Button,
};
