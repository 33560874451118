<template>
  <FieldGeneral
    ref="field"
    type="tel"
    :value="value"
    :label="`${label}`"
    :placeholder="placeholder"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :name="name"
    :max-length="maxLength"
    :custom-keypress-handler="mobileNumberKeypressHandler"
    @update="setModel"
  >
    <template #extra></template>
  </FieldGeneral>
</template>

<script>
import { mobileNumberKeypressHandler } from "@/global/util/keypressHandlers";
import { mapState } from "vuex";
import { isEmpty } from "lodash";
let asYoutType;

export default {
  name: "FieldMobileNumber",
  emits: ["update", "updateCode"],
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Mobile Number",
    },
    maxLength: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mobileNumberKeypressHandler,
      countryCode: "", //"US",
      phoneNumberFormat: "NATIONAL", //"E.164"
      code: "",
      isValidNumber: null,
      isValidCurrentNumber: false,
      parse: null,
      format: null,
      mobileNumber: "",
    };
  },
  watch: {
    countryCode(val) {
      this.code = val ? val : "";
      this.$emit("updateCode", this.code);
    },

    mobileNumber(newValue) {
      const { value } = newValue;
      asYoutType.reset().input(value);

      this.countryCode = asYoutType.country;
      this.$store.commit("tempHelper/setValidationInfo", this.validationInfo);
      this.$emit(`update`, newValue);
    },
    "validation.phone": {
      handler(phone) {
        if (phone && phone.hasOwnProperty("isValid")) {
          // const code = !isEmpty(this.countryCode)
          //   ? this.countryCode.toUpperCase()
          //   : this.countryCode;

          if (!this.parsePhone("US")) {
            this.parsePhone("CA");
          }

          // if (!this.mobileNumber) {
          //   this.mobileNumber = {
          //     field: this.name,
          //     value: this.value,
          //   };
          // }

          // const parsedNumber = this.parse(
          //   this.mobileNumber.value,
          //   code,
          //   this.phoneNumberFormat
          // );

          // if (
          //   parsedNumber.phone &&
          //   this.isValidNumber(parsedNumber.phone, code)
          // ) {
          //   this.mobileNumber = {
          //     field: this.name,
          //     value: this.format(parsedNumber, this.phoneNumberFormat, code),
          //   };
          // }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("tempHelper", ["validation"]),
    validationInfo() {
      const validationInfo = {
        phone: {
          countryCode: this.countryCode,
          isValidNumber: this.isValidNumber,
          parse: this.parse,
          format: this.format,
          phoneNumberFormat: this.phoneNumberFormat,
        },
      };
      return validationInfo;
    },
  },

  created() {
    import("libphonenumber-js").then((libPhoneNumber) => {
      asYoutType = new libPhoneNumber.AsYouType();

      this.isValidNumber = libPhoneNumber.isValidNumber;
      this.parse = libPhoneNumber.parse;
      this.format = libPhoneNumber.format;

      this.$store.commit("tempHelper/setValidationInfo", this.validationInfo);
    });
  },
  methods: {
    parsePhone(code) {
      if (!this.mobileNumber) {
        this.mobileNumber = {
          field: this.name,
          value: this.value,
        };
      }

      const parsedNumber = this.parse(
        this.mobileNumber.value,
        code,
        this.phoneNumberFormat
      );

      if (parsedNumber.phone && this.isValidNumber(parsedNumber.phone, code)) {
        this.mobileNumber = {
          field: this.name,
          value: this.format(parsedNumber, this.phoneNumberFormat, code),
        };
        return true;
      }
      return false;
    },
    setModel(mobileNumber) {
      let { field, value } = mobileNumber;
      this.mobileNumber = {
        field,
        value: value,
      };
    },

    getInputValue() {
      return this.$refs.field.value;
    },
  },
};
</script>

<style lang="scss"></style>
