<template>
  <EDonationView
    :has-hero-image="hasImage"
    :title="titleStepName"
    :step="step"
    :current-step-name="currentStepName"
  >
    <VSpinnerOverlay
      :is-visible="isLoading"
      class="c-the-start__spinner-overlay"
      style="background-color: #f5f5f5; z-index: 5"
    />
    <template v-slot:card_body_block_heading_1>
      <h6 :class="$style['widget-title']">{{ title }}</h6>
      <p v-html="description"></p>
    </template>

    <template v-slot:card_body_block_donation-input>
      <div :class="$style['donation-input']" v-if="allowCustomAmounts">
        <div :class="$style['widget-form-row']">
          <div :class="$style['widget-form-field']">
            <div :class="$style['donation-input-icon']"><span>$</span></div>
            <FieldAmount
              :value="amount == 0 ? null : amount"
              name="amount"
              @update="setAmount"
              @blur="blurAmount"
            />
          </div>
        </div>
      </div>

      <div :class="$style['donation-choice']">
        <div
          name="donation-choice"
          v-for="choice in donationChoiceList"
          :key="`choice-amount-${choice.amount}`"
          :class="[
            $style['donation-choice__btn'],
            choice.complete ? $style['active'] : '',
          ]"
          @click="choiceAmount(choice)"
        >
          ${{ choice.amount }}
        </div>
      </div>
    </template>

    <template v-slot:footer-buttons>
      <VButtonNext
        :label="buttonNext"
        :is-disabled="isDisabledContinue"
        @click.stop="next"
      />
    </template>
  </EDonationView>
</template>

<script>
import start from "@/global/mixins/page/start.js";

export default {
  mixins: [start],
  data: () => ({
    presetName: "embedded",
  }),
};
</script>

<style lang="scss" src="@/global/assets/css/page/start.scss"></style>
<style module scoped src="@/global/assets/css/page/module/start.css"></style>
