import { reactive, createApp, h } from "vue";
import { isEmpty } from "lodash";
import axiosInstanceCreator from "@/http/index";
import { config } from "@/config";
import * as constants from "@/config/constants";

// function metaType(type = "") {
//   return {
//     createMeta: function (name, content) {
//       var link = document.createElement("meta");

//       if (type == "og") {
//         link.setAttribute("property", name);
//       } else if (type == "itemprop") {
//         link.setAttribute("itemprop", name);
//       } else {
//         link.setAttribute("name", name);
//       }
//       link.content = content;
//       document.getElementsByTagName("head")[0].appendChild(link);
//       return this;
//     },
//   };
// }
// function initMetaSocial(token) {
//   fetch(`${config.apiBase}/settings/meta`, {
//     headers: {
//       token: token,
//       auth: config.auth,
//     },
//   })
//     .then((response) => response.json())
//     .then(({ data }) => {
//       metaType()
//         .createMeta("title", data.title)
//         .createMeta("description", data.description);

//       //Open Graph / Facebook
//       let og = metaType("og")
//         .createMeta("og:type", "website")
//         .createMeta("og:url", location.href)
//         .createMeta("og:title", data.title)
//         .createMeta("og:description", data.description);
//       if (data?.image) {
//         og.createMeta("og:image", data.image);
//       }

//       //Twitter
//       let twitter = metaType("twitter")
//         .createMeta("twitter:card", "summary_large_image")
//         .createMeta("twitter:url", location.href)
//         .createMeta("twitter:title", data.title)
//         .createMeta("twitter:description", data.description);
//       if (data?.image) {
//         twitter.createMeta("twitter:image", data.image);
//       }

//       let itemprop = metaType("itemprop")
//         .createMeta("url", location.href)
//         .createMeta("name", data.title)
//         .createMeta("description", data.description);
//       if (data?.image) {
//         itemprop.createMeta("thumbnailUrl", data.image);
//         itemprop.createMeta("image", data.image);
//       }
//     });
// }
const createCustomEvent = (name, args = []) => {
  return new CustomEvent(name, {
    bubbles: false,
    composed: true,
    cancelable: false,
    detail: !args.length ? self : args.length === 1 ? args[0] : args,
  });
};

const nearestElement = (el) => {
  while (el?.nodeType !== 1) el = el.parentElement;
  return el;
};

const paramsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};
class ExternalParams {
  // params="member_id=25878&person_id=89789"
  static dataFromCustomElementParams() {
    const customSettings =
      JSON.parse(this.getAttribute("customSettings")) || {};
    return !isEmpty(customSettings) ? customSettings : {};
  }

  // _modal('init', { member_id: '25878' });
  static dataFromScriptInitializationParams() {
    return !isEmpty(window.scriptConfig) ? window.scriptConfig : {};
  }

  dataFromExternalParams() {}
}
export default class CustomComponent extends HTMLElement {
  constructor(
    { component, router, store },
    globalComponents,
    globalDirectives
  ) {
    super();

    this._globalComponents = globalComponents;
    this._globalDirectives = globalDirectives;

    this._numberProps = [];
    this._def = component;
    this._router = router;
    this._store = store;
    this._app = {};

    this._props = reactive({});
    this._scriptConfigs = {};
  }

  static get observedAttributes() {
    return ["data-test"];
  }

  setAttr(attrName) {
    let val = this[attrName] || this.getAttribute(attrName);

    if (val !== undefined && this._numberProps.includes(attrName)) {
      val = Number(val);
    }

    this._props[attrName] = val;
  }

  // Mutation observer to handle attribute changes, basically two-way binding
  connectObserver() {
    return new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes") {
          const attrName = mutation.attributeName;
          this.setAttr(attrName);
        }
      });
    });
  }

  // Make emits available at the parent element
  createEventProxies() {
    const eventNames = this._def.emits;

    if (eventNames) {
      eventNames.forEach((evName) => {
        const handlerName = `on${evName[0].toUpperCase()}${evName.substring(
          1
        )}`;

        this._props[handlerName] = (...args) => {
          this.dispatchEvent(createCustomEvent(evName, args));
        };
      });
    }
  }

  // Create the application instance and render the component
  createApp() {
    const self = this;
    this._store.commit("config/setExternalСonfiguration", this._scriptConfigs);
    this._store.dispatch("config/initUniqueSessionsData");
    this._store.dispatch("config/initialWidgetData", {...window?.widgetInitData});

    // if (
    //   window?.widgetInitData?.instanceName == constants.kiosk &&
    //   !config.mode.demo
    // ) {
    //   initMetaSocial(this._scriptConfigs?.token);
    // }

    this._store.dispatch("logs/info", {
      token: this._scriptConfigs?.token,
      reason: `1. file: /core/fe-easygiving-custom.component.js; 2. actions: Init widget/Create the application instance and render the component; 3: ***START***`,
      data: window?.widgetInitData,
    });
    const app = createApp({
      render() {
        return h(self._def, self._props);
      },
    })
      .use(this._router)
      .use(this._store);

    this._globalComponents.forEach((component) => {
      app.component(component.name, component);
    });

    this._globalDirectives.forEach((directive) => {
      app.directive(directive.name, directive);
    });

    app.provide("$http", axiosInstanceCreator());
    app.provide("donationWidgetSetupPage", config.mode.demo);

    app.mixin({
      mounted() {
        const insertStyles = (styles) => {
          if (styles?.length) {
            this.__style = document.createElement("style");
            this.__style.innerText = styles.join().replace(/\n/g, "");
            nearestElement(this.$el).prepend(this.__style);
          }
        };

        insertStyles(this.$?.type.styles);
        if (this.$options.components) {
          for (const comp of Object.values(this.$options.components)) {
            insertStyles(comp.styles);
          }
        }
      },
      unmounted() {
        this.__style?.remove();
      },
    });

    app.config.errorHandler = (error, instance, info) => {
      console.error(
        `WIDGET_ERROR_HANDLE[${window?.widgetInitData?.widgetName}]: `,
        {
          message: error.message,
          stack: error.stack,
        }
      );
      this._store.dispatch("logs/error", {
        token: this._scriptConfigs?.token,
        reason: `1. file: /core/fe-easygiving-custom.component.js; 2. actions: Report error to tracking services; 3: ***errorHandler***`,
        data: {
          message: error.message,
          stack: error.stack,
        },
      });
    };

    app.mount(this);
    this._app = app;
  }

  // Handle element being inserted into DOM
  connectedCallback() {
    let options =
      typeof this._def === "function" ? this._def.options : this._def;

    let componentProps = Array.isArray(options.props)
      ? options.props
      : Object.keys(options.props || {});

    componentProps.forEach(([propName, propDetail]) => {
      if (propDetail.type === Number) {
        this._numberProps.push(propName);
      }
      this.setAttr(propName);
    });

    this.createEventProxies();
    this.setExternalConfig();
    this.createApp();
    this.connectObserver().observe(this, { attributes: true });

    this.dataTest = this.getAttribute("data-test");
  }

  disconnectedCallback() {
    this._app.unmount(this);
  }

  setExternalConfig() {
    const scriptInitializationParams =
      ExternalParams.dataFromScriptInitializationParams();

    const customElementParams =
      ExternalParams.dataFromCustomElementParams.bind(this)();

    if (!isEmpty(scriptInitializationParams)) {
      this._scriptConfigs = window.scriptConfig;
    }
    if (!isEmpty(customElementParams)) {
      this._scriptConfigs.customElementParams = customElementParams;
    }

    // var originalSetItem = localStorage.setItem;
    // localStorage.setItem = function () {
    //   document.createEvent("Event").initEvent("itemInserted", true, true);
    //   originalSetItem.apply(this, customElementParams);
    // };
  }
}
