import { nextTick } from "vue";

const defaultState = () => ({
  notifications: [],
  eventsCancelModal: {
    clickedCancelDonationWidget: false,
    confirmedCancellationDonation: false,
  },
});

/* Notification:
  {
    type: '', // ERROR, SUCCESS,
    text: '',
    isHtml: false,
  }
*/

const actions = {
  clickedCancelDonationWidget({ commit }, action) {
    nextTick(() => {
      commit("setActionCancelDonationWidget", action);
    });
    nextTick(() => {
      commit("setConfirmedCancellationDonation", !action);
    });
  },
  confirmedCancellationDonation({ commit }, action) {
    nextTick(() => {
      commit("setActionCancelDonationWidget", !action);
    });
    nextTick(() => {
      commit("setConfirmedCancellationDonation", action);
    });
  },
  restoreEventsCancelModal({ commit }) {
    commit("restoreEventsCancelModal");
  },

  notifyError({ dispatch }, text) {
    if (!text || typeof text == undefined) return;

    const isObject = typeof text === "object";
    return dispatch("notify", {
      type: "ERROR",
      ...(isObject ? text : { text }),
    });
  },
  notifySuccess({ dispatch }, text) {
    const isObject = typeof text === "object";
    return dispatch("notify", {
      type: "SUCCESS",
      ...(isObject ? text : { text }),
    });
  },
  notify({ commit }, notificationRaw) {
    const notification = Object.assign({}, notificationRaw);

    if (!notification.id) {
      notification.id = Date.now();
    }

    nextTick(() => {
      commit("addNotification", notification);
    });

    setTimeout(() => commit("removeNotification", notification.id), 3000);
  },

  resetState({ commit }) {
    commit("resetState");
  },
};

const mutations = {
  setActionCancelDonationWidget(state, action) {
    state.eventsCancelModal.clickedCancelDonationWidget = Boolean(action);
  },
  setConfirmedCancellationDonation(state, action) {
    state.eventsCancelModal.confirmedCancellationDonation = Boolean(action);
  },

  restoreEventsCancelModal(state) {
    state.eventsCancelModal = {
      clickedCancelDonationWidget: false,
      confirmedCancellationDonation: false,
    };
  },

  addNotification(state, notification) {
    state.notifications.push(notification);
  },
  removeNotification(state, id) {
    state.notifications = state.notifications.filter((el) => el.id !== id);
  },

  resetState(state) {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state: defaultState(),
  actions,
  mutations,
};
