// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FieldCheckbox_widget-checkbox_kpFv3[data-v-5912ea11]{align-items:center!important}.FieldCheckbox_widget-checkbox_kpFv3[data-v-5912ea11],.FieldCheckbox_widget-checkbox__flex_UP_7_[data-v-5912ea11]{display:flex!important}.FieldCheckbox_widget-checkbox_kpFv3 input[data-v-5912ea11]{flex-shrink:0!important;width:18px!important;height:18px!important;border:1px solid #bdbdbd!important;border-radius:3px!important;outline:none!important;padding:0!important;margin-right:7px!important}.FieldCheckbox_widget-checkbox_kpFv3 label[data-v-5912ea11]{font-size:14px!important;color:#777!important;line-height:16px!important;margin-top:2px!important}@media screen and (max-width:370px){.FieldCheckbox_widget-checkbox_kpFv3 label[data-v-5912ea11]{font-size:12px!important;line-height:13px!important;margin-top:0!important}.FieldCheckbox_widget-checkbox_kpFv3 input[data-v-5912ea11]{flex-shrink:0!important;width:13px!important;height:13px!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget-checkbox": "FieldCheckbox_widget-checkbox_kpFv3",
	"widget-checkbox__flex": "FieldCheckbox_widget-checkbox__flex_UP_7_"
};
export default ___CSS_LOADER_EXPORT___;
