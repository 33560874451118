import { createRouter, createMemoryHistory } from "vue-router";
import TheWidgetBase from "@embedded/components/TheWidgetBase.vue";
import * as donationSteps from "./donationSteps";

const routes = [
  {
    path: "/",
    component: TheWidgetBase,
    children: [
      {
        path: "/",
        children: donationSteps.getSteps(),
      },
    ],
  },
];

const router = createRouter({
  history: createMemoryHistory(), //createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
