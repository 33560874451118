<template>
  <button
    class="card-button_DSdALK"
    :disabled="isDisabled || opacity"
    :class="[
      {
        opacity: opacity,
      },
      [$style['card-button_border']],
    ]"
  >
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.06591 9.16847H17.0998C17.3386 9.16847 17.5675 9.06969 17.7363 8.89388C17.9052 8.71806 18 8.47961 18 8.23097C18 7.98233 17.9052 7.74387 17.7363 7.56805C17.5675 7.39224 17.3386 7.29347 17.0998 7.29347H3.06591L7.83689 2.32472C7.93051 2.23996 8.00629 2.13598 8.05949 2.01928C8.11269 1.90258 8.14216 1.77569 8.14605 1.64657C8.14994 1.51746 8.12816 1.38889 8.08209 1.26895C8.03601 1.14901 7.96663 1.04029 7.8783 0.94959C7.78997 0.858895 7.6846 0.78819 7.56879 0.741911C7.45298 0.695632 7.32924 0.674779 7.20533 0.680659C7.08142 0.68654 6.96001 0.719027 6.84872 0.776082C6.73744 0.833138 6.63867 0.913531 6.55863 1.01222L0.257326 7.57472C0.0923882 7.74996 0 7.98557 0 8.23097C0 8.47636 0.0923882 8.71197 0.257326 8.88722L6.55863 15.4497C6.72978 15.6047 6.95077 15.6866 7.17743 15.6792C7.4041 15.6718 7.61973 15.5756 7.78121 15.4098C7.9427 15.244 8.03815 15.0208 8.04847 14.7849C8.05879 14.5489 7.98323 14.3176 7.83689 14.1372L3.06591 9.16847Z"
        fill="var(--theme-style-mainColor)"
      />
    </svg>
    <VSpinner
      :size="spinnerSize"
      :ring-size="spinnerRingSize"
      v-if="isDisabled"
    />
    <span v-else>{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: "VButtonBack",
  components: {},
  props: {
    label: {
      type: String,
      default: "",
    },
    spinnerSize: {
      type: Number,
      default: null,
    },
    spinnerRingSize: {
      type: Number,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.opacity {
  opacity: 0.6 !important;
}
.ButtonView_widget-card__footer-buttons_FrgASq .card-button_DSdALK {
  flex: 1 !important;
}
.ButtonView_widget-card__footer-buttons_vFDfeq .card-button_DSdALK {
  flex: 1 !important;
}
.card-button_DSdALK svg {
  margin-right: 7px !important;
}
.widget-card__footer-buttons .card-button_DSdALK {
  flex: 1 !important;
}

.card-button_DSdALK {
  cursor: pointer !important;
  font-size: 16px !important;
  text-decoration: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  border-radius: 5px !important;
  height: 60px !important;
}
.card-button_DSdALK:disabled,
.card-button_DSdALK[disabled] {
  opacity: 0.6 !important;
}

@media screen and (max-width: 370px) {
  .card-button_DSdALK {
    height: 43px !important;
  }

  .card-button_DSdALK svg {
    width: 13px !important;
    height: 11px !important;
  }
}
</style>

<style lang="scss" module scoped>
.card-button_border {
  color: var(--theme-style-mainColor) !important;
  background-color: transparent !important;
  border: 1px solid var(--theme-style-mainColor) !important;
}
</style>
