import { mapGetters, mapState } from "vuex";
import validationMixin from "@/global/mixins/validationRules.js";
import LiveSettingsUpdate from "@/global/util/liveSettings/update";
const _ = require("lodash");

const currentStepName = "chooseAmount";
const titleStepName = "Choose Amount";
const maxCountChoiceRowAmount = 6;

export default {
  inject: ["$http"],
  mixins: [validationMixin],
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    titleStepName: titleStepName,
    currentStepName: currentStepName,
    amountOld: "",
    maxCountChoiceRowAmount: maxCountChoiceRowAmount,
    isLoading: true,

    donationChoiceList: [],
    prevRoute: null,
    isDisabledContinue: false,

    errors: [],
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    amount(value) {
      if (this.amountOld != value) {
        this.isDisabledContinue = false;
      }
      this.amountOld = value;
    },
    $route(to, from) {
      this.stepTo = to.name;
    },
    donationChoiceList(value) {
      if (Array.isArray(value) && value.length > 0) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
  created() {
    this.loadDonationChoice();
  },
  computed: {
    ...mapState("donation", ["start"]),
    ...mapState("screen", ["bpMedium", "bpLarge"]),
    ...mapState("flag", { analytics: (state) => state?.analytics }),
    ...mapGetters("intermediateDataCollect", ["getPayloadInfo"]),
    ...mapState("organization", {
      hasImage: (state) => !!state.organization.widgetSettings.widgetImage,
      stepInfo: (state) =>
        state.organization.widgetSettings.stepsInfo[currentStepName],
      basicDetails: (state) =>
        state.organization.widgetSettings.stepsInfo.basicDetails,
      paymentDetails: (state) =>
        state.organization.widgetSettings.stepsInfo.paymentDetails,
      propertyInfo: (state) => state.organization.widgetSettings,
      configProperties: (state) =>
        state.organization.widgetSettings.properties[currentStepName],
      configPropertiesAll: (state) =>
        state.organization.widgetSettings.properties,
    }),
    ...mapState("notifications", ["notifications"]),
    image: {
      get() {
        return this.propertyInfo.widgetImage;
      },
      set(value) {
        if (value != this.image) {
          this.$store.commit("organization/setWidgetImage", value);
        }
      },
    },
    title: {
      get() {
        return this.stepInfo.title;
      },
      set(value) {
        if (value != this.stepInfo.title) {
          this.$store.commit("organization/setTitle", value);
        }
      },
    },
    givingAmount: {
      get() {
        return this.stepInfo.givingAmount;
      },
      set(value) {
        const givingAmount = _.cloneDeep(this.stepInfo.givingAmount);

        if (!_.isEqual(value.sort(), givingAmount.sort())) {
          this.$store.commit("organization/setGivingAmount", value);
          this.renderDonationChoiceList();
        }
      },
    },
    allowCustomAmounts: {
      get() {
        return this.stepInfo.allowCustomAmounts;
      },
      set(value) {
        if (value != this.stepInfo.allowCustomAmounts) {
          this.$store.commit("organization/setAllowCustomAmounts", value);
        }
      },
    },
    allowAnonymousDonors: {
      get() {
        return this.basicDetails.allowAnonymousDonors;
      },
      set(value) {
        if (value != this.basicDetails.allowAnonymousDonors) {
          this.$store.commit("organization/setAllowAnonymousDonors", value);
        }
      },
    },
    allowCoverFee: {
      get() {
        return this.paymentDetails.allowCoverFee;
      },
      set(value) {
        if (value != this.paymentDetails.allowCoverFee) {
          this.$store.commit("organization/setAllowCoverFee", value);
        }
      },
    },
    collectPhone: {
      get() {
        return Boolean(
          this.configPropertiesAll.basicDetails.phone.rules.required
        );
      },
      set(value) {
        if (
          value !=
          Boolean(this.configPropertiesAll.basicDetails.phone.rules.required)
        ) {
          this.$store.commit("organization/setCollectPhone", value);
        }
      },
    },
    collectEmail: {
      get() {
        return Boolean(
          this.configPropertiesAll.basicDetails.email.rules.required
        );
      },
      set(value) {
        if (
          value !=
          Boolean(this.configPropertiesAll.basicDetails.email.rules.required)
        ) {
          this.$store.commit("organization/setCollectEmail", value);
        }
      },
    },
    collectAddress: {
      get() {},
      set(value) {
        this.$store.commit("organization/setCollectAddress", value);
      },
    },
    minAmountRule: {
      get() {
        const minAmount = this.configProperties.givingAmount.rules.minAmount;
        if (minAmount) {
          return minAmount;
        }
        return 5;
      },
      set(value) {
        if (value != this.configProperties.givingAmount.rules.minAmount) {
          this.$store.commit(
            "organization/setMinAmountRule",
            parseFloat(value)
          );
        }
      },
    },
    amount: {
      get() {
        this.$store.commit("intermediateDataCollect/setChooseAmount", {
          amount: this.stepInfo.givingAmountDefault,
        });
        return this.stepInfo.givingAmountDefault;
      },
      set(value) {
        this.$store.commit("intermediateDataCollect/setChooseAmount", {
          amount: value,
        });
        if (value != this.stepInfo.givingAmountDefault) {
          this.$store.commit("organization/setAmount", value);
          this.renderDonationChoiceList();
        }
      },
    },
    description: {
      get() {
        return this.replace_br(this.stepInfo.description);
      },
      set(value) {
        if (value != this.stepInfo.description) {
          this.$store.commit("organization/setDescription", value);
        }
      },
    },
    buttonNext() {
      return this.stepInfo.buttonNext;
    },
    data() {
      const data = {
        amount: this.amount == "" || !this.amount ? 0 : parseFloat(this.amount),
      };
      return data;
    },
  },
  mounted() {
    if (window?.WidgetEventsBus) {
      window.WidgetEventsBus.$on("settings-update", this.liveSettingsUpdate);
    }
  },
  methods: {
    replace_br(str) {
      return str
        .replace(/<br>|<\/br>/g, "")
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/\n/g, "<br>");
    },
    liveSettingsUpdate(data = {}) {
      let LiveSettings = new LiveSettingsUpdate(data);

      LiveSettings.setColor();
      this.givingAmount = LiveSettings.getGivingAmounts();

      if (data?.title && data.title != this.title) {
        this.title = data.title;
      }

      if (data?.description && data.description != this.description) {
        this.description = data.description;
      }

      if (data?.amount && data.amount != this.amount) {
        this.amount = data.amount;
      }

      if (data?.widgetImage && data.widgetImage != this.widgetImage) {
        this.image = data.widgetImage;
      }

      this.allowCustomAmounts = data?.allowCustomAmounts
        ? Boolean(data.allowCustomAmounts)
        : false;

      this.allowAnonymousDonors = data?.allowAnonymousDonors
        ? Boolean(data.allowAnonymousDonors)
        : false;

      this.allowCoverFee = data?.allowCoverFee
        ? Boolean(data.allowCoverFee)
        : false;

      this.collectPhone = data?.collectPhone
        ? Boolean(data.collectPhone)
        : false;
      this.collectEmail = data?.collectEmail
        ? Boolean(data.collectEmail)
        : false;
      this.collectAddress = data?.collectAddress
        ? Boolean(data.collectAddress)
        : false;

      this.minAmountRule = data?.minAmountRule
        ? data.minAmountRule
        : this.minAmountRule;
    },
    setAmount(amount) {
      this.amount = amount;
    },
    blurAmount() {
      this.renderDonationChoiceList();
    },
    renderDonationChoiceList() {
      this.donationChoiceList = this.givingAmount
        .slice(0, this.maxCountChoiceRowAmount)
        .sort((a, b) => a - b)
        .map((amount) => {
          return {
            id: amount,
            amount: amount,
            complete: this.amount == amount ? true : false,
          };
        });
    },
    loadDonationChoice() {
      if (this.givingAmount) {
        this.renderDonationChoiceList();
      }
    },
    choiceAmount(obj) {
      for (var key in this.donationChoiceList) {
        if (this.donationChoiceList[key].id == obj.id) {
          this.donationChoiceList[key].complete = true;
          this.amount = this.donationChoiceList[key].amount;
          this.donationChoiceSelected = this.donationChoiceList[key].id;
        } else {
          this.donationChoiceList[key].complete = false;
        }
      }
    },

    async analyticsPayloadInfoRequest() {
      const analyticsPayloadInfoSend = !!this.analytics && this.analytics?.analyticsPayloadInfo == 'send';

      if (!analyticsPayloadInfoSend) {
        try {
          await this.$http.post('/analytics', this.getPayloadInfo)

          this.$store.commit("flag/set", {
            analytics: {
              analyticsPayloadInfo: 'send'
            },
          });
        } catch(error) {
          const { code, message, name, response: { status }, request: { responseURL } } = error

          console.error("SAVE ANALYTICS DATA:: ", { code, message, name, status, responseURL })

          this.$store.dispatch("logs/error", {
            reason: `current step: ${this.currentStepName}; 1. file: /presets/${this.presetName}/page/TheStart.vue; 2. user actions: FORM SUBMIT::SAVE ANALYTICS DATA; 3. ***PROCESS***`,
            data: this.getPayloadInfo,
          });
        }
      }

    },
    async next() {
      this.errors = this.validateForm(this.data);
      this.isDisabledContinue = !this.errors.formIsValid;
      setTimeout(() => (this.isDisabledContinue = false), 3000);
      if (!this.errors.formIsValid) return;

      this.$store.commit("donation/setStart", this.data);
      this.$store.dispatch("donation/fetchPaymentsFee");
      this.$store.dispatch("logs/info", {
        reason: `current step: ${this.currentStepName}; 1. file: /presets/${this.presetName}/page/TheStart.vue; 2. user actions: FORM SUBMIT; 3. ***PROCESS***`,
        data: this.data,
      });

      await this.analyticsPayloadInfoRequest()
     
      this.$router.push({ name: "basic-details" });
    },
  },
};
