const defaultState = {
  token: '',
  publicToken: '',
  authenticated: false,
  ignoreAuthenticationErrors: false,
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setPublicToken(state, token) {
    state.token = token;
    state.publicToken = token;
  },
  resetToken(state) {
    state.token = state.publicToken;
    state.authenticated = false;
  },
  setAuthenticated(state, payload) {
    state.authenticated = payload;
  },
  setIgnoreAuthenticationErrors(state, payload) {
    state.ignoreAuthenticationErrors = payload;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
};
