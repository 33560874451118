import loader from "@/core/loader.js";
import widgets from "@/listWidgets";
import * as constants from "@/config/constants";
const { EmbeddedCustomComponent } = widgets;

const defaultConfig = {};

loader(
  window,
  defaultConfig,
  window.document.currentScript,
  EmbeddedCustomComponent,
  constants.embedded
);

console.log({ NODE_ENV: process.env.NODE_ENV, type: constants.embedded });
