<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 2C15.1522 2 17.6957 3.05357 19.5711 4.92893C21.4464 6.8043 22.5 9.34784 22.5 12C22.5 14.6522 21.4464 17.1957 19.5711 19.0711C17.6957 20.9464 15.1522 22 12.5 22C9.84784 22 7.3043 20.9464 5.42893 19.0711C3.55357 17.1957 2.5 14.6522 2.5 12C2.5 9.34784 3.55357 6.8043 5.42893 4.92893C7.3043 3.05357 9.84784 2 12.5 2V2ZM12.5 4C10.3783 4 8.34344 4.84285 6.84315 6.34315C5.34285 7.84344 4.5 9.87827 4.5 12C4.5 14.1217 5.34285 16.1566 6.84315 17.6569C8.34344 19.1571 10.3783 20 12.5 20C14.6217 20 16.6566 19.1571 18.1569 17.6569C19.6571 16.1566 20.5 14.1217 20.5 12C20.5 9.87827 19.6571 7.84344 18.1569 6.34315C16.6566 4.84285 14.6217 4 12.5 4V4ZM12.5 13C12.2348 13 11.9804 12.8946 11.7929 12.7071C11.6054 12.5196 11.5 12.2652 11.5 12V8C11.5 7.73478 11.6054 7.48043 11.7929 7.29289C11.9804 7.10536 12.2348 7 12.5 7C12.7652 7 13.0196 7.10536 13.2071 7.29289C13.3946 7.48043 13.5 7.73478 13.5 8V12C13.5 12.2652 13.3946 12.5196 13.2071 12.7071C13.0196 12.8946 12.7652 13 12.5 13ZM12.5 17C12.2348 17 11.9804 16.8946 11.7929 16.7071C11.6054 16.5196 11.5 16.2652 11.5 16C11.5 15.7348 11.6054 15.4804 11.7929 15.2929C11.9804 15.1054 12.2348 15 12.5 15C12.7652 15 13.0196 15.1054 13.2071 15.2929C13.3946 15.4804 13.5 15.7348 13.5 16C13.5 16.2652 13.3946 16.5196 13.2071 16.7071C13.0196 16.8946 12.7652 17 12.5 17Z"
      fill="#7F1D1D"
    />
  </svg>
</template>

<script>
export default {
  name: "IconWarning",
};
</script>
