import { cardSecurityCodeKeypressHandler } from "@/global/util/keypressHandlers";

const cardDefaults = () => ({
  nameOnCard: "",
  cardNumber: "",
  expirationDate: "",
  isAmex: null,
  securityCode: "",
  cardSecurityCodeKeypressHandler,
});

const addressDefaults = () => ({
  addressLine1: "",
  addressLine2: "",
  city: "",
  region: "",
  zipCode: "",
  country: "US",
});

export { cardDefaults, addressDefaults };
