<template>
  <EDonationView
    :title="titleStepName"
    :step="step"
    :show-card-heading-slot="paymentType.card.show"
    :current-step-name="currentStepName"
  >
    <template v-slot:alert_error>
      <Notification :show="messageError.show" :message="messageError.message" />
    </template>
    <template v-slot:card_body_block_heading_1>
      <div :class="$style['payment-list']">
        <div
          v-if="paymentType.card.show"
          :class="[
            $style['payment-list__item'],
            { [$style['active']]: paymentType.card.checked },
          ]"
          @click="selectPaymentType('card')"
        >
          <div :class="$style['payment-list__icon']">
            <img :src="icons.card" alt="" style="width: 21px" />
          </div>
          <span :class="$style['payment-list__name']">Card</span>
        </div>

        <div
          v-if="paymentType.googlePay.show"
          :class="[
            $style['payment-list__item'],
            { [$style['active']]: paymentType.googlePay.checked },
          ]"
          @click="selectPaymentType('googlePay')"
        >
          <div :class="$style['payment-list__icon']">
            <img :src="icons.google" alt="" style="width: 30px" />
          </div>
          <span :class="$style['payment-list__name']">Google Pay</span>
        </div>

        <div
          v-if="paymentType.applePay.show"
          :class="[
            $style['payment-list__item'],
            { [$style['active']]: paymentType.applePay.checked },
          ]"
          @click="selectPaymentType('applePay')"
        >
          <div :class="$style['payment-list__icon']">
            <img :src="icons.apple" alt="" style="width: 30px" />
          </div>
          <span :class="$style['payment-list__name']">Apple Pay</span>
        </div>
      </div>
    </template>

    <template v-slot:card_body_block_donation-input>
      <PaymentType
        :selected="paymentType"
        :items="items"
        @update="updateCardData"
        @updateFormData="updateFormData"
        :is-invalid-card="validationRule.invalid.cardNumber"
        :is-invalid-cvc="validationRule.invalid.securityCode"
        :is-invalid-cc-exp="validationRule.invalid.expirationDate"
        :is-invalid-postal-code="validationRule.invalid.postalCode"
      />

      <FieldCheckbox
        v-if="showCoverFee"
        ref="coveringProcessingFees"
        :value="items.coveringProcessingFees"
        label="Help the organization by covering processing fees"
        @change="updateFormData"
        name="coveringProcessingFees"
        :is-required="true"
      >
        <template #icon>
          <VIconHelper :tooltip="true">
            <template #tooltip>
              Total fees:<br />
              • 2.9% + $0.30/transaction (merchant fee)<br />
              • No platform fee<br />
            </template>
          </VIconHelper>
        </template>
      </FieldCheckbox>
    </template>

    <template v-slot:footer-buttons>
      <VButtonBack label="Go Back" @click.prevent="back" />
      <VButtonNext
        label="Continue"
        :is-disabled="isDisabledContinue"
        @click.stop="next"
      />
    </template>
  </EDonationView>
</template>

<script>
import paymentDetails from "@/global/mixins/page/paymentDetails.js";

export default {
  mixins: [paymentDetails],
  data: () => ({}),
};
</script>

<style
  module
  scoped
  src="@/global/assets/css/page/module/paymentDetails.css"
></style>
