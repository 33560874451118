import axiosInstanceCreator from "@/http/index";
import { config } from "@/config";
import * as navigator from "@/global/util/navigator";

function hashCode(string) {
  var hash = 0;
  for (var i = 0; i < string.length; i++) {
    var code = string.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
const defaultState = {
  token: "",
  errorStatus: "",
};

let hashObject = "";

const preparedRequest = (info, state, status = "") => {
  if (!info || typeof info == undefined) return false;

  let { token, reason, data } = info;
  token = typeof token == undefined || !token ? state.token : token;

  const request = {
    instanceName: window?.widgetInitData?.instanceName,
    session: config.sessionId,
    token,
    status,
    data: data ? data : {},
    browserInfo: navigator,
  };
  const signature = hashCode(JSON.stringify(request));
  if (hashObject == signature) return false;
  hashObject = signature;

  request.reason = `date UTC: ${navigator.formatDate(new Date())}; ${reason}`;
  return request;
};

const actions = {
  info({ commit, state }, info) {
    const $http = axiosInstanceCreator();
    const request = preparedRequest(info, state, "info");
    if (!request) return;

    if (request?.data?.config?.data) {
      request.data.config.data = {};
    }

    $http.put(`/logs`, request);

    if (process.env.NODE_ENV !== "production") {
      console.log("LOGS/INFO/send: ", request);
    }

    commit("setToken", request.token);
  },

  warning({ commit, state }, warning) {
    const $http = axiosInstanceCreator();
    const request = preparedRequest(warning, state, "warning");
    if (!request) return;

    if (request?.data?.config?.data) {
      request.data.config.data = {};
    }
    $http.put(`/logs`, request);

    if (process.env.NODE_ENV !== "production") {
      console.log("LOGS/WARNING/send: ", request);
    }
  },

  error({ commit, state }, error) {
    if (state.errorStatus != "") return;

    const $http = axiosInstanceCreator();
    const request = preparedRequest(error, state, "error");
    if (!request) return;

    if (request?.data?.config?.data) {
      request.data.config.data = {};
    }

    if (process.env.NODE_ENV !== "production") {
      console.log("LOGS/ERROR/send: ", request);
    }

    return $http.put(`/logs`, request).catch(({ response }) => {
      const { status } = response;
      commit("setErrorStatus", status);

      setTimeout(() => commit("removeErrorStatus"), 10000);
    });
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setErrorStatus(state, status) {
    state.errorStatus = status;
  },
  removeErrorStatus(state) {
    state.errorStatus = "";
  },
};

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
};
