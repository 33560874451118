// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TheBase_spinner_animation_EH4m8{transform:translateX(0)!important;-webkit-animation:TheBase_ani_DFMvd 2s forwards!important;animation:TheBase_ani_DFMvd 2s forwards!important}@-webkit-keyframes TheBase_ani_DFMvd{0%{transform:translateX(0)!important}to{transform:translateY(-150%)!important}}@keyframes TheBase_ani_DFMvd{0%{transform:translateX(0)!important}to{transform:translateY(-150%)!important}}.TheBase_widget_AiEzB{min-height:400px!important;display:flex!important;transition:height 1s!important}.TheBase_widget_AiEzB,.TheBase_widget_AiEzB>*{width:100%!important;position:relative!important}.TheBase_widget_AiEzB>*{flex:0 0 auto!important;top:0!important;left:0!important;height:100%!important}.TheBase_widget_AiEzB{max-width:500px!important;min-width:500px!important;background:#fff!important;box-shadow:0 4px 6px -1px rgba(0,0,0,.1),0 2px 4px -1px rgba(0,0,0,.06)!important;border-radius:10px!important;overflow:hidden!important;font-family:Inter,sans-serif!important;color:#4b5563!important;font-weight:400!important;font-size:16px!important;line-height:24px!important}@media screen and (max-width:735px){.TheBase_widget_AiEzB{display:flex!important;max-width:100%!important;min-width:100%!important;width:100%!important;flex-direction:column!important;min-height:100vh!important;border-radius:0!important;transition:none!important;overflow-y:auto!important}.TheBase_widget_AiEzB,body>div{height:100%!important}}@media screen and (max-width:370px){.TheBase_widget_AiEzB{font-size:14px!important;line-height:21px!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner_animation": "TheBase_spinner_animation_EH4m8",
	"ani": "TheBase_ani_DFMvd",
	"widget": "TheBase_widget_AiEzB"
};
export default ___CSS_LOADER_EXPORT___;
