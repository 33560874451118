// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Notification_widget-alert_CJRZo[data-v-15b72327]{padding:0 30px!important;border-radius:5px!important;display:flex!important;align-items:center!important;justify-content:center!important;height:60px!important;margin-bottom:22px!important;margin-top:-20px!important}.Notification_widget-alert_CJRZo svg[data-v-15b72327]{flex-shrink:0!important;margin-right:7px!important}.Notification_widget-alert_CJRZo span[data-v-15b72327]{font-weight:600!important;font-size:14px!important;line-height:17px!important}.Notification_widget-alert-error_HikGI[data-v-15b72327]{background-color:#fee2e2!important}.Notification_widget-alert-error_HikGI span[data-v-15b72327]{color:#7f1d1d!important}@media screen and (max-width:475px){.Notification_widget-alert_CJRZo[data-v-15b72327]{padding:0 15px!important;margin-top:-10px!important}}@media screen and (max-width:370px){.Notification_widget-alert_CJRZo span[data-v-15b72327]{font-size:12px!important;line-height:14px!important}.Notification_widget-alert_CJRZo[data-v-15b72327]{padding:0 15px!important;height:43px!important;margin-bottom:15px!important;margin-top:-15px!important}.Notification_widget-alert_CJRZo svg[data-v-15b72327]{margin-right:5px!important;width:20px!important;height:20px!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget-alert": "Notification_widget-alert_CJRZo",
	"widget-alert-error": "Notification_widget-alert-error_HikGI"
};
export default ___CSS_LOADER_EXPORT___;
