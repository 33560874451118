import * as constants from "@/config/constants";
const WIDGET_ORIGIN_TYPE_NAME = constants.WIDGET_ORIGIN_TYPE_NAME;

const defaultState = () => ({
  payloadInfo: {},
  routeData: {
    currentStep: '',
    history: [],
  },
  start: {},
  donorInfo: {},
  donorNote: {},
  paymentDetails: {},
  summaryPrepared: {},
});

const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
};

const getters = {
  getTimeOutModalsInfo(state) {
    return state.timeOutModals;
  },
  getPayloadInfo(state) {
    let { 
      addressWidgetDisplayed,
      memberId,
      sessionId,
      token,
      location,
      widgetDisplayMethod 
    } = state.payloadInfo
    return {
      token,
      session_id: sessionId,
      widget_display_method: WIDGET_ORIGIN_TYPE_NAME[widgetDisplayMethod],
      address_widget_displayed: addressWidgetDisplayed,
      location,
      member_id: memberId,
    }
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, defaultState());
  },

  setChooseAmount(state, payload) {
    state.start = payload;
  },
  setDonorData(state, donorData) {
    state.donorInfo = donorData;
  },
  setPaymentDetailsData(state, paymentDetailsData) {
    state.paymentDetails = paymentDetailsData;
  },
  setWidgetPayloadInfo(state, payloadInfo) {
    state.payloadInfo = payloadInfo;
  },
  setRouteData(state, payload) {
    const { currentStep, history } = payload
    state.routeData.currentStep = currentStep;
    state.routeData.history.push(history);

  },
};

export default {
  namespaced: true,
  state: defaultState(),
  actions,
  getters,
  mutations,
};
