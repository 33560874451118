const _ = require("lodash");
import card from "@/global/assets/payment-methods/card.svg";
import apple from "@/global/assets/payment-methods/apple-pay.svg";
import google from "@/global/assets/payment-methods/google-pay.svg";

import { mapGetters, mapState } from "vuex";
import validationMixin from "@/global/mixins/validationRules.js";

const currentStepName = "paymentDetails";
export default {
  emits: ["update"],
  mixins: [validationMixin],
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    titleStepName: "Payment Details",
    currentStepName: currentStepName,
    isDisabledContinue: false,
    messageError: {
      show: false,
      message: "There was an issue with your payment method.",
    },
    skipRequiredRule: {},
    items: {
      cardNumber: "",
      securityCode: "",
      expirationDate: "",
      postalCode: "",
      country: {},
      coveringProcessingFees: false,
    },
    isAmex: null,
    paymentType: {
      card: {
        show: false,
        name: "Card",
        checked: true,
      },
      googlePay: {
        show: false,
        name: "Google Pay",
        checked: false,
      },
      applePay: {
        show: false,
        name: "Apple Pay",
        checked: false,
      },
    },
  }),
  computed: {
    icons: () => ({
      card,
      apple,
      google,
    }),
    ...mapState("organization", {
      stepInfo: (state) =>
        state.organization.widgetSettings?.stepsInfo?.[currentStepName],
      propertyInfo: (state) => state.organization.widgetSettings,
      configProperties: (state) =>
        state.organization.widgetSettings?.properties?.[currentStepName],
    }),
    ...mapState("donation", ["paymentDetails"]),
    ...mapGetters("donation", ["isMethodCard"]),
    buttonNext() {
      return this.stepInfo.buttonNext;
    },
    buttonBack() {
      return this.stepInfo.buttonBack;
    },
    showCoverFee() {
      return this.stepInfo?.allowCoverFee ? true : false;
    },
    data() {
      return {
        cardNumber: this.items.cardNumber,
        securityCode: this.items.securityCode,
        expirationDate: this.items.expirationDate,
        postalCode: this.items.postalCode,
        country: this.items.country,
        countryCode: this.items.country.abbrev,
        countryId: this.items.country.id,
        countryName: this.items.country.name,
        coveringProcessingFees: this.items.coveringProcessingFees,
      };
    },
    validationRule() {
      const invalid = {
        cardNumber: false,
        securityCode: false,
        expirationDate: false,
        postalCode: false,
      };

      return { invalid };
    },
  },
  watch: {
    paymentType: {
      handler(paymentType) {
        for (let type in paymentType) {
          for (let items in paymentType[type]) {
            if (items == "checked" && paymentType[type][items] == true) {
              this.$store.commit("donation/setPaymentMethod", type);
            }
          }
        }
      },
      immediate: true,
    },
    items: {
      handler(items) {
        this.$store.commit("intermediateDataCollect/setPaymentDetailsData", {
          ...items,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.setPreEnteredData();
  },
  created() {
    this.setValidationType({ type: "fieldError" });
  },
  methods: {
    setPreEnteredData() {
      if (!_.isEmpty(this.paymentDetails)) {
        const { coveringProcessingFees } = this.paymentDetails;

        this.items.coveringProcessingFees = coveringProcessingFees;
      }
    },
    updateCardData(data) {
      this.items.cardNumber = data.cardNumber;
      this.items.securityCode = data.securityCode;
      this.items.expirationDate = data.expirationDate;
      this.isAmex = data.isAmex;
    },
    updateFormData(data) {
      if (data.field === "country") {
        if (!data.id) {
          this.items[data.field] = {};
        } else {
          this.items[data.field] = { ...data };
          delete this.items[data.field].field;
        }
      } else {
        this.items[data.field] = data.value;
      }

      if (typeof this.$refs?.coveringProcessingFees?.model !== "undefined") {
        this.items.coveringProcessingFees =
          this.$refs.coveringProcessingFees.model;
      }
    },
    isValidForm() {
      return this.validateForm(
        this.preparedValidationData(),
        this.skipRequiredRule
      );
    },
    preparedValidationData() {
      let prepared = { ...this.data };
      delete prepared.countryCode;
      delete prepared.countryName;
      delete prepared.country;
      return prepared;
    },
    showError(fields) {
      for (var field in fields) {
        if (
          !_.isEmpty(fields[field]) &&
          this.validationRule.invalid.hasOwnProperty(field)
        ) {
          this.validationRule.invalid[field] = true;
        } else {
          this.validationRule.invalid[field] = false;
        }
      }
    },
    next() {
      /**
       * TODO Currently only supported by card payment
       */
      if (!this.isMethodCard) {
        this.$store.dispatch(
          "notifications/notifyError",
          "The selected payment type is not supported"
        );
        return;
      }

      this.errors = this.isValidForm();
      this.isDisabledContinue = !this.errors.formIsValid;

      if (!this.errors.formIsValid) {
        this.showError(this.errors);
        setTimeout(() => {
          this.isDisabledContinue = false;
        }, 1000);
        return;
      }

      this.$store.commit("donation/setPaymentDetails", {
        ...this.data,
      });

      this.$router.push({ name: "summary" });
    },
    back() {
      this.$router.back();
    },
    selectPaymentType(type) {
      this.$store.commit("donation/setPaymentMethod", type);

      for (var key in this.paymentType) {
        if (key == type) {
          this.paymentType[key].checked = true;
        } else {
          this.paymentType[key].checked = false;
        }
      }
    },
  },
};
