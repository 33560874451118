import TheStart from "@modal/page/TheStart.vue";
import TheBasicDetails from "@modal/page/TheBasicDetails.vue";
import TheDonationNote from "@modal/page/TheDonationNote.vue";
import ThePaymentDetails from "@modal/page/ThePaymentDetails";
import TheSummary from "@modal/page/TheSummary";
import TheThankYou from "@modal/page/TheThankYou";

//meta.usePathKey
export function getSteps() {
  return [
    {
      path: "/",
      name: "start",
      component: TheStart,
      props: {
        step: 1,
      },
      meta: {
        usePathKey: "_start_",
        stepName: 'chooseAmount',
      },
    },
    {
      path: "/basic-details",
      name: "basic-details",
      component: TheBasicDetails,
      props: {
        step: 2,
      },
      meta: {
        usePathKey: "_basic-details_",
        stepName: 'basicDetails',
      },
    },
    {
      path: "/donation-note",
      name: "donation-note",
      component: TheDonationNote,
      props: {
        step: 3,
      },
      meta: {
        usePathKey: "_donation-note_",
        stepName: 'donationNote',
      },
    },
    {
      path: "/payment-details",
      name: "payment-details",
      component: ThePaymentDetails,
      props: {
        step: 4,
      },
      meta: {
        usePathKey: "_payment-details_",
        stepName: "paymentDetails",
      },
    },
    {
      path: "/summary",
      name: "summary",
      component: TheSummary,
      props: {
        step: 5,
      },
      meta: {
        usePathKey: "_summary_",
        stepName: "summary",
      },
    },
    {
      path: "/thank-you",
      name: "thank-you",
      component: TheThankYou,
      props: {
        step: 6,
      },
      meta: {
        usePathKey: "_thank-you_",
        stepName: "thankYou",
      },
    },
  ];
}
