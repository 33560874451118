const _ = require("lodash");
import { mapState } from "vuex";
import validationMixin from "@/global/mixins/validationRules.js";

const currentStepName = "donationNote";
export default {
  mixins: [validationMixin],
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    titleStepName: "Donation Note",
    currentStepName: currentStepName,
    maxMiddleLength: 100,
    note: "",
    isDisabledContinue: false,
    errors: [],
    skipRequiredRule: {},
  }),
  computed: {
    ...mapState("organization", {
      stepInfo: (state) =>
        state.organization.widgetSettings?.stepsInfo?.[currentStepName],
      propertyInfo: (state) => state.organization.widgetSettings,
      configProperties: (state) =>
        state.organization.widgetSettings?.properties?.[currentStepName],
    }),
    ...mapState("donation", ["donorNote"]),
    title() {
      return this.stepInfo.title;
    },
    description() {
      return this.stepInfo.description;
    },
    buttonNext() {
      return this.stepInfo.buttonNext;
    },
    buttonBack() {
      return this.stepInfo.buttonBack;
    },
    validationRule() {
      const invalid = {
        note: false,
      };
      const rules = {
        note: {
          required: Boolean(this.configProperties?.note?.rules?.required),
          maxLength: 120,
        },
      };
      return { invalid, rules };
    },
    data() {
      return {
        note: this.note,
      };
    },
  },
  mounted() {
    this.setValidationType({ type: "fieldError" });
    this.initRequiredValidationRule();

    this.setPreEnteredData();
  },
  methods: {
    setPreEnteredData() {
      if (!_.isEmpty(this.donorNote)) {
        const { note } = this.donorNote;
        this.note = note;
      }
    },
    next() {
      this.errors = this.validateForm(this.data, this.skipRequiredRule);
      this.isDisabledContinue = !this.errors.formIsValid;

      if (!this.errors.formIsValid) {
        this.showError(this.errors);
        setTimeout(() => {
          this.isDisabledContinue = false;
        }, 1000);
        return;
      }

      this.$store.commit("donation/setDonorNote", {
        ...this.data,
      });

      this.$store.dispatch("logs/info", {
        reason: `current step: ${this.currentStepName}; 1. file: /presets/${this.presetName}/page/TheDonationNote.vue; 2. user actions: FORM SUBMIT; 3. ***PROCESS***`,
        data: this.data,
      });

      this.$router.push({ name: "payment-details" });
    },
    back() {
      this.$router.back();
    },
    updateFormData(data) {
      if (data.field == "note") {
        this.note = data.value;
      }
    },
    initRequiredValidationRule() {
      _.forIn(this.validationRule.rules, (value, key) => {
        Object.assign(this.skipRequiredRule, {
          [key]: {
            required: value?.required ? value.required : false,
          },
        });
      });
    },
    showError(fields) {
      for (var field in fields) {
        if (
          !_.isEmpty(fields[field]) &&
          this.validationRule.invalid.hasOwnProperty(field)
        ) {
          this.validationRule.invalid[field] = true;
        }
      }
    },
  },
};
