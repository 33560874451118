import config from "@/config";

function setThemeColors(variable, colors) {
  let style = document.documentElement.style;
  let styleVariables = [];

  for (let key of Object.keys(colors)) {
    style.setProperty(variable + key, colors[key]);
    styleVariables.push({ [variable + key]: colors[key] });
  }
}

const defaultState = {
  config,
  sessionData: {},
  initialWidgetData: {},
};

const actions = {
  async loadConfigs({ state, commit }) {
    const response = {
      data: {
        theme: "new info...",
      },
    };

    const config = state.config;
    commit("setConfig", { ...config, ...response.data });
    commit("setThemeSpinnerColors", config);
  },
  initUniqueSessionsData({ state, commit }, data = {}) {
    const session = {
      ...data,
      id: `${Date.now()}`,
    }
    commit("initUniqueSessionsData", session);
  },
  initialWidgetData({ state, commit }, data = {}) {
    commit("initialWidgetData", data);
  },
};

const moduleGetters = {
  feInformations(state) {
    // console.log({ moduleGetters_feInformations: state });
    return (state.config && state.config["fe-informations"]) || {};
  },

  themeDefault(state) {
    return (state.config && state.config["theme-default"]) || {};
  },
  
  getInitialWidgetData(state) {
    return state.initialWidgetData;
  },
};

const mutations = {
  setConfig(state, payload) {
    state.config = payload;
  },
  setThemeSpinnerColors(state, payload) {
    const spinner =
      (state.config &&
        state.config["theme-default"] &&
        state.config["theme-default"].spinner) ||
      {};
    setThemeColors("--theme-style-spinner-", spinner);
  },
  setExternalСonfiguration(state, payload) {
    /**
     * TODO validationEnabled [config] or external script
     */
    state.config.external = payload;
  },
  initUniqueSessionsData(state, payload) {
    const { id } = payload;
    state.config.config.sessionId = id;
    state.sessionData = {...payload}
  },
  initialWidgetData(state, payload) {
    state.initialWidgetData = {...payload};
  },
};

export default {
  namespaced: true,
  state: () => defaultState,
  getters: moduleGetters,
  mutations,
  actions,
};
