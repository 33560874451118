<template>
  <svg
    width="11"
    height="15"
    viewBox="0 0 11 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.625 4.89844H8.9375V3.52344C8.9375 1.62594 7.3975 0.0859375 5.5 0.0859375C3.6025 0.0859375 2.0625 1.62594 2.0625 3.52344V4.89844H1.375C0.61875 4.89844 0 5.51719 0 6.27344V13.1484C0 13.9047 0.61875 14.5234 1.375 14.5234H9.625C10.3813 14.5234 11 13.9047 11 13.1484V6.27344C11 5.51719 10.3813 4.89844 9.625 4.89844ZM5.5 11.0859C4.74375 11.0859 4.125 10.4672 4.125 9.71094C4.125 8.95469 4.74375 8.33594 5.5 8.33594C6.25625 8.33594 6.875 8.95469 6.875 9.71094C6.875 10.4672 6.25625 11.0859 5.5 11.0859ZM7.63125 4.89844H3.36875V3.52344C3.36875 2.34781 4.32438 1.39219 5.5 1.39219C6.67562 1.39219 7.63125 2.34781 7.63125 3.52344V4.89844Z"
      fill="#D1D5DB"
    />
  </svg>
</template>

<script>
export default {
  name: "IconSecure",
};
</script>
