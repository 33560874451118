<template>
  <div>
    <FieldGeneral
      ref="field"
      type="tel"
      :value="cardNumber"
      :label="label"
      :is-required="true"
      :is-disabled="isDisabled"
      placeholder="1234 1234 1234 1234"
      :name="name"
      :custom-validators="[cardNumberValidator]"
      :custom-keypress-handler="cardNumberKeypressHandler"
      :max-length="maxLength"
      :is-invalid="isInvalidCard"
      @update="setModel"
    >
      <template #extra>
        <div :class="$style['card-logo']">
          <span v-if="cardIcon">
            <img :src="cardIcon" :alt="cardIcon" />
          </span>
          <span v-else>
            <img :src="icons.visa" alt="visa" />
            <img :src="icons.mastercard" alt="mastercard" />
            <img :src="icons.americanExpress" alt="americanExpress" />
            <img :src="icons.jcb" alt="jcb" />
          </span>
        </div>
      </template>
    </FieldGeneral>
  </div>
</template>

<script>
import { images } from "@/global/util/vendors";
import { cardNumberValidator } from "@/global/util/validators";
import {
  cardNumberKeypressHandler,
  countCharacters,
} from "@/global/util/keypressHandlers";
import creditCardType from "credit-card-type";

import visa from "@/global/assets/payment-methods/visa.svg";
import mastercard from "@/global/assets/payment-methods/mastercard.svg";
import americanExpress from "@/global/assets/payment-methods/amex.svg";
import jcb from "@/global/assets/payment-methods/jcb.svg";

export default {
  name: "FieldCardNumber",
  emits: ["update"],
  components: {},
  props: {
    name: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Card Number",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isAmex: {
      type: Boolean,
      default: false,
    },
    lastFour: {
      type: String,
      default: "",
    },
    vendor: {
      type: String,
      default: "",
    },
    isInvalidCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardNumberValidator,
      cardNumberKeypressHandler,
      gaps: [],
      maxLength: 19,
      cardIcon: "",
    };
  },
  computed: {
    icons: () => ({
      visa,
      mastercard,
      americanExpress,
      jcb,
    }),
    cardNumber: {
      get() {
        this.$nextTick(() => {
          if (this.selection) {
            const input = this.getInput();
            const modificator =
              countCharacters(" ", input.value.substr(0, this.selection[0])) -
              this.beforeSelection;
            input.selectionStart = this.selection[0] + modificator;
            input.selectionEnd = this.selection[1] + modificator;
          }
        });
        return this.formatOutput(this.value);
      },
      set(newValue) {
        let processedValue = newValue.replace(/ /g, "");
        processedValue = processedValue.substr(0, this.maxLength);
        this.$emit("update", {
          field: "cardNumber",
          value: newValue,
        });
        if (!this.$refs.field) {
          return;
        }
        const input = this.getInput();
        if (input.selectionStart === input.selectionEnd) {
          this.selection = [input.selectionStart, input.selectionEnd];
          this.beforeSelection = countCharacters(
            " ",
            newValue.substr(0, input.selectionStart)
          );
        } else {
          this.selection = null;
        }
      },
    },
  },
  watch: {
    cardNumber: {
      immediate: true,
      handler: "getCardTypeData",
    },
    lastFour: {
      immediate: true,
      handler: "createMaskedCardNumber",
    },
  },
  methods: {
    setModel(data) {
      this.cardNumber = data.value;
    },
    getInput() {
      return this.$refs.field.$refs.input;
    },
    formatOutput(value) {
      let formattedValue = value.replace(/ /g, "");

      if (value) {
        for (let i = 0; i < this.gaps.length; i += 1) {
          if (value.length > this.gaps[i]) {
            formattedValue = `${formattedValue.substr(
              0,
              this.gaps[i] + i
            )} ${formattedValue.substr(this.gaps[i] + i)}`;
          }
        }
      }

      return formattedValue;
    },
    getCardTypeData() {
      let cardInformation = [];

      if (this.value) {
        cardInformation = creditCardType(this.value);
      }

      let vendor = "";

      if (cardInformation.length) {
        const types = creditCardType.types;
        vendor = Object.keys(types).find(
          (type) => types[type] === cardInformation[0].type
        );
      }

      if (this.vendor) {
        vendor = this.vendor;
      } else if (cardInformation.length === 1) {
        this.gaps = cardInformation[0].gaps;
        this.maxLength =
          this.gaps.length + cardInformation[0].lengths.slice(-1)[0];
      } else {
        this.gaps = [];
        this.maxLength = 19;
      }

      this.$emit("update", {
        field: "isAmex",
        value: vendor === "AMERICAN_EXPRESS",
        cardType: vendor,
      });

      this.cardIcon = images[vendor];
    },
    createMaskedCardNumber() {
      if (!this.vendor) {
        return;
      }

      const cardTypeInfo = creditCardType.getTypeInfo(
        creditCardType.types[this.vendor]
      );

      this.gaps = cardTypeInfo.gaps;

      this.cardNumber =
        Array(cardTypeInfo.lengths[0] + 1 - this.lastFour.length).join("•") +
        this.lastFour;
    },
  },
};
</script>

<style module scoped>
.widget-form-field label {
  display: block !important;
  line-height: 12px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color: #6b7280 !important;
  margin-bottom: 9px !important;
}

.widget-form-field.error label {
  color: #dc2626 !important;
}

.card-logo {
  display: flex !important;
  position: absolute !important;
  right: 20px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.card-logo img {
  width: 35px !important;
  height: 30px !important;
  margin-right: 5px !important;
}
.card-logo img:last-child {
  margin-right: 0 !important;
}

@media screen and (max-width: 370px) {
  .card-logo img {
    width: 26px !important;
    height: 16px !important;
  }

  .card-logo {
    right: 10px !important;
  }
}
</style>
