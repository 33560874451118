/* It takes a comma separated list of numbers, and returns an array of numbers */
class GivingAmount {
  givingAmount = ""; //5,12 as string
  data = {};

  constructor(data) {
    this.data = data;
    this.givingAmount = this.preparedGivingAmount();
  }

  /**
   * It returns an array of giving amounts, or an empty array if the giving
   * amounts are not an array or
   * are an empty array
   * @returns An array of giving amounts.
   */
  getGivingAmounts() {
    const givingAmounts = this.givingAmounts();
    return Array.isArray(givingAmounts) && givingAmounts.length
      ? givingAmounts
      : [];
  }

  /**
   * It takes a comma separated list of numbers, and returns an array of numbers
   * @returns An array of giving amounts.
   */
  givingAmounts() {
    return this.filteredGivingAmounts(this.givingAmount.trim().split(","));
  }

  preparedGivingAmount() {
    return this.data?.givingAmount && this.data.givingAmount.trim().length
      ? this.data.givingAmount
      : "";
  }

  /**
   * It takes an array of strings, filters out any non-numbers, removes any empty strings, and returns
   * a new array of unique numbers
   * @param [array] - The array of strings to filter.
   * @returns An array of unique numbers.
   */
  filteredGivingAmounts(array = []) {
    return [
      ...new Set(
        array
          .filter(Number)
          .filter((a) => !!a)
          .map((a) => a.replace(/[^\d.]/g, ""))
      ),
    ];
  }
}

class Color {
  color = "";
  data = {};

  constructor(data) {
    this.data = data;
    this.color = this.preparedColor();
  }

  setColor() {
    if (this.color) {
      document.documentElement.style.setProperty(
        "--theme-style-mainColor",
        this.color
      );
    }
  }

  preparedColor() {
    return this.data?.rootColor ? this.data.rootColor : "";
  }
}

class LiveSettingsUpdate {
  data = {};

  constructor(data) {
    this.data = data;
  }

  getGivingAmounts() {
    return new GivingAmount(this.data).getGivingAmounts();
  }

  setColor() {
    return new Color(this.data).setColor();
  }
}

export default LiveSettingsUpdate;
