import { mapState } from "vuex";
import { config } from "@/config";
import * as constants from "@/config/constants";

const widgetSettings = {
  mode: "demo",
  widgetImage:
    "https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014__340.jpg",
  theme: {
    mainColor: "#8b0000",
  },
  feEasygivingButtonSettings: {
    text: "Give",
    style: {},
  },
  paymentProcessor: "wepay",
  stepsInfo: {
    chooseAmount: {
      title: "Demo mode",
      description: "Widget works in demo mode, for reference only!",
      givingAmount: [10, 50, 100, 500, 1000],
      givingAmountDefault: 50,
      buttonNext: "Continue",
      allowCustomAmounts: true, // display or not amount input
    },
    basicDetails: {
      allowAnonymousDonors: true, // display or not checkbox for anonymous donate
      buttonNext: "Continue",
      buttonBack: "Go Back",
    },
    donationNote: {
      show: false, //show page or not
      title: "Demo mode",
      description: "Widget works in demo mode, for reference only!",
      buttonNext: "Continue",
      buttonBack: "Go Back",
    },
    paymentDetails: {
      allowCoverFee: true, // display or not checkbox for cover fees

      buttonNext: "Continue",
      buttonBack: "Go Back",
    },
    summary: {
      title: "Demo mode",
      description: "Widget works in demo mode, for reference only!",
    },
    thankYou: {
      title: "Demo mode",
      description: "Widget works in demo mode, for reference only!",
    },
  },
  properties: {
    chooseAmount: {
      givingAmount: {
        rules: {
          required: true,
          minAmount: 5,
        },
      },
    },
    basicDetails: {
      firstName: {
        rules: {
          required: true,
        },
      },
      lastName: {
        rules: {
          required: true,
        },
      },
      email: {
        rules: {
          required: true,
        },
      },
      phone: {
        rules: {
          required: true,
        },
      },
      address: {
        rules: {
          required: true,
        },
      },
      city: {
        rules: {
          required: true,
        },
      },
      state: {
        required: true,
      },
      postalCode: {
        rules: {
          required: true,
        },
      },
      country: {
        rules: {
          required: true,
        },
      },
      addressState: {
        rules: {
          required: true,
        },
      },
      donationAsAnonymous: {
        rules: {
          required: false,
        },
      },
    },
    donationNote: {
      note: {
        rules: {
          required: false,
          maxLength: 120,
        },
      },
    },
    paymentDetails: {
      cart: {
        fiels: {
          coverFees: {
            rules: {
              required: false,
            },
            default: true,
          },
        },
      },
      googlePay: {
        fields: {
          coverFees: {
            rules: {
              required: false,
            },
            default: false,
          },
        },
      },
      applePay: {
        fields: {
          coverFees: {
            rules: {
              required: false,
            },
            default: false,
          },
        },
      },
    },
    summary: {},
    thankYou: {},
  },
};
export default {
  inject: ["$http"],
  data: () => ({
    themeTagId: "",
    feEasygivingButtonIsset: false,
  }),
  computed: {
    ...mapState("organization", {
      widgetSettings: (state) => state.organization.widgetSettings,
    }),
  },

  async mounted() {
    await this.loadMemberSettings();
    this.$http
      .get(`/settings/widget`)
      .then(({ data }) => {
        const response = data.data;
        if (data.status == "success") {
          this.$store.commit(
            "organization/setOrganizationWidgetSettings",
            response
          );
          this.$store.commit(
            "organization/setWidgetSettingToOriginalState",
            response
          );

          this.$store.dispatch("logs/info", {
            reason: `1. /src/global/mixins/organization.js; 2. RESPONSE::WIDGET_SETTINGS - Widget settings completed successfully; 3. ***PROCESS***`,
            data: response,
          });
        } else {
          this.maybeDemo(response);
        }

        const paymentProcessor = response?.paymentProcessor
          ? response.paymentProcessor
          : widgetSettings.paymentProcessor;
        this.$store.commit(
          "organization/setPaymentProcessor",
          paymentProcessor
        );
        this.$store.dispatch("organization/themeStyle");

        var evt = new CustomEvent("settings-get", {});
        window.dispatchEvent(evt);
      })
      .catch((error) => {
        this.maybeDemo(error);
      });
  },
  methods: {
    //ONLY FOR KIOSK MODE
    async loadMemberSettings() {
      if (window?.widgetInitData?.instanceName !== constants.kiosk) return;

      const mid = location.search.split("mid=")[1]
        ? location.search.split("mid=")[1]
        : "";

      if (mid == "") return;

      try {
        const data = await this.$http.get(`/settings/member/${mid}`);

        const response = data.data;
        if (response.status == "success") {
          this.$store.commit("organization/setMemberSettings", response.data);

          this.$store.dispatch("logs/info", {
            reason: `1. /src/global/mixins/organization.js; 2. RESPONSE::MEMBER_SETTINGS_BY_ID - Member settings completed successfully; 3. ***PROCESS***`,
            data: response,
          });
        } else {
          this.$store.dispatch("logs/info", {
            reason: `1. /src/global/mixins/organization.js; 2. RESPONSE::MEMBER_SETTINGS_BY_ID - Member settings not found; 3. ***PROCESS***`,
            data: data,
          });
        }
      } catch (error) {}
    },
    maybeDemo(response) {
      if (config.mode.demo) {
        this.$store.commit(
          "organization/setOrganizationWidgetSettings",
          widgetSettings
        );
        this.$store.dispatch("logs/info", {
          reason: `1. /src/global/mixins/organization.js; 2. RESPONSE - Widget settings failed; 3. Mode: demo; 4. ***PROCESS***`,
          data: response,
        });
      }
    },
    // setTheme() {
    //   if (!this.themeTagId) {
    //     const id = "easygiving-theme";
    //     const isset = document.getElementById(id);
    //     if (!isset) {
    //       const el = document.createElement("style");
    //       el.id = id;
    //       document.head.appendChild(el);
    //     }
    //     this.themeTagId = id;
    //   }
    //   const el = document.querySelector(`style#${this.themeTagId}`);
    //   if (el) {
    //     el.textContent = "";
    //   }
    // },
  },
};
