import { createStore, createLogger } from "vuex";

function loadModules() {
  const context = require.context("./modules", false, /([a-z_]+)\.js$/i);

  const modules = context
    .keys()
    .map((key) => ({ key, name: key.match(/([a-z_]+)\.js$/i)[1] }))
    .reduce(
      (modules, { key, name }) => ({
        ...modules,
        [name]: context(key).default,
      }),
      {}
    );

  return { context, modules };
}

const { modules } = loadModules();
const store = createStore({
  modules,
  plugins: process.env.NODE_ENV !== "production" ? [createLogger()] : [],
  strict: process.env.NODE_ENV !== "production",
});
export default store;
store.dispatch("screen/monitorBreakpoints");
store.dispatch("config/loadConfigs");
